import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Row, Col } from 'react-bootstrap';

// Fix pour les icônes des marqueurs Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Composant pour recenter la carte sur les nouvelles coordonnées
const RecenterMap = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
        if (lat && lng) {
            map.setView([lat, lng], 13); // Recentrer la carte sur les nouvelles coordonnées
        }
    }, [lat, lng, map]);
    return null;
};

const ReservationMap = ({ pickLocation, latStart, lngStart, returnLocation, latEnd, lngEnd }) => {
    return (
        <Row>
            {/* Carte pour le point de départ */}
            <Col md={6} className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                    {latStart && lngStart ? (
                        <MapContainer
                            center={[latStart, lngStart]}
                            zoom={13}
                            style={{ height: "100%", width: "100%" }}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <RecenterMap lat={latStart} lng={lngStart} />
                            <Marker position={[latStart, lngStart]}>
                                <Popup>
                                    <strong>Lieu de départ : {pickLocation}</strong>
                                    <hr />
                                    Latitude: {latStart}, Longitude: {lngStart}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    ) : (
                        <p>Les coordonnées de départ ne sont pas disponibles.</p>
                    )}
                </div>
            </Col>

            {/* Carte pour le point de retour */}
            <Col md={6} className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                    {latEnd && lngEnd ? (
                        <MapContainer
                            center={[latEnd, lngEnd]}
                            zoom={13}
                            style={{ height: "100%", width: "100%" }}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <RecenterMap lat={latEnd} lng={lngEnd} />
                            <Marker position={[latEnd, lngEnd]}>
                                <Popup>
                                    <strong>Lieu de retour : {returnLocation}</strong>
                                    <hr />
                                    Latitude: {latEnd}, Longitude: {lngEnd}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    ) : (
                        <p>Les coordonnées de retour ne sont pas disponibles.</p>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default ReservationMap;
