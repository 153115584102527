import * as XLSX from "xlsx";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { logoApplication } from "../dataApi/dataFormApi";

// Fonction pour exporter en Excel
export const exportToExcelData = (data, type, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(
        data.map((entry) => ({
            "Designation": entry.brand || entry.name,  // "brand" pour véhicule, "name" pour propriété
            "Model / Adresse": entry.model || entry.address, // "model" pour véhicule, "address" pour propriété
            "Prix Journalier": entry.basePricePerDay,
            // Si c'est un véhicule, ajouter le champ marque
            ...(type === 'vehicle' && { "Marque": entry.vehiclebrand }),
        }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Report");
    // Exporter avec le nom du fichier donné
    XLSX.writeFile(wb, `${fileName || (type === 'vehicle' ? 'Véhicules' : 'Propriétés')}.xlsx`);
};

// Fonction pour exporter en PDF
export const exportToPDFData = (data, type, fileName) => {
    const doc = new jsPDF();

    // Ajoute le titre
    // doc.addImage(logoApplication)
    // doc.textWithLink(`https://rezaplus.com`, 5, 5)
    doc.text(`Listes: ${type === 'vehicle' ? 'Véhicules' : 'Propriétés'}`, 14, 10);

    // Données de la table
    const tableData = data.map((entry) => [
        entry?.brand || entry?.name,          // "brand" pour véhicule, "name" pour propriété
        entry?.model || entry?.address,      // "model" pour véhicule, "address" pour propriété
        entry?.basePricePerDay,
        // Si c'est un véhicule, ajouter le champ marque
        ...(type === 'vehicle' ? [entry.vehiclebrand] : []),
    ]);

    // Options de table
    doc.autoTable({
        head: [
            [
                "Designation",
                "Model / Adresse",
                "Prix journalier (DZD)",
                ...(type === 'vehicle' ? ["Marque"] : []), // Si c'est un véhicule, ajouter la colonne "Marque"
            ]
        ],
        body: tableData,
        startY: 20,
        theme: "grid",
    });

    // Sauvegarde du fichier PDF avec le nom du fichier donné
    doc.save(`${fileName || (type === 'vehicle' ? 'Véhicules' : 'Propriétés')}.pdf`);
};
