import axios from "axios";
import { FETCH_RESERVATIONS_FAILURE, FETCH_RESERVATIONS_REQUEST, FETCH_RESERVATIONS_SUCCESS, FETCH_RESERVATIONS_SUCCESS_2, FETCH_RESERVATION_FAILURE, FETCH_RESERVATION_REQUEST, FETCH_RESERVATION_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl, checkWordInURL } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";





// Create Reservation
export const ReservationCreate = (
    idUser, idTypeReservation, data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/reservations/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Nouvelle demande reservation créer avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error("Reservation non créer")
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });
            });
    };
}




// Create Reservation
export const ReservationCreateClient = (
    data,
    navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/reservations/new`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Demande de Reservation envoyé avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                if (!checkWordInURL(ROUTES.DASHBOARD)) {
                    navigate(`/`);
                } else {
                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_INVOICE_WEB}`);
                }
                //console.log(response.data.data);
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
            });
    };
}









// update status reservation status 
export const ReservationStatutUpdateById = (idReservation, status, discount, idMotif, motif, imagesVerifield, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/reservations/update/${idReservation}/status`,
                {
                    "status": status,
                    "user": getAndCheckLocalStorage(localStorageKeys.userId),
                    "discount": discount,
                    "imagesVerifield": imagesVerifield,
                    "idMotif": idMotif,
                    "motif": motif
                }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                dispatch(fetchReservationById(idReservation));
                if (status == "CONFIRMED") {
                    toast.success("Reservation confirmé avec succès", { position: "bottom-right" })
                } else if (status == "CANCELLED") {
                    toast.success("Reservation  annulé avec succès", { position: "bottom-right" })
                }
            })
            .catch((error) => {
                //console.log(error.request.status);
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });

            });
    };
}











// extends reservation 
export const ReservationExtendById = (idReservation, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/reservations/extend/${idReservation}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                dispatch(fetchReservationById(idReservation));
                toast.success("Prologement de la reservation effectué avec succès", { position: "bottom-right" });
            })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
            });
    };
}




// Update Total Price Real for Reservation
export const ReservationUpdateToalPriceRealById = (idReservation, totalPriceReal) => {
    return async (dispatch) => {
        // console.log("prix Réele",totalPriceReal);
        // Validation de la valeur avant d'envoyer la requête
        if (!totalPriceReal || isNaN(Number(totalPriceReal))) {
            toast.error("Veuillez fournir un prix valide.", { position: "bottom-right" });
            return;
        } else {
            dispatch({ type: FETCH_RESERVATION_REQUEST });
            try {
                // Requête API
                const response = await axios.put(
                    `${baseurl.url}/api/v1/reservations/update/totalprice-real/${idReservation}`,
                    {
                        totalPriceReal: Number(totalPriceReal), // Conversion explicite en nombre
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `${baseurl.TypeToken} ${baseurl.token}`,
                        },
                    }
                );
                // Succès
                toast.success(
                    response.data.message || "Prix réel de la réservation mis à jour avec succès.",
                    { position: "bottom-right" }
                );
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                dispatch(fetchReservationById(idReservation)); // Actualisation des données de la réservation
            } catch (error) {
                // Erreur
                // console.error("Erreur lors de la mise à jour :", error);
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });
                toast.error(
                    error.response?.data?.message || "Erreur lors de la mise à jour du prix.",
                    { position: "bottom-right" }
                );
            }
        }
    };
};




// update status reservation status 
export const ReservationImagesVerieldUpdateById = (idReservation, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/reservations/update/${idReservation}/imagesverified`,
                data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                dispatch(fetchReservationById(idReservation));
                toast.success(`${response.data.message}`, { position: "bottom-right" })
            })
            .catch((error) => {
                //console.log(error.request.status);
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });

            });
    };
}


// Update reservation
export const ReservationUpdateById = (idReservation, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/reservations/update/${idReservation}`,
                data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                dispatch(fetchReservationById(idReservation));
                toast.success(`${response.data.message}`, { position: "bottom-right" })
            })
            .catch((error) => {
                //console.log(error.request.status);
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });

            });
    };
}



// All reservation of plateforme
export function fetchReservationsAll(startDate, endDate, property, vehicle, status) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (status) params.append("status", status);

        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                //console.log(response.data.data);
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
                saveDataToFile(response.data.data, localStorageData.Reservations);
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}




// All reservation of plateforme of Client or customer
export function fetchReservationsAllByidClientId(idClient, startDate, endDate, property, vehicle, status) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (status) params.append("status", status);

        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations/client/${idClient}?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                //console.log(response.data.data);
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
                saveDataToFile(response.data.data, localStorageData.Reservations);
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}





// All reservation of plateforme of Client or customer
export function fetchReservationsAllByidUserId(idUser, startDate, endDate, property, vehicle, status, client) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (status) params.append("status", status);
        if (client) params.append("client", client);

        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations/user/${idUser}?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                //console.log(response.data.data);
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
                saveDataToFile(response.data.data, localStorageData.Reservations);
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}






// All reservation of plateforme of vehicle
export function fetchReservationsAllByVehicleId(idVehicle) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations/vehicle/${idVehicle}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Reservations);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}






// All reservation of plateforme of property
export function fetchReservationsAllByPropertyId(idProperty) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations/property/${idProperty}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Reservations);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}






// All reservation of plateforme of property
export function fetchReservationsAllByTypeId(idTypeReservation) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATIONS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservations/type/${idTypeReservation}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_RESERVATIONS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Reservations);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATIONS_FAILURE, payload: error.message });
            });
    }
}



// get reservation by id 
export function fetchReservationById(idReservation) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservation/${idReservation}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATION_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}





// changer item de reservation

// get reservation by id 
export function fetchchangeReservationItemById(data) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/reservations/renew`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATION_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Item de reservation changer avec succès", { position: "bottom-right" });
            dispatch(fetchReservationById(response?.data?.data?._id || data?.reservationId))
        })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message })
                //console.log(error);
                toast.success(error?.response?.data?.message || "Item de réservation n'a pas été changer ", { position: "bottom-right" })
            });
    }
}




