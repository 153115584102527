import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { FaHome, FaRuler, FaCalendarAlt, FaMoneyBillAlt, FaMapMarkerAlt, FaTruck } from 'react-icons/fa';
import { Row, Col, Button, Container, Card, Form, Table, Accordion, Badge, Image, Modal, Tabs, Tab, Dropdown } from 'react-bootstrap';
import './DetailPropertyPage.css'; // Optionnel pour les styles personnalisés
import { fetchPropertyById } from '../../../actions/request/PropertyRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdAdd, MdCopyAll, MdEdit, MdGetApp, MdLocationOn, MdOutlineNumbers, MdOutlineSupport, MdPerson, MdSearch, MdShare, MdWeb } from 'react-icons/md';
import { ROUTES } from '../../../config/routingUrl';
import { fetchInsurancesAllByPropertyId, fetchInsurancesAllByVehicleId } from '../../../actions/request/InssuranceRequest';
import moment from 'moment';
import { RiEye2Fill } from 'react-icons/ri';
import { fetchReservationsAllByPropertyId } from '../../../actions/request/ReservationRequest';
import { useState } from 'react';
import { fetchDamagesAll, fetchDamagesAllByPropertyId } from '../../../actions/request/DamageRequest';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { copyToClipboard, getCleanUrl } from '../../../utils/functionData/otherFucntion';

// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});




const getExpirationColor = (endDate) => {

  const today = moment();
  const expirationDate = moment(endDate);
  const daysDiff = expirationDate.diff(today, 'days');

  if (daysDiff <= 0) {
    return 'danger'; // Rouge : Date expirée
  } else if (daysDiff <= 5) {
    return 'warning'; // Vert : Moins de 20 jours
  } else {
    return 'primary'; // Vert clair : Plus de 20 jours
  }
};



const renderStatusBadgeDamage = (status) => {
  switch (status) {
    case 'Reported':
      return <Badge bg="danger">Signalé</Badge>;
    case 'Resolved':
      return <Badge bg="success">Résolu</Badge>;
    case 'In Progress':
      return <Badge bg="danger">Annulée</Badge>;
    case 'Paid':
      return <Badge bg="danger">Annulée</Badge>;
    default:
      return <Badge bg="secondary">{status}</Badge>;
  }
};



const DetailPropertyPage = () => {
  // Récupération de l'objet de la propriété via Redux
  const navigate = useNavigate();
  const paramProterty = useParams();
  const dispatch = useDispatch();

  const loadingProperty = useSelector((state) => state.propertys.loadingProperty);

  const property = useSelector((state) => state.propertys.property);
  const insurances = useSelector((state) => state.insurances.insurances);
  const reservations = useSelector((state) => state.reservations.reservations);
  const damages = useSelector((state) => state.damages.damages);


  const [showWebSite, setshowWebSite] = useState(false);
    const handleShowWebsite = () => { setshowWebSite(true) }
    const handleCloseWebsite = () => { setshowWebSite(false) }


  const [showOccupanyRate, setshowOccupanyRate] = useState(false);
  const handlShowOccupanyRate = () => { setshowOccupanyRate(true) }
  const handlCloseOccupanyRate = () => { setshowOccupanyRate(false) }



  useEffect(() => {
    dispatch(fetchPropertyById(paramProterty?.id || ""));
    dispatch(fetchInsurancesAllByPropertyId(paramProterty?.id || ""));
    dispatch(fetchReservationsAllByPropertyId(paramProterty?.id || ""));
    dispatch(fetchDamagesAllByPropertyId(paramProterty?.id || ""));

  }, [dispatch])


  useEffect(() => {
    // Calculer le taux d'occupation global (par défaut) en prenant la première et la dernière réservation
    const globalStartDate = reservations[0]?.startDate || new Date().toISOString();
    const globalEndDate = reservations[reservations.length - 1]?.endDate || new Date().toISOString();
    const rate = calculateOccupancyRate(reservations, globalStartDate, globalEndDate);
    setOccupancyRate(rate);
  }, [reservations]);


  // cacule




  // Fonction pour calculer la différence en jours entre deux dates
  const calculateDaysBetween = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Conversion en jours
    return diffDays;
  };

  // Fonction pour calculer le taux d'occupation
  const calculateOccupancyRate = (reservations, filterStartDate, filterEndDate) => {
    const filterStart = new Date(filterStartDate);
    const filterEnd = new Date(filterEndDate);
    const totalPeriodDays = calculateDaysBetween(filterStart, filterEnd);

    let totalReservedDays = 0;

    reservations.forEach((reservation) => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      // Vérifier si la réservation chevauche la période filtrée
      if (reservationEnd >= filterStart && reservationStart <= filterEnd) {
        const start = reservationStart > filterStart ? reservationStart : filterStart;
        const end = reservationEnd < filterEnd ? reservationEnd : filterEnd;
        totalReservedDays += calculateDaysBetween(start, end);
      }
    });

    // Calcul du taux d'occupation en pourcentage
    const occupancyRate = (totalReservedDays / totalPeriodDays) * 100;
    return occupancyRate.toFixed(2);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [occupancyRate, setOccupancyRate] = useState(0);



  const handleFilter = () => {
    if (startDate && endDate) {
      const rate = calculateOccupancyRate(reservations, startDate, endDate);
      setOccupancyRate(rate);
    }
  };






  if (loadingProperty) {
    return <p className="text-center">Chargement...</p>;
  }

  if (!property) {
    return <p className="text-center">Propriété non trouvée.</p>;
  }


  // Préparation des images pour la galerie
  const images = property && property.images && property.images.length > 0 ? property.images.map((image) => ({
    original: image,
    thumbnail: image,
  })) : [];




  return (
    <div className="property-details">


      <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        <div></div>
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>

              <Dropdown.Item onClick={() => {
                dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: property });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_ADD}`, { state: property })
              }} >
                <MdAdd /> <span> Nouvelle réservation </span>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_EDIT}/${property._id}`) }} ><MdEdit /> <span> Modifier </span> </Dropdown.Item>
              <Dropdown.Item onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`, { state: property }) }} > <MdOutlineSupport /> <span> Assurance </span> </Dropdown.Item>
              <Dropdown.Item onClick={() => { handleShowWebsite() }} > <MdShare /> <span>Copier le lien de la propriété </span> </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>




        </div>
      </div>


      <Row className="mt-4">
        {/* Section des images (à gauche) */}
        <Col md={6}>
          <ImageGallery

            items={images}
            showThumbnails={true}
            showFullscreenButton={false}
            showPlayButton={false}
            renderItem={(item) => (
              <div style={{
                height: '300px', // Hauteur fixe
                width: '100%',
                overflow: 'hidden'
              }}>
                <img
                  src={item.original}
                  alt=""
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            )}

          />


          {
            property && property.lat && property.lng && (

              <div>
                <div className="py-3">
                  <h6> <MdLocationOn />  Localisation </h6>
                  <p> {property && property.address ? property.address : ""}</p>
                </div>
                <MapContainer center={[property.lat, property.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                  <Marker position={[property.lat, property.lng]}>
                    <Popup>
                      Location: {property.lat}, {property.lng}
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            )
          }
        </Col>

        {/* Section des détails de la propriété (à droite) */}
        <Col md={6}>
          <Card>
            <Card.Body>

              <h3>{property?.name}</h3>
              <p><FaHome /> Type: {property?.type?.name}</p>
              <p><MdOutlineNumbers /> N° de la propriété : {property?.numberProperty || ""}</p>
              <p><FaMapMarkerAlt /> Adresse: {property?.address}</p>

              <p><FaRuler /> Taille: {property?.size} m²</p>
              <p><FaCalendarAlt /> Année de construction: {property?.yearBuilt}</p>
              <p><FaTruck /> Frais Supplémentaire: {property?.deliveryFee ? `${property.deliveryFee} DZD` : 'Gratuit'}</p>

              {property && property.basePricePerDay ? <h4 className="mt-4">Infos sur réservation</h4> : ""}
              <p>
                {property ? `Prix de base par jour: ${property.basePricePerDay} DZD` : ""}<br />
                {property && property.commissionPercentage ? `Commission: ${property.commissionPercentage}%` : ""}
                <br />
                {property && property.deliveryFee ? `Commission: ${property.deliveryFee} DZD` : ""}
              </p>

              {/* propriété */}

              {/*<hr />
              <Col md={12}>
                <h3 className="text-start my-4 ">Taux d{"'"}occupation </h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ width: "100%" }}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Date de début</Form.Label>
                      <Form.Control
                        type="datetime-local" style={{ height: "40px" }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Form.Group controlId="endDate">
                      <Form.Label>Date de fin</Form.Label>
                      <Form.Control
                        type="datetime-local" style={{ height: "40px" }}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div>
                      <Button variant="primary" size="sm" className="mt-3"
                        onClick={() => {
                          if (!endDate && !startDate) {
                            toast.error("Date obligatoire", { position: "bottom-right" })
                          } else {
                            handleFilter()
                            handlShowOccupanyRate();
                          }
                        }}
                      >
                        <MdSearch /> Pourcentage d{"'"}occupation
                      </Button>
                    </div>
                  </div>
                </div>
              </Col> */}


              {/* Propriété */}

              {/*
               <hr />
              <Button variant="primary" size="sm" className="mt-3 m-2"
                onClick={() => {
                  dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                  dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: property });
                  navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_ADD}`, { state: property })
                }} >
                <MdAdd /> <span> Nouvelle reservation </span>
              </Button>

              <Button variant="primary" size="sm" className="mt-3 m-2"
              onClick={() => {
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_EDIT}/${property._id}`)
              }}

            >
              <MdEdit /> <span> Modifier </span>
            </Button>
            <Button variant="primary" size="sm" className="mt-3 m-2"
              onClick={() => {
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`, { state: property })
              }}

            >
              <MdOutlineSupport /> <span> Assurance </span>
            </Button> */}


            </Card.Body>

          </Card>

        </Col>
      </Row>



      <div className="container mt-4">
        <Tabs defaultActiveKey="owner" id="reservation-tabs" className="mb-3">
          {/* Tab pour les informations sur le propriétaire */}
          <Tab eventKey="owner" title="Propriétaire">

            <Col md={12}>
              <Card>
                <Card.Body>
                  <h3>Informations sur le propriétaire</h3>
                  <div className="owner-info">
                    {
                      property?.owner?.profilePicture ?
                        <img
                          src={property?.owner?.profilePicture}
                          alt={property?.owner?.firstname}
                          className="owner-img rounded-circle" style={{ height: "80px", width: "80px" }}
                        />
                        : <MdPerson />
                    }
                    <div>
                      <p>Nom: {property?.owner?.firstname} {property?.owner?.lastname}</p>
                      <p>Email: {property?.owner?.email}</p>
                      <p>Téléphone: {property?.owner?.phone}</p>
                      <p>Adresse: {property?.owner?.address}</p>
                      <p>Entreprise: {property?.owner?.companyName}</p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

          </Tab>


          <Tab eventKey="insurances" title="Assurances">
            {/* Section des informations sur le propriétaire */}
            <Col md={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Assurances</h4></Accordion.Header>
                  <Accordion.Body>
                    <Card>
                      <Card.Body>

                        {/* Tableau des rapports filtrés */}
                        {insurances && insurances.length > 0 ? <Table striped bordered hover responsive >
                          <thead>
                            <tr>
                              <th>Titre</th>
                              <th>N° Police</th>
                              <th>Cout</th>
                              <th>Expiration</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {insurances && insurances.length > 0 && insurances.map(insurance => (
                              <tr key={insurance._id}>
                                <td>
                                  {
                                    insurance && insurance.type && insurance.type.name ?
                                      insurance.type.name : ""
                                  }
                                </td>
                                <td>{insurance.policyNumber}</td>
                                <td>{insurance.premiumAmount}</td>

                                <td>
                                  <Badge bg={getExpirationColor(insurance.endDate)}>
                                    {moment(insurance.endDate).format("DD-MM-YYYY")}
                                  </Badge>
                                </td>
                                <td>
                                  <Button variant="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL}/${insurance._id}`) }}>
                                    <RiEye2Fill /> <span className="text-sm"> Detail </span>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table >
                          : <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                            <div> Aucune Assurance</div>
                          </div>
                        }
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Tab>


          <Tab eventKey="damages" title="Dommages">
            <Col md={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Dommages</h4></Accordion.Header>
                  <Accordion.Body>
                    <Card>
                      <Card.Body>

                        {/* Tableau des rapports filtrés */}
                        {
                          damages && damages.length > 0 ?
                            <Table striped bordered hover responsive >
                              <thead>
                                <tr>
                                  <th>Catégorie</th>
                                  <th>Photos Avant</th>
                                  <th>Photos Après</th>
                                  <th>Cout</th>
                                  <th>Statut</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {damages && damages.length > 0 && damages.map((report, index) => (
                                  <tr key={report.id}>

                                    <td>
                                      {
                                        report && report.type && report.type.name ?
                                          report.type.name : ""
                                      }
                                    </td>

                                    <td>
                                      <Image
                                        src={`${report.beforeUsePhoto[0]}`}
                                        alt={`Avant - ${report.beforeUsePhoto[0]}`}
                                        fluid
                                        thumbnail
                                        style={{ width: '80px', height: '60px' }}
                                      />
                                    </td>
                                    <td>
                                      <Image
                                        src={`${report.afterUsePhoto[0]}`}
                                        alt={`Après - ${report.afterUsePhoto[0]}`}
                                        fluid
                                        thumbnail
                                        style={{ width: '80px', height: '60px' }}
                                      />
                                    </td>
                                    <td>
                                      <strong>{report.estimatedCost}</strong>
                                    </td>
                                    <td>
                                      {renderStatusBadgeDamage(report.status)}
                                    </td>
                                    <td>

                                      <Button primary="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}/${report._id}`) }}>
                                        <RiEye2Fill />
                                      </Button>


                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            :
                            <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                              <div> Aucun dommage</div>
                            </div>
                        }
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </Col>
          </Tab>




          <Tab eventKey="reservation" title="Réservation">
            {/*Taux d'occupation */}
            {
              reservations && reservations.length > 0 ?

                <Col md={12}>
                  <h3 className="text-start my-4 ">Réservation </h3>

                  {/* Formulaire pour filtrer le taux d'occupation */}
                  <Row>

                    <Col md={12}>
                      {/* Affichage des réservations sous forme de cartes */}
                      <div className="row">
                        {reservations.map((reservation) => (
                          <div className="col-md-3" key={reservation._id}>
                            <Card className="mb-4">
                              <Card.Img variant="top" src={reservation.vehicle?.images[0] || reservation.property?.images[0]} alt="Image du véhicule" />
                              <Card.Body>
                                <Card.Title>{reservation.ordre || ""}</Card.Title>
                                <Card.Text>
                                  <strong>Date de début :</strong> {moment(reservation.startDate).format("DD/MM/YYYY HH:MM")} <br />
                                  <strong>Date de fin :</strong> {moment(reservation.endDate).format("DD/MM/YYYY HH:MM")} <br />
                                  <strong>Client :</strong> {reservation.client?.firstname} {reservation.client?.lastname}<br />
                                  <strong>
                                    <Button size='sm' variant="primary" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`) }}>
                                      <RiEye2Fill />
                                    </Button>
                                  </strong>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
                :
                ""
            }
          </Tab>
        </Tabs>
      </div>






      {/*Taux d'occupation */}
      <Modal show={showOccupanyRate} onHide={handlCloseOccupanyRate} centered>
        <Modal.Header><h3 className="text-center">Taux d{"'"}occupation</h3></Modal.Header>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Taux d{"'"}occupation global :</Card.Title>
              <p className="h4 " style={{ fontWeight: "600", fontSize: "30px" }}>
                <strong>{occupancyRate}%</strong>
              </p>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer><Button type="button" variant="primay" size='sm' >Fermer</Button></Modal.Footer>
      </Modal>




      <Modal show={showWebSite} onHide={handleCloseWebsite} centered>
        <Modal.Body>
          <div style={{ width: "100%" }}>
            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label">Lien de la propriété</label>
              <div className="input-group">
                <input style={{ height: "40px" }}
                  readOnly
                  className={`form-control`}
                  placeholder=""
                  value={
                    `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_VIEW}/${property._id}/name/${property.name}/description/${property.name }`}`
                  }
                />
                <button
                  type="button" style={{ height: "40px" }}
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    copyToClipboard(
                      `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_VIEW}/${property._id}/name/${property.name}/description/${property.name }`}`
                    )
                    setshowWebSite(false);
                  }}
                >
                  <MdCopyAll />
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>





    </div>
  );
};

export default DetailPropertyPage;
