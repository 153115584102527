import React, { useEffect, useState } from 'react';
import { Collapse, Image, Nav, Navbar, Container, Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { FaCar, FaMoneyCheckAlt, FaTools, FaUsers } from 'react-icons/fa';
import { MdSpaceDashboard, MdExitToApp, MdClosedCaption, MdOpenInBrowser, MdKeyboardArrowDown, MdKeyboardArrowRight, MdOutlineDateRange, MdAccountCircle, MdDataUsage, MdOutlineSupport, MdHome, MdHomeWork, MdOutlineDiscount, MdPanTool, MdVerified, MdDateRange, MdOutlineNotificationsActive, MdSecurityUpdateGood, MdSettings, MdList, MdReport, MdDashboard, MdOutlineSpaceDashboard, MdReportOff, MdTaskAlt } from 'react-icons/md';
import { AiOutlineReload } from "react-icons/ai";
import { PiUsersThreeThin } from "react-icons/pi";
import { RiFileDamageFill } from "react-icons/ri";
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../config/routingUrl';
import { logoApplication, profileRoleType } from '../utils/dataApi/dataFormApi';
import "./SidebarAdmin.css";
import ScrollToTop from './ScrollToTop';
import { localStorageData, localStorageKeys } from '../utils/storage/localvalue';
import { getAndCheckLocalStorage, handleClearLocalStorage } from '../utils/storage/localvalueFuction';
import { useDispatch } from 'react-redux';
import { UserUpdateById, fetchUserById } from '../actions/request/UserRequest';
import { fetchCustomerById } from '../actions/request/CustomerRequest';
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";
import { getCleanUrl } from '../utils/functionData/otherFucntion';
import { fetchExchangeRateGetCurency } from '../actions/request/ExchangeRateRequest';



const SidebarAdmin = () => {

    const dispatch = useDispatch()


    const navigate = useNavigate();
    const location = useLocation()


    //    const {_id , images , starDate,endDate,client} =  location.state;





    const [openMenu, setOpenMenu] = useState({});
    const [isSidebarOpen, setSidebarOpen] = useState(true);

    // Fonction pour savoir si l'URL actuelle correspond à un lien
    const isActive = (path) => location.pathname === path;

    const toggleSubMenu = (menu) => {
        setOpenMenu((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu]
        }));
    };
   




    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };


    const [modalDisconnect, setmodalDisconnect] = useState(false);
    const handleShowModalDisconnect = () => { setmodalDisconnect(true) }
    const handleCloseModelDisconnect = () => { setmodalDisconnect(false) }


    const handleDisconnect = () => {
        handleClearLocalStorage();
        window.localStorage.removeItem(localStorageKeys.adminId)
        window.localStorage.removeItem(localStorageKeys.userId)
        window.localStorage.removeItem(localStorageKeys.profileRole)
        window.localStorage.removeItem(localStorageKeys.userCoverPicture)
        window.localStorage.removeItem(localStorageKeys.adminCoverPicture)
        window.localStorage.removeItem(localStorageKeys.userName)
        window.localStorage.removeItem(localStorageKeys.userRole)
        // window.location.href = "/";
    }


    useEffect(() => {
        dispatch(fetchUserById(getAndCheckLocalStorage(localStorageKeys.userId)))
        dispatch(fetchCustomerById(getAndCheckLocalStorage(localStorageKeys.userId)))
    },
        [dispatch])

    var accesstoken = getAndCheckLocalStorage(localStorageKeys.userId) || getAndCheckLocalStorage(localStorageKeys.adminId);
    if (accesstoken == null) {
        navigate("/")
        setTimeout(() => {
            if (accesstoken == null) {
                navigate("/");
            }
        }, 5000);
    }
    else if (
        (
            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER
        )
        &&
        (
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.REPORTING_LIST}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_ADMIN}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_EDIT}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_PLATEFORM_ADD}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_FORM_PAGE_WEB}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.DASHBOARDREPORT}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.CODEPROMOS_LIST}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_DETAIL_PLATEFORM}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_ADMIN}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_PLATEFORM}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.CARS_ADD}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_ADD}` ||
            location.pathname === `/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`
        )
    ) {
        // toast.error("")
        navigate(`/${ROUTES.DASHBOARD}`)
    }

    else {
        return (
            <div className="d-flex">
                {/* Barre de navigation */}
                <Navbar bg="white" className="border" variant="" expand="lg" fixed="top">
                    <Container fluid>
                        <Navbar.Brand as={Link} to="/">
                            <Image src={logoApplication} style={{ maxHeight: "40px", maxWidth: "40px" }} alt="Logo" />
                            <span style={{ fontSize: '1.5rem' }}></span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="me-auto">
                                {/*<Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}`} className={isActive('/') ? 'active' : ''}>
                                    <span style={{ fontSize: '1.25rem' }}><MdHome /> </span>
                                </Nav.Link> */}
                            </Nav>
                            <Nav>
                                <Nav.Link as={Link} >

                                    <Dropdown>
                                        <Dropdown.Toggle variant='white' className="shadow-none" size='sm'>
                                            <Image
                                                src={
                                                    getAndCheckLocalStorage(localStorageKeys.userCoverPicture) ?
                                                        getAndCheckLocalStorage(localStorageKeys.userCoverPicture) :
                                                        "https://img.freepik.com/vecteurs-libre/cercle-utilisateurs-defini_78370-4704.jpg?t=st=1727639140~exp=1727642740~hmac=8c70aa11dc13e5b1a79cf79b351460f7c966867d31d7ec03728278f13d087385&w=740"
                                                }
                                                style={{ maxHeight: "50px", maxWidth: "50px", borderRadius: "50%" }}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" >
                                                {getAndCheckLocalStorage(localStorageKeys.userName)}
                                            </Dropdown.Item>
                                            {
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ?
                                                    <Dropdown.Item href="#" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROFILE}`) }}>
                                                        <MdAccountCircle size={20} /> Profile
                                                    </Dropdown.Item> : ""
                                            }


                                            <Dropdown.Item href="#" onClick={() => {
                                                handleShowModalDisconnect()
                                            }}>
                                                <MdExitToApp size={20} /> Se déconnecter
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>

                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* Sidebar */}
                <div className={`sidebar bg-white border-r ${isSidebarOpen ? 'open' : ''}`} style={{ width: isSidebarOpen ? '250px' : '0', height: 'calc(100vh - 56px)', overflowY: 'auto', transition: '0.3s', position: 'fixed', top: '56px' }}>
                    {isSidebarOpen && (
                        <Nav className="flex-column p-3 pt-5" style={{ paddingTop: "50px", paddingBottom: "60px" }}>
                            <Nav.Item >
                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}`) ? 'active' : ''}`}>
                                    <MdSpaceDashboard className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Tableau de bord</span>
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item >
                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.NOTIFICATIONS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.NOTIFICATIONS_LIST}`) ? 'active' : ''}`}>
                                    <MdOutlineNotificationsActive className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Notifications</span>
                                </Nav.Link>
                            </Nav.Item>


                            {
                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                ) && (
                                    <Nav.Item >
                                        <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.DASHBOARDREPORT}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.DASHBOARDREPORT}`) ? 'active' : ''}`}>
                                            <MdTaskAlt className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Rappports</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            }





                            {/* Menu Patrimoine */}

                            {
                                /* Utilisateur */
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                                    <Nav.Item>
                                        <Nav.Link onClick={() => toggleSubMenu('patrimoine')} className="d-flex align-items-center">
                                            <MdHomeWork className="me-2" />
                                            <span style={{ fontSize: '1.25rem' }}>Patrimoine</span>
                                            {openMenu.patrimoine ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                                        </Nav.Link>
                                        <Collapse in={openMenu.patrimoine}>
                                            <Nav className="flex-column ms-3">
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`) ? 'active-link' : ''}>
                                                    <FaCar className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Véhicules</span>
                                                </Nav.Link>
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`) ? 'active-link' : ''}>
                                                    <MdHomeWork className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Propriétés</span>
                                                </Nav.Link>
                                            </Nav>
                                        </Collapse>
                                    </Nav.Item>
                                    : ""
                            }





                            {/* Menu Réservations */}
                            {
                                /* Utilisateur */
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?
                                    <Nav.Item>
                                        <Nav.Link onClick={() => toggleSubMenu('reservations')} className="d-flex align-items-center">
                                            <MdOutlineDateRange className="me-2" />
                                            <span style={{ fontSize: '1.25rem' }}>Réservations</span>
                                            {openMenu.reservations ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                                        </Nav.Link>
                                        <Collapse in={openMenu.reservations}>
                                            <Nav className="flex-column ms-3">
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`) ? 'active-link' : ''}>
                                                    <span style={{ fontSize: '1.25rem' }}><MdList /> Listes </span>
                                                </Nav.Link>
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_LIST_CALENDAR}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_LIST_CALENDAR}`) ? 'active-link' : ''}>
                                                    <span style={{ fontSize: '1.25rem' }}> <MdDataUsage /> Calendrier </span>
                                                </Nav.Link>
                                            </Nav>
                                        </Collapse>
                                    </Nav.Item>
                                    : ""
                            }


                            {
                                /* Utilisateur */
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?
                                    <Nav.Item>
                                        <Nav.Link onClick={() => toggleSubMenu('maintenances')} className="d-flex align-items-center">
                                            <FaTools className="me-2" />
                                            <span style={{ fontSize: '1.25rem' }}>Entretiens</span>
                                            {openMenu.maintenances ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                                        </Nav.Link>
                                        <Collapse in={openMenu.maintenances}>
                                            <Nav className="flex-column ms-3">
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_PLATEFORME}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_PLATEFORME}`) ? 'active-link' : ''}>
                                                    <MdList className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Listes</span>
                                                </Nav.Link>
                                                <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_CALENDAR}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_CALENDAR}`) ? 'active-link' : ''}>
                                                    <MdDataUsage className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Calendrier</span>
                                                </Nav.Link>
                                            </Nav>
                                        </Collapse>
                                    </Nav.Item>
                                    : ""
                            }

                            {
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                    ?
                                    <>
                                        <Nav.Item>
                                            <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                                <MdOutlineSupport className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Assurances</span>
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                                <RiFileDamageFill className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Dommages</span>
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CODEPROMOS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CODEPROMOS_LIST}`) ? 'active-link' : ''}`}>
                                                <MdOutlineDiscount className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Code Promos</span>
                                            </Nav.Link>
                                        </Nav.Item>

                                        {/*<Nav.Item>
                                            <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_PLATEFORM}`) ? 'active' : ''}`}>
                                                <FaMoneyCheckAlt className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Paiements</span>
                                            </Nav.Link>
                                        </Nav.Item> */}

                                        {
                                            /*<Nav.Item>
                                            <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.EXPENCES_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.EXPENCES_LIST}`) ? 'active-link' : ''}`}>
                                                <FaMoneyCheckAlt className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Dépenses</span>
                                            </Nav.Link>
                                        </Nav.Item> */
                                        }





                                    </>
                                    : ""
                            }










                            {
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?

                                    <>
                                        <Nav.Item style={{ paddingBottom: "60px" }}>
                                            <Nav.Link onClick={() => toggleSubMenu('settings')} className="d-flex align-items-center">
                                                <MdSettings className="me-2" />
                                                <span style={{ fontSize: '1.25rem' }}>Administrateur</span>
                                                {openMenu.settings ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                                            </Nav.Link>
                                            <Collapse in={openMenu.settings}>
                                                <Nav className="flex-column ms-3">

                                                    <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CUSTOMERS_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CUSTOMERS_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                                        <PiUsersThreeThin className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Clients</span>
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.USERS_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.USERS_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                                        <FaUsers className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Utilisateurs</span>
                                                    </Nav.Link>
                                                    {/*<Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_ADMIN}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_ADMIN}`) ? 'active-link' : ''}>
                                                        <span style={{ fontSize: '1.25rem' }}><MdSecurityUpdateGood /> Gestion Assurances </span>
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CATEGORYS_LIST_ADMIN}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CATEGORYS_LIST_ADMIN}`) ? 'active-link' : ''}>
                                                        <span style={{ fontSize: '1.25rem' }}><MdSecurityUpdateGood /> Gestion Categories </span>
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TRANSMISSIONS_LIST_ADMIN}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TRANSMISSIONS_LIST_ADMIN}`) ? 'active-link' : ''}>
                                                        <span style={{ fontSize: '1.25rem' }}><MdSecurityUpdateGood /> Gestion Transmissions </span>
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_ADMIN}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_LIST_ADMIN}`) ? 'active-link' : ''}>
                                                        <span style={{ fontSize: '1.25rem' }}><MdSecurityUpdateGood /> Types paiements </span>
                                                    </Nav.Link> */}
                                                </Nav>

                                            </Collapse>
                                        </Nav.Item>
                                    </>
                                    :
                                    ""
                            }

                        </Nav>
                    )}
                </div>

                {/* Page Content */}
                <div className="content border" style={{ marginLeft: isSidebarOpen ? '250px' : '0', transition: '0.3s', width: '100%', paddingTop: '56px', marginTop: "20px" }}>
                    {/* Bouton pour ouvrir/fermer le Sidebar */}
                    <button className="btn btn-sm btn-link m-5" onClick={toggleSidebar}>
                        {isSidebarOpen ? <FaAnglesLeft /> : <FaAnglesRight />}
                    </button>

                    {/* Contenu principal (Outlet pour afficher les sous-pages) */}
                    <Card style={{ minHeight: "600px" }} >
                        <Card.Body>
                            <ScrollToTop />
                            <Outlet />

                        </Card.Body>
                        <Card.Footer></Card.Footer>
                    </Card>
                </div>



                <Modal show={modalDisconnect} onHide={handleCloseModelDisconnect} centered >
                    <Modal.Header>Voulez vous vou déconnecter</Modal.Header>
                    <Modal.Footer>
                        <Button variant="white" size="sm" onClick={handleCloseModelDisconnect}>Annuler</Button>
                        <Button variant="primary" size="sm" onClick={() => {
                            handleCloseModelDisconnect();
                            handleDisconnect();
                        }}>Se Déconnecter</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );

    }
};

export default SidebarAdmin;
