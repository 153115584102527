import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderWeb from '../components/web/HeaderWeb';
import SidebarAdmin from '../components/SidebarAdmin';
import ChatComponent from '../components/chatboot/ChatCompo';
import FooterWeb from '../components/FooterWeb';
import { Container } from 'react-bootstrap';


const LayoutAdmin = () => {
    
    
    return (
        <>
            <div className="page-wrapper">
                <SidebarAdmin />
            </div>
        </>
    )
}

export default LayoutAdmin
