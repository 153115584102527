import React from 'react';
import InvoiceReservationWebPage from './InvoiceReservationWebPage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { baseurl } from '../../utils/url/baseurl';

const stripePromise = loadStripe(baseurl.ApikeyCheckout);

const ElementsWrapper = () => (
    <Elements stripe={stripePromise}>
        <InvoiceReservationWebPage />
    </Elements>
);

export default ElementsWrapper;
