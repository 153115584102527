import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="page-wrap d-flex flex-row align-items-center bg-light" style={{alignItems:"center",height:"100vh"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">404</span>
                        <div className="mb-4 lead">Page non trouvée .</div>
                        <Link to="/" className="btn btn-link"> Retour </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
