import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleCreate, VehicleUpdateById } from '../../../actions/request/VehicleRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { fetchVehicleTypesAll } from '../../../actions/request/VehicleTypeRequest';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchVehicleBrandsAll } from '../../../actions/request/VehicleBrandRequest';
import { fetchMainteanceShedulesAll } from '../../../actions/request/MainteanceSheduleRequest';
import { fetchVehicleModelsAll } from '../../../actions/request/VehicleModelRequest';
import { MdClose, MdDeleteOutline, MdRemove } from 'react-icons/md';
import { toast } from 'react-toastify';
import { localStorageKeys } from '../../../utils/storage/localvalue';


function UpdateVehiclePage() {

    const navigate = useNavigate();

    const paramsvehicle = useParams();


    const dispatch = useDispatch();
    const vehicleTypesList = useSelector((state) => state.vehicleTypes.vehicleTypes);
    const vehiclebrandList = useSelector((state) => state.vehiclebrands.vehiclebrands);
    const maintenanceshedulesList = useSelector((state) => state.maintenanceShedules.maintenanceShedules);
    const vehiclemodels = useSelector((state) => state.vehiclemodels.vehiclemodels);

    const [newVehicle, setNewVehicle] = useState({
        brand: '',
        model: '',
        basePricePerDay: '',
        passenger: 0,
        address: "",
        lat: null,
        lng: null,
        // commissionPercentage: '',
        deliveryFee: '',
        vehiclebrand: "",
        maintenanceshedule: "",
        year: '',
        color: '',
        registrationNumber: '',
        numberIdVehicle: '',
        mileage: '',
        owner: getAndCheckLocalStorage(localStorageKeys.userId),
        type: '',
        images: [],
        addBy: getAndCheckLocalStorage(localStorageKeys.userId),

        deliveryFee10: 5000,
        deliveryFee50: 9000,
        deliveryFee100: 50000,
        vignetteImpot: ""

    });






    const [typeOptions, setTypeOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([
        { value: 'Rouge', label: 'Rouge' },
        { value: 'Bleu', label: 'Bleu' },
        { value: 'Vert', label: 'Vert' },
        { value: 'Jaune', label: 'Jaune' },
        { value: 'Noir', label: 'Noir' },
        { value: 'Blanc', label: 'Blanc' },
        { value: 'Gris', label: 'Gris' },
        { value: 'Orange', label: 'Orange' },
        { value: 'Marron', label: 'Marron' },
        { value: 'Violet', label: 'Violet' },
        { value: 'Rose', label: 'Rose' },
    ]);

    const vehiclemodelsOptions = vehiclemodels && vehiclemodels.length > 0 ? vehiclemodels.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const vehiclebrandOptions = vehiclebrandList && vehiclebrandList.length > 0 ? vehiclebrandList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const maintenancesheduleOptions = maintenanceshedulesList && maintenanceshedulesList.length > 0 ? maintenanceshedulesList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []




    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);



    async function fetchVehicleGet() {
        setUploading(true);
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicle/${paramsvehicle.id || ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.typeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            const reponseData = response.data.data;
            setNewVehicle(
                {
                    type: reponseData.type._id,
                    brand: reponseData.brand,
                    model: reponseData.model,
                    basePricePerDay: reponseData.basePricePerDay,
                    commissionPercentage: reponseData.commissionPercentage,
                    deliveryFee: reponseData.deliveryFee,


                    vehiclebrand: reponseData.vehiclebrand,
                    maintenanceshedule: reponseData.maintenanceshedule,

                    year: reponseData.year,
                    color: reponseData.color,
                    registrationNumber: reponseData.registrationNumber,
                    numberIdVehicle: reponseData.numberIdVehicle,
                    mileage: reponseData.mileage,
                    owner: reponseData.owner,
                    images: reponseData.images,
                    addBy: reponseData.addBy,
                    isVisible: reponseData.isVisible,
                    address: reponseData.address,
                    passenger: reponseData.passenger,

                    deliveryFee10: reponseData.deliveryFee10,
                    deliveryFee50: reponseData.deliveryFee50,
                    deliveryFee100: reponseData.deliveryFee100,
                    vignetteImpot:reponseData.vignetteImpot

                }
            );
            setPickupLocation(reponseData.address);
            setUploading(false);
            // setNewVehicle(response.data.data);
            // dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                toast.error("Véhicule introuvable", { position: "bottom-right" })
                // console.log(error);
                setUploading(false);
            });
    }



    // Gestion des types de véhicules
    useEffect(() => {
        dispatch(fetchVehicleTypesAll());
        dispatch(fetchVehicleBrandsAll());
        dispatch(fetchMainteanceShedulesAll());
        dispatch(fetchVehicleModelsAll());
        // get vehicle by id 
        fetchVehicleGet();


    }, [dispatch]);

    useEffect(() => {
        if (vehicleTypesList && vehicleTypesList.length > 0) {
            setTypeOptions(vehicleTypesList.map((type) => ({
                label: type.name,
                value: type._id
            })));
        }
    }, [vehicleTypesList]);

    // Fonction de téléchargement des images vers Cloudinary
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const uploadPromises = files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', baseurl.cloudinaryUploadPreset);

            return axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/image/upload`, formData)
                .then(response => {
                    toast.success('Image téléchargée avec succès', { position: "bottom-right" });
                    return response.data.secure_url;
                })
                .catch(error => {
                    toast.error('Erreur lors du téléchargement de l\'image', { position: "bottom-right" });
                    console.error('Erreur lors du téléchargement de l\'image :', error);
                    return null;
                });
        });

        const uploadedImageUrls = await Promise.all(uploadPromises);
        setNewVehicle(prevVehicle => ({
            ...prevVehicle,
            images: [...prevVehicle.images, ...uploadedImageUrls.filter(url => url !== null)]
        }));

        // console.log("URLs des images téléchargées : ", uploadedImageUrls);
    };

    // Validation du formulaire
    const validateForm = () => {
        let formErrors = {};
        if (!newVehicle.brand) formErrors.brand = "le Nom ou la designation est requise";
        if (!newVehicle.model) formErrors.model = "Le modèle est requis";
        if (!newVehicle.vehiclebrand) formErrors.vehiclebrand = "La marque est requise";
        if (!newVehicle.registrationNumber) formErrors.registrationNumber = "Le numéro d'immatriculation est requis";
        if (!newVehicle.year) formErrors.year = "L'année est requise";
        if (!newVehicle.mileage) formErrors.mileage = "Le kilométrage est requis";
        if (!newVehicle.color) formErrors.color = "La couleur est requise";
        if (!newVehicle.type) formErrors.type = "Le type de véhicule est requis";
        if (!newVehicle.basePricePerDay) formErrors.basePricePerDay = "Le prix journalier est requis";
        if (!newVehicle.address) formErrors.address = "Addresse est requise";
        if (!newVehicle.passenger) formErrors.address = "Nombre de passager est requis ";
        if (!newVehicle.deliveryFee10) formErrors.deliveryFee10 = "Frais de livraison au dela de 10 Km ";
        if (!newVehicle.deliveryFee50) formErrors.deliveryFee50 = "Frais de livraison au dela de 50 Km ";
        if (!newVehicle.deliveryFee100) formErrors.deliveryFee100 = "Frais de livraison au dela de 100 Km ";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // Fonction de traitement d'ajout de véhicule
    const handleAddVehicle = async () => {
        if (validateForm()) {

            try {
                setUploading(true);
                // console.log(newVehicle)
                dispatch(VehicleUpdateById(paramsvehicle.id || "", getAndCheckLocalStorage(localStorageKeys.userId), newVehicle.type || "", newVehicle, navigate, toast));
            } catch (error) {
                console.error("Erreur lors de l'ajout du véhicule:", error);
            } finally {
                setUploading(false);
            }
        }
    };


    // Fonction pour gérer les changements dans les champs de type nombre et empêcher les valeurs négatives
    const handleNumberInputChange = (e, field) => {
        const value = parseFloat(e.target.value);
        if (value < 0) {
            setNewVehicle(prevState => ({ ...prevState, [field]: '' }));
        } else {
            setNewVehicle(prevState => ({ ...prevState, [field]: value }));
        }
    };



    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };
    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };
    const handlePickupSelection = (suggestion) => {
        // Met à jour le pickupLocation
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
        setNewVehicle(prevState => ({
            ...prevState,
            address: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        // Vérifie les valeurs dans suggestion
        // console.log(suggestion?.geometry?.location?.lat); // Affiche la latitude
        // console.log(suggestion?.geometry?.location?.lng); // Affiche la longitude
        // console.log(`${suggestion.name} - ${suggestion.formatted_address}`); // Affiche l'adresse complète

        // Vide les suggestions après la sélection
        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    }





    return (
        <div className="mb-5" onClick={handleCleanSugestions}>
            <Form>
                <Card>
                    <Card.Header>
                        <h3 className="mb-4">Modifier cet vehicule</h3>
                    </Card.Header>
                    <Card.Body>

                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formVehicleBrand">
                                    <Form.Label>Nom/désignation</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.brand}
                                        onChange={(e) => setNewVehicle({ ...newVehicle, brand: e.target.value })}
                                        isInvalid={!!errors.brand}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.brand}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Marque</Form.Label>
                                    <Select
                                        required
                                        options={vehiclebrandOptions}
                                        value={vehiclebrandOptions.find(option => option.value === newVehicle.vehiclebrand)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, vehiclebrand: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.vehiclebrand}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Modèle</Form.Label>
                                    <Select
                                        options={vehiclemodelsOptions}
                                        value={vehiclemodelsOptions.find(option => option.value === newVehicle.model)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, model: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.model}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Calendrier maintenance</Form.Label>
                                    <Select
                                        options={maintenancesheduleOptions}
                                        value={maintenancesheduleOptions.find(option => option.value === newVehicle.maintenanceshedule)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, maintenanceshedule: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.maintenanceshedule}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleColor">
                                    <Form.Label>Couleur</Form.Label>
                                    <Select
                                        options={colorOptions}
                                        value={colorOptions.find(option => option.value === newVehicle.color)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, color: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.color && <div className="invalid-feedback d-block">{errors.color}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Type de véhicule</Form.Label>
                                    <Select
                                        options={typeOptions}
                                        value={typeOptions.find(option => option.value === newVehicle.type)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, type: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleYear">
                                    <Form.Label>Année</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        placeholder=""
                                        value={newVehicle.year}

                                        onChange={(e) => { handleNumberInputChange(e, "year") }}
                                    />
                                    {errors.year && <div className="invalid-feedback d-block">{errors.year}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>N° d{"'"}immatriculation</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.registrationNumber}
                                        onChange={(e) => setNewVehicle({ ...newVehicle, registrationNumber: e.target.value })}
                                        isInvalid={!!errors.registrationNumber}
                                    />
                                    {errors.registrationNumber && <div className="invalid-feedback d-block">{errors.registrationNumber}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>N° d{"'"}Identification ( VIN )</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.numberIdVehicle}
                                        onChange={(e) => setNewVehicle({ ...newVehicle, numberIdVehicle: e.target.value })}
                                        isInvalid={!!errors.numberIdVehicle}
                                    />
                                    {errors.numberIdVehicle && <div className="invalid-feedback d-block">{errors.numberIdVehicle}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={12}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Vignette impôt (Année) </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        placeholder=""
                                        value={newVehicle.vignetteImpot}
                                        onChange={(e) =>{ handleNumberInputChange(e, "vignetteImpot")}}
                                    />
                                </Form.Group>
                            </Col>


                            {/* Addrese du véhicule */}
                            <Col md={6}>
                                <Form.Group controlId="formVehicleMileage">
                                    <Form.Label>Passagers </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        placeholder=""
                                        min={1}
                                        max={300}
                                        value={newVehicle.passenger}
                                        onChange={(e) => { handleNumberInputChange(e, "passenger") }}
                                    />
                                    {errors.passenger && <div className="invalid-feedback d-block">{errors.passenger}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formVehicleMileage">
                                    <Form.Label>Addresse </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={pickupLocation}
                                        onChange={(e) => { handlePickupLocationChange(e) }}
                                    />
                                    {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                                    {/* Liste déroulante des suggestions */}
                                    {pickupSuggestions && pickupSuggestions.length > 0 && (
                                        <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                            {pickupSuggestions.map((suggestion, index) => (
                                                <li className="border"
                                                    key={index}
                                                    style={{ padding: '5px', cursor: 'pointer' }}
                                                    onClick={() => handlePickupSelection(suggestion)}
                                                >
                                                    {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formVehicleMileage">
                                    <Form.Label>Kilométrage </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        placeholder=""
                                        value={newVehicle.mileage}
                                        onChange={(e) => { handleNumberInputChange(e, "mileage") }}
                                    />
                                    {errors.mileage && <div className="invalid-feedback d-block">{errors.mileage}</div>}
                                </Form.Group>
                            </Col>





                            <Col md={6}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Prix Journalier</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        min={1000}
                                        max={1000000}
                                        placeholder=""
                                        value={newVehicle.basePricePerDay}
                                        onChange={(e) => { handleNumberInputChange(e, "basePricePerDay") }}
                                        isInvalid={!!errors.basePricePerDay}
                                    />
                                    {errors.basePricePerDay && <div className="invalid-feedback d-block">{errors.basePricePerDay}</div>}
                                </Form.Group>
                            </Col>



                            {/*
                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Commission ( % )</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder=""
                                        value={newVehicle.commissionPercentage}
                                        onChange={(e) => { handleNumberInputChange(e, "commissionPercentage") }}
                                        isInvalid={!!errors.commissionPercentage}
                                    />
                                </Form.Group>
                            </Col>
                        */}


                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Frais au dela 10 Km</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newVehicle.deliveryFee10}
                                        onChange={(e) => { handleNumberInputChange(e, "deliveryFee10") }}
                                        isInvalid={!!errors.deliveryFee10}
                                    />
                                    {errors.deliveryFee10 && <div className="invalid-feedback d-block">{errors.deliveryFee10}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Frais au dela de 50 Km</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newVehicle.deliveryFee50}
                                        onChange={(e) => { handleNumberInputChange(e, "deliveryFee50") }}
                                        isInvalid={!!errors.deliveryFee50}
                                    />
                                    {errors.deliveryFee50 && <div className="invalid-feedback d-block">{errors.deliveryFee50}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Frais au dela de 100 Km</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newVehicle.deliveryFee100}
                                        onChange={(e) => { handleNumberInputChange(e, "deliveryFee100") }}
                                        isInvalid={!!errors.deliveryFee100}
                                    />
                                    {errors.deliveryFee100 && <div className="invalid-feedback d-block">{errors.deliveryFee100}</div>}
                                </Form.Group>
                            </Col>



                            <Col md={12}>
                                <Form.Group controlId="formImages">
                                    <Form.Label>Photos de l{"'"}état initial</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    <div className="mt-2">
                                        {newVehicle.images.length > 0 && (
                                            <div>
                                                <h5>Images sélectionnées :</h5>
                                                <ul className="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {newVehicle.images.map((image, index) => (
                                                        <li key={index} className="p-1">
                                                            <span className="text-danger text-xs"
                                                                onClick={() => {
                                                                    // On supprime l'image correspondant à l'index
                                                                    const updatedImages = newVehicle.images.filter((_, i) => i !== index);
                                                                    setNewVehicle({ ...newVehicle, images: updatedImages });
                                                                }}
                                                            >
                                                                <MdClose />
                                                            </span>
                                                            <div>
                                                                <img src={image} style={{ height: "50px", width: "50px" }} />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>

                            <div className="col-md-12 " style={{ display: "flex", alignItems: "center" }}>
                                <input style={{ height: "30px", width: "30px" }} className="p-2"
                                    type="checkbox"
                                    name="firstname" title="Afficher mof de passe"
                                    checked={newVehicle.isVisible}
                                    onChange={(e) => { setNewVehicle({ ...newVehicle, isVisible: e.target.checked }); }}
                                />
                                <label className="p-2"> Mise en ligne sur la plateforme </label>
                            </div>
                        </Row>

                        <div className="" style={{ display: "flex", justifyContent: "start" }}>
                            {
                                uploading ?
                                    <p>Chargement ....</p>
                                    :
                                    <Button
                                        className="mt-5"
                                        variant="primary"
                                        onClick={handleAddVehicle}
                                        size="sm"
                                        disabled={uploading}
                                    >
                                        {uploading ? "Téléchargement..." : "Mise a jour"}
                                    </Button>
                            }
                        </div>
                    </Card.Body>
                    <Card.Footer>


                    </Card.Footer>
                </Card>




            </Form>
        </div>
    );
}

export default UpdateVehiclePage;
