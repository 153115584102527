import axios from "axios";
import { FETCH_VEHICLES_FAILURE, FETCH_VEHICLES_REQUEST, FETCH_VEHICLES_SUCCESS, FETCH_VEHICLES_SUCCESS_2, FETCH_VEHICLES_SUCCESS_AVAILABLE, FETCH_VEHICLES_SUCCESS_UNAVAILABLE, FETCH_VEHICLE_FAILURE, FETCH_VEHICLE_REQUEST, FETCH_VEHICLE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";





// Create Vehicle
export const VehicleCreate = (
    idUser, idTypeVehicle, data,navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/vehicles/add/by/${idUser}/type/${idTypeVehicle}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Véhicule créer avec succès");
                dispatch({ type: FETCH_VEHICLE_SUCCESS });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`)
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error("Création de cet compte impossible")
                dispatch({ type: FETCH_VEHICLE_FAILURE, payload: error.message });
            });
    };
}





export const VehicleUpdateById = (
    idVehicle,
    idUser, idTypeVehicle, data,
    navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/vehicles/update/${idVehicle}/by/${idUser}/type/${idTypeVehicle}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Véhicule mis a jour avec succès",{position:"bottom-right"});
                dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: response.data.data });
                dispatch(fetchVehicleById(idVehicle))
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`)
            })
            .catch((error) => {
                toast.error("Mise à jour impossible",{position:"bott-right"})
                dispatch({ type: FETCH_VEHICLE_FAILURE, payload: error.message });
            });
    };
}















// 
export const VehicleStatutUpdateById = (idVehicle, idUser, status, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_REQUEST });
        await axios
            .patch(`${baseurl.url}/api/v1/vehicles/status/${idVehicle}/by/${idUser}`, { "status": status }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_VEHICLE_SUCCESS });
                dispatch(fetchVehicleById(idVehicle));
                toast.success("Disponilité mis à jour");
            })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_FAILURE, payload: error.message });
                toast.error("Disponibilété non mis ajour")
            });
    };
}







// All vehicle of plateforme
export function fetchVehiclesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicles`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Vehicles);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
            });
    }
}



// All vehicle of plateforme of Owner
export function fetchVehiclesAllByOwnerId(idOwner) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicles/by/${idOwner}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.Vehicles);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
            });
    }
}




// All vehicle of plateforme of Owner
export function fetchVehiclesAllByOwnerIdAvailable(idOwner) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicles/by/${idOwner}/available`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.Vehicles);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
            });
    }
}








// All vehicle of plateforme of Owner with availability filter
export function fetchVehiclesAllResearch( availableStartDate, availableEndDate, companyId = null,address) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });

        // Préparer les paramètres de requête
        let queryParams = `?availableStartDate=${availableStartDate}&availableEndDate=${availableEndDate}`;
        if (companyId) {
            queryParams += `&companyId=${companyId}`;
        }
        if (address) {
            queryParams += `&address=${address}`;
        }

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/vehicles/get_vehicles_params${queryParams}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLES_SUCCESS_2, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
        });
    }
}






// All vehicle of plateforme of Owner with availability filter
export function fetchVehiclesAllResearchAvailable( availableStartDate, availableEndDate, companyId = null,address) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });

        // Préparer les paramètres de requête
        let queryParams = `?availableStartDate=${availableStartDate}&availableEndDate=${availableEndDate}`;
        if (companyId) {
            queryParams += `&companyId=${companyId}`;
        }
        if (address) {
            queryParams += `&address=${address}`;
        }

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/vehicles/get_vehicles_params_available${queryParams}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS_AVAILABLE, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
        });
    }
}






// All vehicle of plateforme of Owner with unavailability filter
export function fetchVehiclesAllResearchUnAvailable( availableStartDate, availableEndDate, companyId = null,address) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });

        // Préparer les paramètres de requête
        let queryParams = `?availableStartDate=${availableStartDate}&availableEndDate=${availableEndDate}`;
        if (companyId) {
            queryParams += `&companyId=${companyId}`;
        }
        if (address) {
            queryParams += `&address=${address}`;
        }

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/vehicles/get_vehicles_params_occupied${queryParams}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS_UNAVAILABLE, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
        });
    }
}






// filter vehicles
export function fetchVehiclesAllFilter(
    availableStartDate,
    availableEndDate,
    address = null,
    userAdd = null,
    owner = null,
    vehiclebrand = null,
    color = null,
    model = null
) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLES_REQUEST });

        // Créer un objet URLSearchParams pour les paramètres de requête
        const params = new URLSearchParams();
       
        // Ajouter les filtres facultatifs
        if (availableStartDate) params.append("availableStartDate", availableStartDate);
        if (availableEndDate) params.append("availableEndDate", availableEndDate);
        if (address) params.append("address", address);
        if (userAdd) params.append("userAdd", userAdd);
        if (owner) params.append("owner", owner);
        if (vehiclebrand) params.append("vehiclebrand", vehiclebrand);
        if (color) params.append("color", color);
        if (model) params.append("model", model);

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/vehicles/get_vehicles_filter?${params.toString()}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLES_SUCCESS_2, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_VEHICLES_FAILURE, payload: error.message });
        });
    };
}










// get vehicle by id 
export function fetchVehicleById(idVehicle) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicle/${idVehicle}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




