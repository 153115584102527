import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const ImageGalleryComponent = ({ idCardFile = [], driverLicenseFile = [] }) => {
    // Formatage des images pour react-image-gallery
    const formatImages = (images) => {
        return images.map((image) => ({
            original: image,
            thumbnail: image,
        }));
    };

    return (
        <>
            <Row className="mb-3 mt-4">
                <Col md={6}>
                    <Form.Group controlId="idCardFile">
                        <Form.Label>Fichiers Carte d{"'"}identité (Recto / verso)</Form.Label>
                        {idCardFile.length > 0 ? (
                            <ImageGallery items={formatImages(idCardFile)} />
                        ) : (
                            <p>Aucun fichier disponible.</p>
                        )}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="driverLicenseFile">
                        <Form.Label>Fichiers de permis de conduire (Recto / verso)</Form.Label>
                        {driverLicenseFile.length > 0 ? (
                            <ImageGallery items={formatImages(driverLicenseFile)} />
                        ) : (
                            <p>Aucun fichier disponible.</p>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default ImageGalleryComponent;
