import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Badge, Card, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { fetchMaintenancesAll, MaintenanceCreate, MaintenanceCancelById, MaintenanceDeleteById, MaintenanceUpdateById } from '../../../actions/request/MaintenanceRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import moment from 'moment';
import { fetchMotifsAll } from '../../../actions/request/MotifRequest';
import { MdOutlineSearch } from 'react-icons/md';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { fetchMaintenanceCategorysAll } from '../../../actions/request/MaintenanceCategoryRequest';

const MaintenanceManagementPage = () => {
    const dispatch = useDispatch();
    const maintenanceCategorys = useSelector((state) => state.maintenanceCategorys.maintenanceCategorys);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const maintenancesData = useSelector((state) => state.maintenances.maintenances);
    // const maintenanceCategorys = useSelector((state) => state.maintenanceCategorys.maintenanceCategorys);

    const [maintenanceCategorySelect, setmaintenanceCategorySelect] = useState(null);
    const [vehicleSelect, setvehicleSelect] = useState(null);
    const [propertySelect, setpropertySelect] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [description, setDescription] = useState('');
    const [cost, setCost] = useState(''); // Ajout du champ coût
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState('add'); // add, cancel, delete
    const [selectedMaintenance, setSelectedMaintenance] = useState(null);
    const [idMaintenance, setidMaintenance] = useState("");

    useEffect(() => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchMaintenanceCategorysAll());
            dispatch(fetchPropertysFilters("", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllFilter("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchMotifsAll());

        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
        }
        else {
            dispatch(fetchMaintenancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
        }
    }, [dispatch]);

    // Filtre des options
    const maintenanceCategoryOptions = maintenanceCategorys?.map(item => ({ value: item._id, label: item.name })) || [];
    const vehicleOptions = vehicles?.map(item => ({ value: item._id, label: item.brand })) || [];
    const propertyOptions = propertys?.map(item => ({ value: item._id, label: item.name })) || [];

    // Fonction pour ouvrir le modal d'ajout ou d'action
    const handleModal = (action, maintenance = null) => {
        setModalAction(action);
        setSelectedMaintenance(maintenance);
        setShowModal(true);
        if (maintenance) {
            // console.log(maintenance);

            setidMaintenance(maintenance._id);
            setmaintenanceCategorySelect(maintenance.maintenanceCategory);
            setDescription(maintenance.description);
            setStartDate(moment(maintenance.startDate).format("YYYY-MM-DD"));
            setEndDate(moment(maintenance.endDate).format("YYYY-MM-DD"));
            if (maintenance && maintenance.property) {
                setpropertySelect({ value: maintenance.property?._id, label: maintenance.property?.name });
            } else if (maintenance && maintenance.vehicle) {
                setvehicleSelect({ value: maintenance.vehicle?._id, label: maintenance.vehicle?.brand });
            } else {
                setpropertySelect(null);
                setvehicleSelect(null);
            }
        }
    };



    const renderStatusBadge = (status) => {
        switch (status) {

            case 'PLANNED':
                return <Badge bg="info">Planifié</Badge>;
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;

            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };



    // gestion des assurances en question
    const handleChangeParse = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);
        setCost(parsedValue >= 0 ? value : '');
    };



    // Griser les dates passées
    const minStartDate = moment().format('YYYY-MM-DD');

    // Griser les dates avant la date de début + 2 jours
    const minEndDate = startDate ? moment(startDate).add(2, 'days').format('YYYY-MM-DD') : '';

    // Validation des champs avant soumission
    const validateForm = () => {
        if ( !maintenanceCategorySelect?.value && !startDate && !endDate && !description || (!vehicleSelect || !propertySelect) && !maintenanceCategorySelect && !cost) {
            toast.error('Tous les champs doivent être remplis');
            return false;
        }
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start < new Date()) {
            toast.error("La date de début ne peut pas être dans le passé ou celle d'aujourd'hui");
            return false;
        }
        if ((end - start) / (1000 * 60 * 60 * 24) < 2) {
            toast.error("La date de fin doit être au moins deux jours après la date de début.");
            return false;
        }
        return true;
    };

    // Fonction pour soumettre les actions
    const handleSubmitAction = () => {
        if (modalAction === 'add') {
            if (!validateForm()) return;
            const data = {
                category: maintenanceCategorySelect?.value,
                property: propertySelect?.value,
                vehicle: vehicleSelect?.value,
                description,
                startDate,
                endDate,
                cost: Number(cost), // Enregistrer le coût en tant que nombre
                userAdd: getAndCheckLocalStorage(localStorageKeys.userId)
            };

            dispatch(MaintenanceCreate(data, toast));
        }
        else if (modalAction === 'edit') {
            if (!validateForm()) return;
            const data = {
                category: maintenanceCategorySelect?.value,
                property: propertySelect?.value,
                vehicle: vehicleSelect?.value,
                description,
                startDate,
                endDate,
                cost: Number(cost), // Enregistrer le coût en tant que nombre
                userAdd: getAndCheckLocalStorage(localStorageKeys.userId)
            };
            dispatch(MaintenanceUpdateById(idMaintenance, data, toast));
            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
        }
        else if (modalAction === 'cancel') {
            dispatch(MaintenanceCancelById(selectedMaintenance._id, toast));
            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
        } else if (modalAction === 'delete') {
            dispatch(MaintenanceDeleteById(selectedMaintenance._id, toast));
            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
        }
        setShowModal(false);
    };

    const [startDateSearch, setstartDateSearch] = useState('');
    const [endDateSearch, setendDateSearch] = useState('');
    const handleFilter = () => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {

            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId), startDateSearch, endDateSearch, propertySelect?.value, vehicleSelect?.value, ""));

        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
        }
        else {
            dispatch(fetchMaintenancesAll("", startDateSearch, endDateSearch, propertySelect?.value, vehicleSelect?.value, ""));
        }

    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4">Entretiens</h3>
                        </div>
                        <div>
                            {
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                                <Button variant="primary" size='sm' className="ml-2" onClick={() => {
                                    handleModal("add");
                                    setpropertySelect(null)
                                    setvehicleSelect(null)
                                    setmaintenanceCategorySelect(null)
                                    setDescription("")
                                    setStartDate("")
                                    setEndDate("")
                                    setCost(0)
                                }}>+ Ajouter</Button>:""
                            }
                        </div>
                    </div>
                </Card.Header>

                <Card.Body>
                    <>
                        <ul className="list-group">
                            <li className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                    <Form.Label>Véhicule</Form.Label>
                                                    <Select
                                                        value={vehicleSelect}
                                                        onChange={(selectedOption) => setvehicleSelect(selectedOption)}
                                                        options={vehicleOptions}
                                                        isClearable
                                                        isSearchable
                                                        placeholder="Choix"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                    <Form.Label>Propriété</Form.Label>
                                                    <Select
                                                        value={propertySelect}
                                                        isClearable
                                                        isSearchable
                                                        onChange={(selectedOption) => setpropertySelect(selectedOption)}
                                                        options={propertyOptions}
                                                        placeholder="Choix"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="p-1">
                                        <label>{""}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={startDateSearch} onChange={(e) => setstartDateSearch(e.target.value)} />
                                    </div>
                                    <div className="p-1">
                                        <label>{""}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={endDateSearch} onChange={(e) => setendDateSearch(e.target.value)} />
                                    </div>
                                    <div className="m-1">
                                        <label>{""}</label>
                                        <div>
                                            <Button variant='primary' size='sm' onClick={handleFilter} >
                                                <MdOutlineSearch />
                                            </Button>
                                        </div>

                                    </div>

                                </div>
                            </li>
                        </ul>
                    </>

                    <div style={{ minHeight: "500px" }} >
                        {maintenancesData?.length === 0 ? (
                            <p className="text-center mt-5">Aucun entretien</p>
                        ) : (
                            <Table responsive bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Type</th>
                                        <th>Objet</th>
                                        <th>Début</th>
                                        <th>Fin</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {maintenancesData.map(maintenance => (
                                        <tr key={maintenance._id}>
                                            <td>
                                                {maintenance.property?.images?.[0] ? (
                                                    <img src={maintenance.property.images[0]} alt="Propriété" style={{ height: '30px' }} />
                                                ) : (
                                                    maintenance.vehicle?.images?.[0] && <img src={maintenance.vehicle.images[0]} alt="Véhicule" style={{ height: '30px' }} />
                                                )}
                                            </td>
                                            <td>{maintenance.vehicle ? 'Véhicule' : 'Propriété'}</td>
                                            <td>{maintenance.property?.name || maintenance.vehicle?.brand}</td>
                                            <td>{moment(maintenance.startDate).format('DD-MM-YYYY')}</td>
                                            <td>{moment(maintenance.endDate).format('DD-MM-YYYY')}</td>
                                            <td>{renderStatusBadge(maintenance.status)}</td>
                                            <td>
                                                <DropdownButton id="dropdown-basic-button" title="Actions" size="sm">
                                                    <Dropdown.Item onClick={() => handleModal('edit', maintenance)}>Modifier</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleModal('cancel', maintenance)}>Annuler</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleModal('delete', maintenance)}>Supprimer</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>

                    {/* Modal pour les actions de maintenance */}
                    <Modal show={showModal} size='lg' onHide={() => setShowModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{modalAction === 'add' ? 'Nouvelle maintenance' : modalAction === 'edit' ? 'Modifier cet maintenance' : modalAction === 'cancel' ? 'Annuler la maintenance' : 'Supprimer la maintenance'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(modalAction === 'add' || modalAction === 'edit') && (
                                <Row>

                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Motif</Form.Label>
                                            <Select
                                                value={maintenanceCategorySelect}
                                                onChange={setmaintenanceCategorySelect}
                                                options={maintenanceCategoryOptions}
                                                isClearable
                                                placeholder="Choix"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Véhicule</Form.Label>
                                            <Select
                                                value={vehicleSelect}
                                                onChange={setvehicleSelect}
                                                options={vehicleOptions}
                                                isClearable
                                                placeholder="Choix"
                                                isDisabled={!!propertySelect}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Propriété</Form.Label>
                                            <Select
                                                value={propertySelect}
                                                onChange={setpropertySelect}
                                                options={propertyOptions}
                                                isClearable
                                                placeholder="Choix"
                                                isDisabled={!!vehicleSelect}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Date de début</Form.Label>
                                            <Form.Control
                                                style={{ height: "40px" }}
                                                type="date"
                                                value={startDate}
                                                min={minStartDate}  // Griser les dates passées
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Date de fin</Form.Label>
                                            <Form.Control
                                                style={{ height: "40px" }}
                                                type="date"
                                                value={endDate}
                                                min={minEndDate}  // Griser les dates avant 2 jours après la date de début
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Coût</Form.Label>
                                            <Form.Control
                                                style={{ height: "40px" }}
                                                type="number"
                                                value={cost}
                                                onChange={handleChangeParse}
                                                placeholder=""
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                placeholder=""
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                            )}
                            <p>{modalAction === 'cancel' && 'Êtes-vous sûr de vouloir annuler cet entretien ?'}</p>
                            <p>{modalAction === 'delete' && 'Êtes-vous sûr de vouloir supprimer cet entretien ?'}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" size="sm" onClick={() => setShowModal(false)}>Fermer</Button>
                            <Button variant="primary" size="sm" onClick={handleSubmitAction}>{modalAction === 'add' ? 'Ajouter' : modalAction === 'edit' ? 'Mettre à jour' : modalAction === 'cancel' ? 'Annuler' : 'Supprimer'}</Button>
                        </Modal.Footer>
                    </Modal>
                </Card.Body>
            </Card>
        </div>
    );
};

export default MaintenanceManagementPage;
