import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchVehiclesAll } from '../../../actions/request/VehicleRequest';
import { fetchPropertysAll } from '../../../actions/request/PropertyRequest';
import { fetchDamageTypesAll } from '../../../actions/request/DamageTypeRequest';
import { DamageCreate } from '../../../actions/request/DamageRequest';
import { baseurl } from '../../../utils/url/baseurl';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { MdColorize, MdDateRange, MdMap } from 'react-icons/md';
import { handleImageUploadCloud } from '../../../actions/upload/UploadFileCloud';

const DamageReportPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const damageTypes = useSelector((state) => state.damageTypes.damageTypes);

    const { _id, images, ordre, vehicle, property, startDate, endDate, createdAt, updatedAt, totalPrice } = location.state
    useEffect(() => {
        dispatch(fetchPropertysAll());
        dispatch(fetchVehiclesAll());
        dispatch(fetchDamageTypesAll());
        if (_id && vehicle && vehicle._id) {
            setSelectedVehicle("Vehicle")
        } else {
            setSelectedVehicle("Property")
        }
    }, [dispatch])

    const [selectedPatrimoine, setSelectedPatrimoine] = useState(null);
    const [selectedDamgeType, setSelectedDamgeType] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [photosBefore, setPhotosBefore] = useState([]);
    const [photosAfter, setPhotosAfter] = useState([]);
    const [comments, setComments] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [damageDate, setDamageDate] = useState('');



    const handleFileChangeAfter = (e) => {
        setPhotosAfter(e.target.files);
    };

    const handleEstimatedCostChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value < 0) {
            setEstimatedCost('');
        } else {
            setEstimatedCost(value);
        }
    };

    const handleSubmit = async () => {
        if (!selectedVehicle || photosAfter.length == 0 || !estimatedCost || !damageDate || !comments) {
            console.log(
                {
                    reservation: _id,
                    type: selectedDamgeType && selectedDamgeType.value,
                    relatedObjectType: selectedVehicle,
                    description: comments,
                    damageDate,
                    estimatedCost,
                }
            )

            setAlertMessage('Veuillez remplir tous les champs et télécharger les photos.');
            toast.error("Veuillez remplir tous les champs et télécharger les photos.", { position: "bottom-right" });
            setShowAlert(true);

            return;
        }

        // try {
        const afterPhotosUrls = await handleImageUploadCloud(photosAfter, toast);

        const damageReport = {
            reservation: _id,
            type: selectedDamgeType && selectedDamgeType.value,
            relatedObjectType: selectedVehicle,
            description: comments,
            damageDate: damageDate,
            userId: getAndCheckLocalStorage(localStorageKeys.userId),
            estimatedCost: Number(estimatedCost),
            vehicle: vehicle?._id || null,
            property: property?._id || null,
            afterUsePhoto: afterPhotosUrls.filter(url => url !== null)
        };
        console.log(damageReport)

        dispatch(DamageCreate(damageReport, navigate, toast));

        setAlertMessage('Rapport de dommages soumis avec succès !');
        setShowAlert(false);
        handleCancel();
        // } catch (error) {
        //     setAlertMessage('Erreur lors de la soumission du rapport de dommages.');
        //     toast.error('Erreur lors de la soumission du rapport de dommages.', { position: "bottom-right" });
        //     setShowAlert(true);
        // }
    };

    const handleCancel = () => {
        setSelectedVehicle(null);
        setSelectedProperty(null);
        setPhotosBefore([]);
        setPhotosAfter([]);
        setComments('');
        setEstimatedCost('');
        setDamageDate('');
        setShowAlert(false);
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <h3 className="mb-4">Nouveau domage</h3>
                </Card.Header>
                <Card.Body>
                    {showAlert && (
                        <Alert variant={alertMessage.includes('succès') ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible>
                            {alertMessage}
                        </Alert>
                    )}

                    <Form>
                        <Row>
                            <Col md={12}>
                                {
                                    ordre ?
                                        <div className="card" style={{ width: "100%" }}>
                                            <div className="card-body">
                                                <Row>
                                                    <Col md={2}>
                                                        {vehicle && vehicle.images && vehicle.images.length && vehicle.images.length > 0 ?
                                                            <Image src={vehicle.images[0]} className="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={ordre} /> : ""
                                                        }
                                                        {property && property.images && property.images.length && property.images.length > 0 ?
                                                            <Image src={property.images[0]} className="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={ordre} /> : ""
                                                        }
                                                    </Col>
                                                    <Col md={10}>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">Réservation: <strong> {ordre || ""} </strong> </li>
                                                            {
                                                                vehicle && vehicle.brand ? <li className="list-group-item">{vehicle.brand || ""}</li> : ""
                                                            }
                                                            {
                                                                vehicle && vehicle.model ? <li className="list-group-item">Model :  {vehicle.model || ""}</li> : ""
                                                            }
                                                            {
                                                                vehicle && vehicle.color ? <li className="list-group-item"><MdColorize /> {vehicle.color || ""}</li> : ""
                                                            }

                                                            {
                                                                property && property.name ? <li className="list-group-item">titre: {property.name || ""}</li> : ""
                                                            }
                                                            {
                                                                property && property.address ? <li className="list-group-item"><MdMap /> {property.address || ""}</li> : ""
                                                            }

                                                            {
                                                                startDate && endDate ?
                                                                    <li className="list-group-item">
                                                                        <MdDateRange />
                                                                        <span>
                                                                            Période :  {moment(startDate).format("DD-MM-YYYY - HH:MM") || ""}

                                                                            {moment(endDate).format("DD-MM-YYYY - HH:MM") || ""}
                                                                        </span>
                                                                    </li> : ""
                                                            }
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Type de dommage</Form.Label>
                                    <Select
                                        value={selectedDamgeType}
                                        onChange={setSelectedDamgeType}
                                        options={damageTypes.map(damageType => ({ value: damageType._id, label: damageType.name }))}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Coût estimé</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000000}
                                        value={estimatedCost}
                                        onChange={handleEstimatedCostChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date du dommage</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="datetime-local"
                                        value={damageDate}
                                        onChange={(e) => setDamageDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Photos après utilisation</Form.Label>
                                    <Form.Control type="file" multiple accept="image/*" onChange={handleFileChangeAfter} />
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Commentaires</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={1}
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Button variant="primary" size="sm" onClick={handleSubmit}>Soumettre</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div >
    );
};

export default DamageReportPage;