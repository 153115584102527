



// // taux de change en question
// import { fetchExchangeRateGetCurency } from "../../actions/request/ExchangeRateRequest";

// async function getTauxEuroToDinar() {
//   const { buyRate } = await fetchExchangeRateGetCurency("EUR") || 0;
//   console.log('Taux Euro à Dinar :', buyRate);
//   return buyRate;
// }

// // Exemple d'appel
// getTauxEuroToDinar().then((rate) => {
//   console.log('Valeur récupérée :', rate);
// });




// export const logoApplication = "logoApplication.png";
export const logoApplication = "https://res.cloudinary.com/dt6ammifo/image/upload/v1730033828/logoApplication_zvt36g.png";

export const emptyImage = "https://media.istockphoto.com/id/1409329028/fr/vectoriel/aucune-image-disponible-espace-r%C3%A9serv%C3%A9-ic%C3%B4ne-miniature-illustration-design.jpg?s=612x612&w=0&k=20&c=2G3XRVmxPifjkDYAzuAA-5QygJVWZ6LrFdgQHqOsGrw=";


export const bgHome = "bg_service.jpg";
export const bgHome2 = "bgHome2.jpg";
export const bgHome2_2 = "bgHome2.1.jpg";
export const iconSmile = "smile.png";


export const facebookLink = "https://web.facebook.com/profile.php?id=61563997472335"
export const youtubeLink = "https://www.youtube.com"
export const ErrorIllustration = "Error.png";


export const ageDateNaissanceMax = 18;




export const colorApp = {
    bg_primary: "bg-primary",
    bg_danger: "bg-danger",
    bg_secondary: "bg-dark",
    btn_primary: "btn-primary",
    btn_delete: "btn-danger",
    btn_grey: "btn-secondary",
    btn_light: "btn-light",
    btn_info: "btn-info",
    btn_secondary: "btn-dark",
    btn_outline_secondary: "btn-outline-dark",
    btn_outline_primary: "btn-outline-warning",
    text_primary: "text-warning",
    text_secondary: "text-dark",
}


export const profilePictureDefault = "https://img.freepik.com/vecteurs-premium/homme-noir-barbu-clignotant-souriant-illustration-avatar-vectoriel-2d-portrait-personnage-dessin-anime-africain-americain-adulte-etincelant-portrait-visage-personnage-dessin-anime-amical-flirtant-homme-couleur-plate-image-profil-utilisateur-isolee-blanc_151150-19800.jpg?w=740";



export const roleAdmin = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    SUPPORT: "SUPPORT",
    DOCTOR: "DOCTOR",
    OPERATOR: "OPERATOR",
    MANAGER: "MANAGER",
}


export const profileRoleType = {
    CUSTOMER: "CUSTOMER",
    USER: "USER",
    //adminstrator
    ADMIN: "ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
    SUPPORT: "SUPPORT",
}



export const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
];



export const languagesList = [
    { value: "fr", label: "Français", picture: "https://flagcdn.com/fr.svg" },
    { value: "en", label: "Anglais", picture: "https://flagcdn.com/gb.svg" },
    { value: "es", label: "Espagnol", picture: "https://flagcdn.com/es.svg" },
    { value: "de", label: "Allemand", picture: "https://flagcdn.com/de.svg" },
    { value: "zh", label: "Chinois", picture: "https://flagcdn.com/cn.svg" },
    { value: "ja", label: "Japonais", picture: "https://flagcdn.com/jp.svg" },
    { value: "ru", label: "Russe", picture: "https://flagcdn.com/ru.svg" },
    { value: "ar", label: "Arabe", picture: "https://flagcdn.com/ae.svg" },
    { value: "pt", label: "Portugais", picture: "https://flagcdn.com/pt.svg" },
    { value: "it", label: "Italien", picture: "https://flagcdn.com/it.svg" }
];
