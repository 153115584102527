import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderWeb from '../components/web/HeaderWeb';
import FooterWeb from '../components/FooterWeb';


const LayoutWeb = () => {
    return (
        <>
            <div className="page-wrapper">

                <HeaderWeb />
                <Outlet />
                <FooterWeb />
                <button className="scroltop icon-up" type="button"><i className="fas fa-arrow-up"></i></button>


            </div>

        </>
    )
}

export default LayoutWeb
