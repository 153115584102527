import { toast } from "react-toastify";

export function copyToClipboard(text) {
    // Vérifie si l'API Clipboard est disponible
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success("Lien copier avec succès", { position: "bottom-right" })
                // console.log('Texte copié dans le presse-papier :', text);
            })
            .catch(err => {
                // toast.error("Url du wite copier avec succès",{position:"bottom-right"})
                // console.error('Échec de la copie du texte :', err);
            });
    } else {
        // Fallback si l'API Clipboard n'est pas disponible
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            // console.log('Texte copié dans le presse-papier :', text);
            toast.success("Texte copié dans le presse-papier ", { position: "bottom-right" })
        } catch (err) {
            // console.error('Échec de la copie du texte :', err);
        }
        document.body.removeChild(textArea);
    }
}



export const getCleanUrl = () => {
    // Utilise `window.location` pour obtenir l'URL actuelle
    const { protocol, hostname, port } = window.location;

    // Si un port est défini, on l'ajoute, sinon on ne l'inclut pas
    const portPart = port ? `:${port}` : '';

    // Reconstruire l'URL de base sans le chemin
    return `${protocol}//${hostname}${portPart}`;
};
