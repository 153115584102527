import React, { useState } from 'react';
import { Tab, Tabs, Form, Button, Image, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePostalsAll } from '../../../actions/request/CodePostalRequest';
import { useEffect } from 'react';
import { UserCreate, UserUpdateById } from '../../../actions/request/UserRequest';
import { toast } from 'react-toastify';
import Select from "react-select";
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMER_FAILURE, FETCH_CUSTOMER_SUCCESS, FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../../../app/actions/actions';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import { handleImageUploadCloudOnly } from '../../../actions/upload/UploadFileCloud';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { CustomerUpdateById } from '../../../actions/request/CustomerRequest';


function ProfileAccount() {
    const [key, setKey] = useState('profile');
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);
    const codepostals = useSelector((state) => state.codepostals.codepostals);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        companyName: '',
        phone: '',
        codePostal: '',
        gender: 'Male',
        dateOfBirth: '',
        profilePicture: '',
        profession: '',
        address: '',
        addressPostal: '',
        numberTVA: '',
        role: 'SUPER_ADMIN',
        description: '',
        location: '',
    });



    function fetchUserByGet() {
        return async (dispatch) => {
            dispatch({ type: FETCH_USER_REQUEST });
            dispatch({ type: FETCH_CUSTOMERS_REQUEST });
            await axios.get(
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                    `${baseurl.url}/api/v1/users/get_user/${getAndCheckLocalStorage(localStorageKeys.userId)}`
                    :
                    `${baseurl.url}/api/v1/customers/get_customer/${getAndCheckLocalStorage(localStorageKeys.userId)}`

                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }).then((response) => {
                    console.log(response.data.data);
                    const responseData = response.data.data;
                    dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.data });
                    dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data.data });
                    setFormData(
                        {
                            firstname: responseData.firstname,
                            lastname: responseData.lastname,
                            companyName: responseData.companyName,
                            phone: responseData.phone,
                            codePostal: responseData.codePostal,
                            gender: responseData.gender,
                            dateOfBirth: responseData.dateOfBirth,
                            profilePicture: responseData.profilePicture,
                            profession: responseData.profession,
                            address: responseData.address,
                            addressPostal: responseData.addressPostal,
                            numberTVA: responseData.numberTVA,
                            role: responseData.role,
                            description: responseData.description,
                            location: responseData.location,
                        }
                    )
                })
                .catch((error) => {
                    dispatch({ type: FETCH_USER_FAILURE, payload: error.message })
                    dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message })
                    console.log(error);
                });
        }
    }


    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Récupère le premier fichier sélectionné
        const url = await handleImageUploadCloudOnly(file, toast);
        if (url) {
            setFormData({ ...formData, profilePicture: url });
            // Tu peux maintenant utiliser l'URL, par exemple l'envoyer dans une requête pour sauvegarder l'image dans ta base de données
        }
    };




    useEffect(() => {
        // Fetch codePostals and companies from API
        dispatch(fetchCodePostalsAll());
        dispatch(fetchUserByGet());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit logic here
        if (
            !formData.firstname ||
            !formData.lastname ||
            !formData.codePostal ||
            !formData.phone ||
            !formData.dateOfBirth
        ) {
            toast.error("Champx avec * non requis", { position: "bottom-right" })
        }
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            dispatch(UserUpdateById(getAndCheckLocalStorage(localStorageKeys.userId), formData, toast))
        } else {
            dispatch(CustomerUpdateById(getAndCheckLocalStorage(localStorageKeys.userId), formData, toast))
        }

    };

    // Prepare options for react-select
    const codePostalOptions = codepostals.map((code) => ({
        value: code._id,
        label: `${code.indicatif} - ${code.country}`,
    }));

    const genderOptions = [
        { value: 'Male', label: 'Homme' },
        { value: 'Female', label: 'Femme' },
    ];

    const roleOptions = [
        { value: 'INDIVIDUAL', label: 'Individu' },
        { value: 'COMPANY', label: 'Entreprise' },
        { value: 'ADMIN', label: 'Administrateur' },
        { value: 'SUPER_ADMIN', label: 'Super Administrateur' },
    ];

    return (

        <div>
            <Form onSubmit={handleSubmit}>
                {loadingUser ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                        <p>Chargement en cours...</p>
                    </div>
                ) : (
                    <>
                        <div className="row">

                            <div className="col-md-4 form-group">
                                <label>Nom *</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="col-md-4 form-group">
                                <label>Prénom *</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="col-md-4 form-group">
                                <label>Entreprise </label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className=" col-md-6 form-group">
                                <label>Téléphone *</label>
                                <input style={{ height: "40px" }}
                                    type="number"
                                    className="form-control"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className=" col-md-6 form-group">
                                <label>Indicatif (téléphone ) * </label>
                                <Select
                                    required
                                    name="codePostal"
                                    isClearable
                                    options={codePostalOptions}
                                    onChange={handleSelectChange}
                                    value={codePostalOptions.find(option => option.value === formData.codePostal)}
                                    placeholder="Sélectionner un code postal"
                                />
                            </div>
                            {/*<div className=" col-md-4 form-group">
                                <label>Genre * </label>
                                <Select
                                required
                                    name="gender"
                                    isClearable
                                    options={genderOptions}
                                    onChange={handleSelectChange}
                                    value={genderOptions.find(option => option.value === formData.gender)}
                                    placeholder="Sélectionner un genre"
                                />
                            </div> */}
                            <div className="col-md-6 form-group">
                                <label>Date de naissance * </label>
                                <input style={{ height: "40px" }}
                                    type="date"
                                    className="form-control"
                                    name="dateOfBirth"
                                    value={formData.dateOfBirth}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <div style={{ display: "flex", flexWrap: "wrap",alignItems:"center" }}>
                                    <div>
                                        <label>Photo de profil:</label>
                                        <input
                                            style={{ height: "40px" }}
                                            type="file" accept=".jpg,.png,.jpeg"
                                            className="form-control"
                                            name="profilePicture"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <div>
                                        <Image src={formData.profilePicture} style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 form-group">
                                <label>Profession:</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="profession"
                                    value={formData.profession}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <label>Adresse (Entreprise / Personnel):</label>
                                <input
                                    style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <label>Adresse postale (Entreprise / Personnel):</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="addressPostal"
                                    value={formData.addressPostal}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12 form-group">
                                <label>Numéro de TVA:</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="numberTVA"
                                    value={formData.numberTVA}
                                    onChange={handleChange}
                                />
                            </div>


                            {/*<div className="col-md-4 form-group">
                                <label>Rôle:</label>
                                <Select
                                    name="role"
                                    isClearable
                                    options={roleOptions}
                                    onChange={handleSelectChange}
                                    value={roleOptions.find(option => option.value === formData.role)}
                                    placeholder="Sélectionner un rôle"
                                />
                            </div>*/}

                            {
                                /*<div className="col-md-4 form-group">
                                <label>Emplacement:</label>
                                <input style={{ height: "40px" }}
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}
                                />
                            </div> */
                            }

                            <div className="col-md-12 form-group">
                                <label>Description:</label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                ></textarea>
                            </div>

                        </div>
                        <hr className="my-5" />
                        <button type="submit" className="btn  btn-sm btn-primary mt-5">Mise à jour</button>
                    </>
                )}
            </Form>
        </div>
    );
}

export default ProfileAccount;
