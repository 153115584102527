import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table, Modal, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchCodePostalById, fetchCodePostalsAll } from '../../actions/request/CodePostalRequest';
import { fetchVehicleById } from '../../actions/request/VehicleRequest';
import { emptyImage, logoApplication } from '../../utils/dataApi/dataFormApi';
import { MdAirplaneTicket, MdCategory, MdColorize, MdMap, MdMarkAsUnread, MdMergeType, MdModelTraining, MdOutlineAirplanemodeActive, MdPerson, MdTimer } from 'react-icons/md';
import { ReservationCreateClient } from '../../actions/request/ReservationRequest';
import { toast } from 'react-toastify';
import { fetchPropertyById } from '../../actions/request/PropertyRequest';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { fetchCompanyAiportsAll } from '../../actions/request/CompanyAiportRequest';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { baseurl } from '../../utils/url/baseurl';
import { loadStripe } from '@stripe/stripe-js';
import { fetchExchangeRateGetCurency } from '../../actions/request/ExchangeRateRequest';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { localStorageData } from '../../utils/storage/localvalue';





// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});



const InvoiceReservationWebPage = () => {

    const currency = "eur"
    const stripe = useStripe();
    const elements = useElements();
    const [errorStripe, setErrorStripe] = useState(null);
    const [success, setSuccess] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [loadingCard, setloadingCard] = useState(false)


    const navigate = useNavigate();
    const location = useLocation();
    const {
        //more infos reservation
        idCard,
        idCardFile,
        driverLicenseNumber,
        driverLicenseFile,
        dateOfBirth,
        pickupLocation,
        returnLocation,
        // other
        reservationElement, type, vehicle, firstname, image, property,
        userAdd,
        basePricePerDay,
        commissionPercentage,
        deliveryFee,
        lastname, email, phone, codePostal, numberTVA, startDate, endDate, specialRequests,
        // localisation
        latStart,
        lngStart,
        latEnd,
        lngEnd,
        companyAirport,
        numberVolAiport

    } = location.state || {};

    const dispatch = useDispatch();

    const companyairports = useSelector((state) => state.companyairports.companyairports)

    const loadingReservation = useSelector((state) => state.reservations.loadingReservation);
    const vehicleData = useSelector((state) => state.vehicles.vehicle);
    const propertyData = useSelector((state) => state.propertys.property);
    const codepostals = useSelector((state) => state.codepostals.codepostals);

    const [euroToDinar, setEuroToDinar] = useState(0);

    useEffect(() => {
        if (!userAdd) {
            // toast.error("Veillez rempir les champs du formualire s'il vous plais")
            navigate(-1);
        }
        dispatch(fetchCompanyAiportsAll())
        dispatch(fetchCodePostalsAll());
        dispatch(fetchVehicleById(vehicle));
        dispatch(fetchPropertyById(property));

        async function getExchangeRate() {
            const { buyRate } = await fetchExchangeRateGetCurency("EUR");
            setEuroToDinar(buyRate || getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow));
            // console.log(getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow))
        }
        getExchangeRate();
    }, [dispatch])


    const [showModal, setShowModal] = useState(false);
    const [promoCode, setPromoCode] = useState('');

    const vehicleInfo = {
        name: vehicle?.brand || vehicleData?.brand,
        image: vehicle?.image || emptyImage,
        type: type,
        basePricePerDay: basePricePerDay, // Prix journalier en DZD
        deliveryFee: deliveryFee || 0, // Frais de livraison
        commissionPercentage: commissionPercentage || 0, // Pourcentage de commission
        discount: 0, // Remise initiale
        deliveryFee10: Number(vehicleData?.deliveryFee10 || vehicle?.deliveryFee10 || 0),
        deliveryFee50: Number(vehicleData?.deliveryFee50 || vehicle?.deliveryFee50 || 0),
        deliveryFee100: Number(vehicleData?.deliveryFee100 || vehicle?.deliveryFee100 || 0),

    };

    const userInfo = {
        firstName: firstname,
        lastName: lastname,
        phone: phone,
        email: email,
    };

    // Fonction pour calculer la durée en jours, heures, minutes
    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const durationInMs = end - start;
        const durationInHours = durationInMs / (1000 * 60 * 60);

        let days = Math.floor(durationInHours / 24);
        let hours = Math.floor(durationInHours % 24);
        let minutes = Math.floor((durationInHours % 1) * 60);

        // Si on dépasse l'heure ou les minutes, on arrondit au jour suivant
        if (hours > 0 || minutes > 0) {
            days += 1;  // Ajout d'un jour supplémentaire si on dépasse
            hours = 0;  // On remet les heures à 0
            minutes = 0; // On remet les minutes à 0
        }
        return { days, hours, minutes };
    };

    // Calcul de la durée entre startDate et endDate
    const duration = calculateDuration(startDate, endDate);



    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Rayon de la Terre en kilomètres
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // Distance en kilomètres
        return distance || 0;
    }

    const [distanceDelyverie, setDistanceDelyverie] = useState(0);

    // Utiliser useEffect pour calculer la distance une fois que les données sont disponibles
    useEffect(() => {
        // Vérifie que toutes les coordonnées sont définies avant de calculer la distance
        if (vehicleData?.lat && vehicleData?.lng && latStart && lngStart) {
            const distance = calculateDistance(vehicleData?.lat, vehicleData?.lng, latStart, lngStart);
            setDistanceDelyverie(distance);
        }
    }, [vehicleData?.lat, vehicleData?.lng, latStart, lngStart]);

    // Correctif pour les conditions de calcul des frais de livraison
    const vehicleDeveliView = Number(
        distanceDelyverie >= 10 && distanceDelyverie < 50 ? vehicleInfo.deliveryFee10 :
            distanceDelyverie >= 50 && distanceDelyverie < 100 ? vehicleInfo.deliveryFee50 :
                distanceDelyverie >= 100 ? vehicleInfo.deliveryFee100 : 0
    );

    // Calcul du prix total en tenant compte des jours et des frais de livraison
    const calculateTotalPrice = () => {
        const { days } = calculateDuration(startDate, endDate);

        // Prix pour les jours entiers
        const totalPriceForDays = days * Number(vehicleInfo.basePricePerDay);

        // Total avant frais de livraison et remises
        const subTotal = totalPriceForDays;

        // Ajout de la commission
        const commission = (subTotal * Number(vehicleInfo.commissionPercentage || 0)) / 100;

        // Calcul des frais de livraison en fonction de la distance
        const vehicleDeliveryFee = vehicleDeveliView;

        // Calcul du prix total
        const totalPrice = subTotal + vehicleDeliveryFee + commission - vehicleInfo?.discount;
        return totalPrice;
    };

    // Total à afficher
    const totalPrice = calculateTotalPrice();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleConfirmReservation = () => {
        setShowModal(false);
        handleSubmit();

    }





    const handleSubmit = async (e) => {

        e.preventDefault();
        const { buyRate } =await  fetchExchangeRateGetCurency("EUR");
        setEuroToDinar(buyRate || getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow || 0))

        if (!customerName) {
            setErrorStripe("Veuillez entrer le sur votre carte avant de continuer.");
            return;
        }
        let reservationData = {


            code: promoCode,
            type, vehicle, property,
            basePricePerDay: Number(basePricePerDay),
            commissionPercentage: Number(commissionPercentage),
            deliveryFee: Number(deliveryFee),
            discount: Number(vehicleInfo.discount),
            firstname, lastname, email, phone, codePostal, numberTVA, startDate, endDate, specialRequests,

            userAdd,
            // more informations
            idCard,
            idCardFile,
            driverLicenseNumber,
            driverLicenseFile,
            dateOfBirth,
            pickupLocation,
            returnLocation,

            latStart,
            lngStart,
            latEnd,
            lngEnd,

            //  information sur la le vol
            companyAirport,
            numberVolAiport,

            //information  sur la carte de credit
            customerName: customerName,
            euroToDinar: euroToDinar || 0,
            creditCard: '',
        };
        if (euroToDinar === 0 || euroToDinar < 0) {
            toast.error("montant doit être un monatn valide . ", { position: "bottom-right" });
            const { buyRate } = await  fetchExchangeRateGetCurency("EUR");
            setEuroToDinar(buyRate || getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow))
            return;
        }

        
        try {
            setErrorStripe("");
            setloadingCard(true)
            // Créer une intention de paiement côté backend
            let amountFinalEuro = Number(Number(totalPrice * 0.1) / euroToDinar).toFixed(3);

            // Vérifier si le montant est inférieur à 0.5 euro
            if (amountFinalEuro < 0.5) {
                amountFinalEuro = 0.5; // Ajuster à 0.5 euro
            }

            const { data } = await axios.post(`${baseurl.url}/create-payment-intent`, {
                amount: Number(amountFinalEuro), // 10% de l'acompte
                currency,
                customerName,
                userAdd: userAdd,
                amountReal: totalPrice,
                tauxEuroToDinar: euroToDinar
            },
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            );

            const clientSecret = data?.clientSecret;

            // Confirmer le paiement avec Stripe
            const paymentResult = await stripe?.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: customerName,
                    },
                },
            });

            if (paymentResult.error) {

                setloadingCard(false);
                setSuccess(false);
                toast.error(paymentResult?.error?.message || "Paiement échoué")
                setErrorStripe(paymentResult?.error?.message);
                setTimeout(() => {
                    setErrorStripe("");
                }, 10000);

            } else {
                const responseCardId = data?.data?._id;
                reservationData.creditCard = responseCardId

                setSuccess(false);
                setErrorStripe("");
                // console.log("Paiement réussi :", paymentResult);
                setloadingCard(false)
                toast.success("Paiement réussi ", { position: "bottom-right" });
                dispatch(ReservationCreateClient(reservationData, navigate, toast));
            }
        } catch (error) {
            setloadingCard(false);
            setErrorStripe("Une erreur s'est produite lors du traitement du paiement.");
            // console.error("Erreur :", error);
            toast.error("Une erreur s'est produite lors du traitement du paiement.", { position: "bottom-right" })
            setTimeout(() => {
                setErrorStripe("");
            }, 10000);
        }
    };

    return (
        <Container className="mt-5">
            <Card>

                <Card.Header as="h5" className="bg-light">Confirmation de la réservation</Card.Header>
                <Card.Body>

                    {/* Latgitude  :{vehicleData?.lng || ""}
                    Longitude :{vehicleData?.lng || ""}

                    Latgitude R :{latStart || ""}
                    Longitude R:{lngStart || ""} */}
                    <Row>
                        <Col md={6} className="mb-3">
                            <Card className="shadow-sm rounded">

                                <Card.Body>
                                    <div>
                                        <Card>
                                            <Row>

                                                <Col md={6}>
                                                    <Card.Body>

                                                        {
                                                            vehicleData && vehicleData.images && vehicleData.images ?
                                                                <img src={
                                                                    vehicleData.images[0] ||
                                                                    emptyImage
                                                                } alt="Véhicule" style={{ height: "100px", height: "100px" }} className="img-fluid mb-3 rounded" />
                                                                :
                                                                ""
                                                        }

                                                        {
                                                            propertyData && propertyData.images && propertyData.images ?
                                                                <img src={
                                                                    propertyData.images[0] ||
                                                                    emptyImage
                                                                } alt="Véhicule" style={{ height: "100px", height: "100px" }} className="img-fluid mb-3 rounded" />
                                                                :
                                                                ""
                                                        }

                                                    </Card.Body>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="car-type"> Catégorie :   <strong>{vehicleData?.type?.name || ""} {propertyData?.type?.name || ""} </strong></div>
                                                    <hr />
                                                    <div className="car-type"> Désignation :   <strong>{vehicleData?.brand || ""} {propertyData?.name || ""} </strong></div>
                                                    <hr />
                                                    {
                                                        vehicleData && vehicleData?._id &&
                                                        (
                                                            <div className="feature-list">
                                                                <div>
                                                                    <label><MdColorize /> <span>Couleur</span></label>
                                                                    <p className="value">{vehicleData?.color || ""}</p>
                                                                </div>
                                                                <div>
                                                                    <label><MdCategory size={20} /><span>Marque</span></label>
                                                                    <p className="value">{vehicleData?.vehiclebrand || ""}</p>
                                                                </div>
                                                                <div>
                                                                    <label><MdModelTraining size={20} /> <span> Model </span></label>
                                                                    <p className="value">{vehicleData?.model || ""}</p>
                                                                </div>

                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        propertyData && propertyData?._id &&
                                                        (
                                                            <div className="">
                                                                {/*<div>
                                                                    <label><MdMergeType /> <span>Type Propriété </span></label>
                                                                    <p className="value">{propertyData?.name || ""}</p>
                                                                </div> */}
                                                                <br />
                                                                <div>
                                                                    <label><MdMap size={20} /> <span> Addresse </span></label>
                                                                    <p className="value">{propertyData?.address || ""}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                </Col>
                                            </Row>

                                        </Card>
                                    </div>

                                    <h6><MdTimer /> Période </h6>
                                    <Table bordered>
                                        <tbody>
                                            <tr>
                                                <td><strong>Date de début :</strong></td>
                                                <td>{new Date(startDate).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Date de fin :</strong></td>
                                                <td>{new Date(endDate).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Durée totale :</strong></td>
                                                <td >
                                                    <strong>{duration.days} jour{duration.days > 1 ? "s" : ""}</strong>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                    {
                                        companyAirport || numberVolAiport &&
                                        <>
                                            <h6> <MdOutlineAirplanemodeActive /> Compagnie Aérienne </h6>
                                            <Table bordered>
                                                <tbody>
                                                    {
                                                        companyAirport &&
                                                        <tr>
                                                            <td><strong>Compagnie aérienne :</strong></td>
                                                            <td >
                                                                <strong>
                                                                    {
                                                                        companyairports && companyairports.length > 0 ?
                                                                            companyairports.map((item) => {
                                                                                if (item?._id == companyAirport) {
                                                                                    return <span>{item?.name || ""}</span>
                                                                                }
                                                                            }) : ""
                                                                    }
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {
                                                        numberVolAiport &&
                                                        <tr>
                                                            <td><strong>N° de Vol :</strong></td>
                                                            <td >
                                                                <strong>{numberVolAiport}</strong>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </>
                                    }


                                    {/*Information dur le client */}

                                    <h6><MdPerson /> Informations du client</h6>
                                    <Table bordered>
                                        <tbody>
                                            <tr>
                                                <td><strong>Nom et Prénoms :</strong></td>
                                                <td>{firstname} {lastname}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Téléphone :</strong></td>
                                                <td>
                                                    {
                                                        codepostals && codepostals.length > 0 ?
                                                            codepostals.map((item) => {
                                                                if (item?._id == codePostal) {
                                                                    return <span>{item?.indicatif || ""} {phone}</span>
                                                                }
                                                            }) : ""
                                                    } </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Email :</strong></td>
                                                <td>{email}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    {/* Détails de la période de location */}







                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card className="shadow-sm rounded">

                                <Card.Body>
                                    <Card.Title>
                                        {reservationElement == "Vehicle" ? "Réservation du véhicule" : ""}
                                        {reservationElement == "Property" ? "Réservation de la propriété" : ""}
                                    </Card.Title>
                                    <Table striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td><strong>Prix journalier ( {vehicleInfo.basePricePerDay || 0} ) </strong></td>
                                                <td>{Number(vehicleInfo.basePricePerDay * duration.days) || "0"} DZD</td>
                                            </tr>
                                            <tr>
                                                <td><strong>
                                                    {reservationElement == "Vehicle" ? "Frais de livraison" : ""}
                                                    {reservationElement == "Property" ? "Frais supplémentaire" : ""}
                                                </strong></td>
                                                <td>
                                                    {reservationElement == "Vehicle" ? Number(vehicleDeveliView || 0) + "DZD" : ""}
                                                    {reservationElement == "Property" ? Number(vehicleInfo.deliveryFee || 0) + "DZD" : ""}
                                                </td>
                                            </tr>

                                            {/*<tr>
                                                <td><strong>Remise :</strong></td>
                                                <td>{vehicleInfo.discount.toFixed(1).toLocaleString() || 0} DZD</td>
                                            </tr> */}

                                            <tr>
                                                <td><strong>Montant total </strong></td>
                                                <td><strong>{totalPrice.toFixed(1).toLocaleString() || 0} DZD</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Accompte {/*({vehicleInfo.commissionPercentage}%) */} </strong></td>
                                                <td>
                                                    {(Number(totalPrice) * 0.1).toFixed(1)} DZD
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Code Promo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Entrez un code promo"
                                            value={promoCode}
                                            onChange={(e) => setPromoCode(e.target.value)}
                                        />
                                    </Form.Group>

                                    <div>
                                        <div className='flex justify-content-center' style={{ display: "flex", justifyContent: "center" }}>


                                            <button disabled={loadingReservation} type="button" className="btn btn-sm btn-primary w-100 rounded-pill" style={{ textAlign: "center", justifyContent: "center" }} onClick={handleShowModal}>

                                                {
                                                    loadingReservation ?
                                                        <>
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                            Chargement...
                                                        </> :
                                                        <span> Confirmer </span>
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <Row>


                        {

                            reservationElement == "Vehicle" &&
                            <Col md={6}>
                                {
                                    latStart && lngStart && (

                                        <div>
                                            <div className="py-3">
                                                <h6> Lieux de Départ </h6>

                                                <p> {returnLocation}</p>
                                            </div>
                                            <MapContainer center={[latStart, lngStart]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                />
                                                <Marker position={[latStart, lngStart]}>
                                                    <Popup>
                                                        Location: {latStart}, {lngStart}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )
                                }
                            </Col>
                        }

                        {

                            reservationElement == "Vehicle" &&
                            <Col md={6}>
                                {
                                    latStart && lngStart && (

                                        <div>
                                            <div className="py-3">
                                                <h6> Lieux de retour </h6>

                                                <p> {returnLocation}</p>
                                            </div>
                                            <MapContainer center={[latEnd, lngEnd]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                />
                                                <Marker position={[latEnd, lngEnd]}>
                                                    <Popup>
                                                        Location: {latEnd}, {lngEnd}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )
                                }
                            </Col>
                        }



                        {

                            reservationElement !== "Vehicle" &&
                            <Col md={12}>
                                {
                                    property && property.lat && property.lng && (

                                        <div>
                                            <div className="py-3">
                                                <h6> Lieux de la propriété </h6>
                                                <p> {property && property.address ? property.address : ""}</p>
                                            </div>
                                            <MapContainer center={[property.lat, property.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                />
                                                <Marker position={[property.lat, property.lng]}>
                                                    <Popup>
                                                        Location: {property.lat}, {property.lng}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )
                                }
                            </Col>

                        }

                    </Row>

                </Card.Body>

            </Card>

            {/* Modal de confirmation */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer la réservation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Êtes-vous sûr de vouloir confirmer la réservation pour le véhicule <strong>{vehicleData && vehicleData.brand ? vehicleData.brand : ""}  {propertyData && propertyData.name ? propertyData.name : ""}</strong> ?
                    <br />

                    {/*<strong>Montant total :</strong> {totalPrice.toLocaleString()} DZD */}



                    {/*Stripe */}

                    <div className="col-md-12 form-group border" style={{ marginBottom: "15px", border: "0 0 1 0 2px solid" }}>
                        <label htmlFor="customerName" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Nom sur la carte</label>
                        <input
                            className="form-control bg-white"
                            type="text"
                            id="customerName"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            placeholder="Entrez le nom complet sur la carte ...."
                            required
                            style={{
                                height: "40px",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ced4da",
                                width: "100%",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                            }}
                        />
                    </div>

                    <div className="col-md-12 form-group border" style={{ marginBottom: "15px", border: "0 0 1px 0 solid" }}>
                        <label htmlFor="cardNumber" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Numéro de carte</label>
                        <CardNumberElement
                            style={{
                                fontSize: "16px",
                                color: "#495057",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ced4da",
                                width: "100%",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                            }}
                        />
                    </div>

                    <div className="col-md-12 form-group border" style={{ marginBottom: "15px", border: "0 0 1px 0 solid" }}>
                        <label htmlFor="expiryDate" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Date d’expiration</label>
                        <CardExpiryElement
                            style={{

                                fontSize: "16px",
                                color: "#495057",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ced4da",
                                width: "100%",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                            }}
                        />
                    </div>

                    <div className="col-md-12 form-group border" style={{ marginBottom: "15px", border: "0 0 1px 0 solid" }}>
                        <label htmlFor="cvc" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>CVC</label>
                        <CardCvcElement
                            style={{

                                fontSize: "16px",
                                color: "#495057",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ced4da",
                                width: "100%",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                            }}
                        />
                    </div>





                    {errorStripe && <p style={{ color: 'red', marginTop: "10px" }}>{errorStripe}</p>}
                    {success && <p style={{ color: 'green', marginTop: "10px" }}>Paiement réussi ! Merci, {customerName}.</p>}




                </Modal.Body>
                <Modal.Footer>
                    <form onSubmit={handleSubmit}>
                        <Button type='button' variant="light" className="rounded-pill" onClick={handleCloseModal}>
                            Annuler
                        </Button>

                        <Button
                            type={loadingCard ? "button" : "subumit"}
                            variant="primary"
                            style={{ textAlign: "center" }}
                            disabled={!stripe}
                        >
                            {loadingCard ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Paiement en cours...
                                </>
                            ) : (
                                `Payer accompte (${Number(Number(totalPrice * 0.1) / euroToDinar).toFixed(2)} ${currency.toUpperCase()} )`
                            )}

                        </Button>
                    </form>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default InvoiceReservationWebPage;
