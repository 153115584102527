


// CUSTOMERS
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_SUCCESS_2 = "FETCH_CUSTOMERS_SUCCESS_2";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";
// CUSTOMERS LOADING
export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";



// MAINTENANCES
export const FETCH_MAINTENANCES_REQUEST = "FETCH_MAINTENANCES_REQUEST";
export const FETCH_MAINTENANCES_SUCCESS = "FETCH_MAINTENANCES_SUCCESS";
export const FETCH_MAINTENANCES_SUCCESS_2 = "FETCH_MAINTENANCES_SUCCESS_2";
export const FETCH_MAINTENANCES_FAILURE = "FETCH_MAINTENANCES_FAILURE";
// MAINTENANCES LOADING
export const FETCH_MAINTENANCE_REQUEST = "FETCH_MAINTENANCE_REQUEST";
export const FETCH_MAINTENANCE_SUCCESS = "FETCH_MAINTENANCE_SUCCESS";
export const FETCH_MAINTENANCE_FAILURE = "FETCH_MAINTENANCE_FAILURE";


// administrateurs
export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_SUCCESS_2 = "FETCH_ADMINS_SUCCESS_2";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";
// ADMINS LOADING
export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAILURE = "FETCH_ADMIN_FAILURE";



// utilisateurs
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_SUCCESS_2 = "FETCH_USERS_SUCCESS_2";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
// USERS LOADING
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";


// Agenda
export const FETCH_AGENDAS_REQUEST = "FETCH_AGENDAS_REQUEST";
export const FETCH_AGENDAS_SUCCESS = "FETCH_AGENDAS_SUCCESS";
export const FETCH_AGENDAS_SUCCESS_2 = "FETCH_AGENDAS_SUCCESS_2";
export const FETCH_AGENDAS_SUCCESS_D = "FETCH_AGENDAS_SUCCESS_D";
export const FETCH_AGENDAS_FAILURE = "FETCH_AGENDAS_FAILURE";
// AGENDAS LOADING
export const FETCH_AGENDA_REQUEST = "FETCH_AGENDA_REQUEST";
export const FETCH_AGENDA_SUCCESS = "FETCH_AGENDA_SUCCESS";
export const FETCH_AGENDA_FAILURE = "FETCH_AGENDA_FAILURE";


// Damage Type (type de domages accident etc ..)
export const FETCH_DAMAGE_TYPES_REQUEST = "FETCH_DAMAGE_TYPES_REQUEST";
export const FETCH_DAMAGE_TYPES_SUCCESS = "FETCH_DAMAGE_TYPES_SUCCESS";
export const FETCH_DAMAGE_TYPES_SUCCESS_2 = "FETCH_DAMAGE_TYPES_SUCCESS_2";
export const FETCH_DAMAGE_TYPES_SUCCESS_D = "FETCH_DAMAGE_TYPES_SUCCESS_D";
export const FETCH_DAMAGE_TYPES_FAILURE = "FETCH_DAMAGE_TYPES_FAILURE";
// DAMAGE_TYPES LOADING
export const FETCH_DAMAGE_TYPE_REQUEST = "FETCH_DAMAGE_TYPE_REQUEST";
export const FETCH_DAMAGE_TYPE_SUCCESS = "FETCH_DAMAGE_TYPE_SUCCESS";
export const FETCH_DAMAGE_TYPE_FAILURE = "FETCH_DAMAGE_TYPE_FAILURE";


// Damage
export const FETCH_DAMAGES_REQUEST = "FETCH_DAMAGES_REQUEST";
export const FETCH_DAMAGES_SUCCESS = "FETCH_DAMAGES_SUCCESS";
export const FETCH_DAMAGES_SUCCESS_2 = "FETCH_DAMAGES_SUCCESS_2";
export const FETCH_DAMAGES_SUCCESS_D = "FETCH_DAMAGES_SUCCESS_D";
export const FETCH_DAMAGES_FAILURE = "FETCH_DAMAGES_FAILURE";
// DAMAGES LOADING
export const FETCH_DAMAGE_REQUEST = "FETCH_DAMAGE_REQUEST";
export const FETCH_DAMAGE_SUCCESS = "FETCH_DAMAGE_SUCCESS";
export const FETCH_DAMAGE_FAILURE = "FETCH_DAMAGE_FAILURE";



// Insurances (pour les paiements)
export const FETCH_INSURANCES_REQUEST = "FETCH_INSURANCES_REQUEST";
export const FETCH_INSURANCES_SUCCESS = "FETCH_INSURANCES_SUCCESS";
export const FETCH_INSURANCES_SUCCESS_2 = "FETCH_INSURANCES_SUCCESS_2";
export const FETCH_INSURANCES_SUCCESS_D = "FETCH_INSURANCES_SUCCESS_D";
export const FETCH_INSURANCES_FAILURE = "FETCH_INSURANCES_FAILURE";
// INSURANCES LOADING
export const FETCH_INSURANCE_REQUEST = "FETCH_INSURANCE_REQUEST";
export const FETCH_INSURANCE_SUCCESS = "FETCH_INSURANCE_SUCCESS";
export const FETCH_INSURANCE_FAILURE = "FETCH_INSURANCE_FAILURE";


// Insurances Type (les assurances)
export const FETCH_INSURANCE_TYPES_REQUEST = "FETCH_INSURANCE_TYPES_REQUEST";
export const FETCH_INSURANCE_TYPES_SUCCESS = "FETCH_INSURANCE_TYPES_SUCCESS";
export const FETCH_INSURANCE_TYPES_SUCCESS_2 = "FETCH_INSURANCE_TYPES_SUCCESS_2";
export const FETCH_INSURANCE_TYPES_SUCCESS_D = "FETCH_INSURANCE_TYPES_SUCCESS_D";
export const FETCH_INSURANCE_TYPES_FAILURE = "FETCH_INSURANCE_TYPES_FAILURE";
// INSURANCE_TYPES LOADING
export const FETCH_INSURANCE_TYPE_REQUEST = "FETCH_INSURANCE_TYPE_REQUEST";
export const FETCH_INSURANCE_TYPE_SUCCESS = "FETCH_INSURANCE_TYPE_SUCCESS";
export const FETCH_INSURANCE_TYPE_FAILURE = "FETCH_INSURANCE_TYPE_FAILURE";




// Payment Type (espèces ou Stripe)
export const FETCH_PAYMENT_TYPES_REQUEST = "FETCH_PAYMENT_TYPES_REQUEST";
export const FETCH_PAYMENT_TYPES_SUCCESS = "FETCH_PAYMENT_TYPES_SUCCESS";
export const FETCH_PAYMENT_TYPES_SUCCESS_2 = "FETCH_PAYMENT_TYPES_SUCCESS_2";
export const FETCH_PAYMENT_TYPES_SUCCESS_D = "FETCH_PAYMENT_TYPES_SUCCESS_D";
export const FETCH_PAYMENT_TYPES_FAILURE = "FETCH_PAYMENT_TYPES_FAILURE";
// PAYMENT_TYPES LOADING
export const FETCH_PAYMENT_TYPE_REQUEST = "FETCH_PAYMENT_TYPE_REQUEST";
export const FETCH_PAYMENT_TYPE_SUCCESS = "FETCH_PAYMENT_TYPE_SUCCESS";
export const FETCH_PAYMENT_TYPE_FAILURE = "FETCH_PAYMENT_TYPE_FAILURE";



// Currency Payments
export const FETCH_CURRENCYS_REQUEST = "FETCH_CURRENCYS_REQUEST";
export const FETCH_CURRENCYS_SUCCESS = "FETCH_CURRENCYS_SUCCESS";
export const FETCH_CURRENCYS_SUCCESS_2 = "FETCH_CURRENCYS_SUCCESS_2";
export const FETCH_CURRENCYS_SUCCESS_D = "FETCH_CURRENCYS_SUCCESS_D";
export const FETCH_CURRENCYS_FAILURE = "FETCH_CURRENCYS_FAILURE";
// CURRENCYS LOADING
export const FETCH_CURRENCY_REQUEST = "FETCH_CURRENCY_REQUEST";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";
export const FETCH_CURRENCY_FAILURE = "FETCH_CURRENCY_FAILURE";



// Currency Payments
export const FETCH_MOTIFS_REQUEST = "FETCH_MOTIFS_REQUEST";
export const FETCH_MOTIFS_SUCCESS = "FETCH_MOTIFS_SUCCESS";
export const FETCH_MOTIFS_SUCCESS_2 = "FETCH_MOTIFS_SUCCESS_2";
export const FETCH_MOTIFS_SUCCESS_D = "FETCH_MOTIFS_SUCCESS_D";
export const FETCH_MOTIFS_FAILURE = "FETCH_MOTIFS_FAILURE";
// MOTIFS LOADING
export const FETCH_MOTIF_REQUEST = "FETCH_MOTIF_REQUEST";
export const FETCH_MOTIF_SUCCESS = "FETCH_MOTIF_SUCCESS";
export const FETCH_MOTIF_FAILURE = "FETCH_MOTIF_FAILURE";




// MAINTENANCE_SHEDULE
export const FETCH_MAINTENANCE_SHEDULES_REQUEST = "FETCH_MAINTENANCE_SHEDULES_REQUEST";
export const FETCH_MAINTENANCE_SHEDULES_SUCCESS = "FETCH_MAINTENANCE_SHEDULES_SUCCESS";
export const FETCH_MAINTENANCE_SHEDULES_SUCCESS_2 = "FETCH_MAINTENANCE_SHEDULES_SUCCESS_2";
export const FETCH_MAINTENANCE_SHEDULES_SUCCESS_D = "FETCH_MAINTENANCE_SHEDULES_SUCCESS_D";
export const FETCH_MAINTENANCE_SHEDULES_FAILURE = "FETCH_MAINTENANCE_SHEDULES_FAILURE";
// MAINTENANCE_SHEDULES LOADING
export const FETCH_MAINTENANCE_SHEDULE_REQUEST = "FETCH_MAINTENANCE_SHEDULE_REQUEST";
export const FETCH_MAINTENANCE_SHEDULE_SUCCESS = "FETCH_MAINTENANCE_SHEDULE_SUCCESS";
export const FETCH_MAINTENANCE_SHEDULE_FAILURE = "FETCH_MAINTENANCE_SHEDULE_FAILURE";




// MAINTENANCE_SHEDULE
export const FETCH_MAINTENANCE_CATEGORYS_REQUEST = "FETCH_MAINTENANCE_CATEGORYS_REQUEST";
export const FETCH_MAINTENANCE_CATEGORYS_SUCCESS = "FETCH_MAINTENANCE_CATEGORYS_SUCCESS";
export const FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2 = "FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2";
export const FETCH_MAINTENANCE_CATEGORYS_SUCCESS_D = "FETCH_MAINTENANCE_CATEGORYS_SUCCESS_D";
export const FETCH_MAINTENANCE_CATEGORYS_FAILURE = "FETCH_MAINTENANCE_CATEGORYS_FAILURE";
// MAINTENANCE_CATEGORYS LOADING
export const FETCH_MAINTENANCE_CATEGORY_REQUEST = "FETCH_MAINTENANCE_CATEGORY_REQUEST";
export const FETCH_MAINTENANCE_CATEGORY_SUCCESS = "FETCH_MAINTENANCE_CATEGORY_SUCCESS";
export const FETCH_MAINTENANCE_CATEGORY_FAILURE = "FETCH_MAINTENANCE_CATEGORY_FAILURE";





// MAINTENANCE_SHEDULE
export const FETCH_VEHICLE_BRANDS_REQUEST = "FETCH_VEHICLE_BRANDS_REQUEST";
export const FETCH_VEHICLE_BRANDS_SUCCESS = "FETCH_VEHICLE_BRANDS_SUCCESS";
export const FETCH_VEHICLE_BRANDS_SUCCESS_2 = "FETCH_VEHICLE_BRANDS_SUCCESS_2";
export const FETCH_VEHICLE_BRANDS_SUCCESS_D = "FETCH_VEHICLE_BRANDS_SUCCESS_D";
export const FETCH_VEHICLE_BRANDS_FAILURE = "FETCH_VEHICLE_BRANDS_FAILURE";
// VEHICLE_BRANDS LOADING
export const FETCH_VEHICLE_BRAND_REQUEST = "FETCH_VEHICLE_BRAND_REQUEST";
export const FETCH_VEHICLE_BRAND_SUCCESS = "FETCH_VEHICLE_BRAND_SUCCESS";
export const FETCH_VEHICLE_BRAND_FAILURE = "FETCH_VEHICLE_BRAND_FAILURE";



// MAINTENANCE_SHEDULE
export const FETCH_VEHICLE_MODELS_REQUEST = "FETCH_VEHICLE_MODELS_REQUEST";
export const FETCH_VEHICLE_MODELS_SUCCESS = "FETCH_VEHICLE_MODELS_SUCCESS";
export const FETCH_VEHICLE_MODELS_SUCCESS_2 = "FETCH_VEHICLE_MODELS_SUCCESS_2";
export const FETCH_VEHICLE_MODELS_SUCCESS_D = "FETCH_VEHICLE_MODELS_SUCCESS_D";
export const FETCH_VEHICLE_MODELS_FAILURE = "FETCH_VEHICLE_MODELS_FAILURE";
// VEHICLE_MODELS LOADING
export const FETCH_VEHICLE_MODEL_REQUEST = "FETCH_VEHICLE_MODEL_REQUEST";
export const FETCH_VEHICLE_MODEL_SUCCESS = "FETCH_VEHICLE_MODEL_SUCCESS";
export const FETCH_VEHICLE_MODEL_FAILURE = "FETCH_VEHICLE_MODEL_FAILURE";






// Payment
export const FETCH_PAYMENTS_REQUEST = "FETCH_PAYMENTS_REQUEST";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_SUCCESS_2 = "FETCH_PAYMENTS_SUCCESS_2";
export const FETCH_PAYMENTS_SUCCESS_D = "FETCH_PAYMENTS_SUCCESS_D";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";
// PAYMENTS LOADING
export const FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FETCH_PAYMENT_FAILURE";




// Expenses
export const FETCH_EXPENSES_REQUEST = "FETCH_EXPENSES_REQUEST";
export const FETCH_EXPENSES_SUCCESS = "FETCH_EXPENSES_SUCCESS";
export const FETCH_EXPENSES_SUCCESS_2 = "FETCH_EXPENSES_SUCCESS_2";
export const FETCH_EXPENSES_SUCCESS_D = "FETCH_EXPENSES_SUCCESS_D";
export const FETCH_EXPENSES_FAILURE = "FETCH_EXPENSES_FAILURE";
// EXPENSES LOADING
export const FETCH_EXPENSE_REQUEST = "FETCH_EXPENSE_REQUEST";
export const FETCH_EXPENSE_SUCCESS = "FETCH_EXPENSE_SUCCESS";
export const FETCH_EXPENSE_FAILURE = "FETCH_EXPENSE_FAILURE";



// Expenses Categorys
export const FETCH_EXPENSE_CATEGORYS_REQUEST = "FETCH_EXPENSE_CATEGORYS_REQUEST";
export const FETCH_EXPENSE_CATEGORYS_SUCCESS = "FETCH_EXPENSE_CATEGORYS_SUCCESS";
export const FETCH_EXPENSE_CATEGORYS_SUCCESS_2 = "FETCH_EXPENSE_CATEGORYS_SUCCESS_2";
export const FETCH_EXPENSE_CATEGORYS_SUCCESS_D = "FETCH_EXPENSE_CATEGORYS_SUCCESS_D";
export const FETCH_EXPENSE_CATEGORYS_FAILURE = "FETCH_EXPENSE_CATEGORYS_FAILURE";
// EXPENSE_CATEGORYS LOADING
export const FETCH_EXPENSE_CATEGORY_REQUEST = "FETCH_EXPENSE_CATEGORY_REQUEST";
export const FETCH_EXPENSE_CATEGORY_SUCCESS = "FETCH_EXPENSE_CATEGORY_SUCCESS";
export const FETCH_EXPENSE_CATEGORY_FAILURE = "FETCH_EXPENSE_CATEGORY_FAILURE";




// Property
export const FETCH_PROPERTYS_REQUEST = "FETCH_PROPERTYS_REQUEST";
export const FETCH_PROPERTYS_SUCCESS = "FETCH_PROPERTYS_SUCCESS";
export const FETCH_PROPERTYS_SUCCESS_2 = "FETCH_PROPERTYS_SUCCESS_2";
export const FETCH_PROPERTYS_SUCCESS_D = "FETCH_PROPERTYS_SUCCESS_D";
export const FETCH_PROPERTYS_SUCCESS_AVAILABLE = "FETCH_PROPERTYS_SUCCESS_AVAILABLE";
export const FETCH_PROPERTYS_SUCCESS_UNAVAILABLE = "FETCH_PROPERTYS_SUCCESS_UNAVAILABLE";
export const FETCH_PROPERTYS_FAILURE = "FETCH_PROPERTYS_FAILURE";
// PROPERTYS LOADING
export const FETCH_PROPERTY_REQUEST = "FETCH_PROPERTY_REQUEST";
export const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS";
export const FETCH_PROPERTY_FAILURE = "FETCH_PROPERTY_FAILURE";


// Property Type (Terrain , residence)
export const FETCH_PROPERTY_TYPES_REQUEST = "FETCH_PROPERTY_TYPES_REQUEST";
export const FETCH_PROPERTY_TYPES_SUCCESS = "FETCH_PROPERTY_TYPES_SUCCESS";
export const FETCH_PROPERTY_TYPES_SUCCESS_2 = "FETCH_PROPERTY_TYPES_SUCCESS_2";
export const FETCH_PROPERTY_TYPES_SUCCESS_D = "FETCH_PROPERTY_TYPES_SUCCESS_D";
export const FETCH_PROPERTY_TYPES_FAILURE = "FETCH_PROPERTY_TYPES_FAILURE";
// PROPERTY_TYPES LOADING
export const FETCH_PROPERTY_TYPE_REQUEST = "FETCH_PROPERTY_TYPE_REQUEST";
export const FETCH_PROPERTY_TYPE_SUCCESS = "FETCH_PROPERTY_TYPE_SUCCESS";
export const FETCH_PROPERTY_TYPE_FAILURE = "FETCH_PROPERTY_TYPE_FAILURE";





// Reservation  vehicules terrains
export const FETCH_RESERVATIONS_REQUEST = "FETCH_RESERVATIONS_REQUEST";
export const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS";
export const FETCH_RESERVATIONS_SUCCESS_2 = "FETCH_RESERVATIONS_SUCCESS_2";
export const FETCH_RESERVATIONS_SUCCESS_D = "FETCH_RESERVATIONS_SUCCESS_D";
export const FETCH_RESERVATIONS_FAILURE = "FETCH_RESERVATIONS_FAILURE";
// RESERVATIONS LOADING
export const FETCH_RESERVATION_REQUEST = "FETCH_RESERVATION_REQUEST";
export const FETCH_RESERVATION_SUCCESS = "FETCH_RESERVATION_SUCCESS";
export const FETCH_RESERVATION_FAILURE = "FETCH_RESERVATION_FAILURE";



// Reservation type  Journalier , Mensuel
export const FETCH_RESERVATION_TYPES_REQUEST = "FETCH_RESERVATION_TYPES_REQUEST";
export const FETCH_RESERVATION_TYPES_SUCCESS = "FETCH_RESERVATION_TYPES_SUCCESS";
export const FETCH_RESERVATION_TYPES_SUCCESS_2 = "FETCH_RESERVATION_TYPES_SUCCESS_2";
export const FETCH_RESERVATION_TYPES_SUCCESS_D = "FETCH_RESERVATION_TYPES_SUCCESS_D";
export const FETCH_RESERVATION_TYPES_FAILURE = "FETCH_RESERVATION_TYPES_FAILURE";
// RESERVATION_TYPES LOADING
export const FETCH_RESERVATION_TYPE_REQUEST = "FETCH_RESERVATION_TYPE_REQUEST";
export const FETCH_RESERVATION_TYPE_SUCCESS = "FETCH_RESERVATION_TYPE_SUCCESS";
export const FETCH_RESERVATION_TYPE_FAILURE = "FETCH_RESERVATION_TYPE_FAILURE";




// Trajet 
export const FETCH_TRIPS_REQUEST = "FETCH_TRIPS_REQUEST";
export const FETCH_TRIPS_SUCCESS = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_SUCCESS_2 = "FETCH_TRIPS_SUCCESS_2";
export const FETCH_TRIPS_SUCCESS_D = "FETCH_TRIPS_SUCCESS_D";
export const FETCH_TRIPS_FAILURE = "FETCH_TRIPS_FAILURE";
// TRIPS LOADING
export const FETCH_TRIP_REQUEST = "FETCH_TRIP_REQUEST";
export const FETCH_TRIP_SUCCESS = "FETCH_TRIP_SUCCESS";
export const FETCH_TRIP_FAILURE = "FETCH_TRIP_FAILURE";




// Vehicule
export const FETCH_VEHICLES_REQUEST = "FETCH_VEHICLES_REQUEST";
export const FETCH_VEHICLES_SUCCESS = "FETCH_VEHICLES_SUCCESS";
export const FETCH_VEHICLES_SUCCESS_2 = "FETCH_VEHICLES_SUCCESS_2";
export const FETCH_VEHICLES_SUCCESS_D = "FETCH_VEHICLES_SUCCESS_D";
export const FETCH_VEHICLES_SUCCESS_AVAILABLE = "FETCH_VEHICLES_SUCCESS_AVAILABLE";
export const FETCH_VEHICLES_SUCCESS_UNAVAILABLE = "FETCH_VEHICLES_SUCCESS_UNAVAILABLE";
export const FETCH_VEHICLES_FAILURE = "FETCH_VEHICLES_FAILURE";
// VEHICLES LOADING
export const FETCH_VEHICLE_REQUEST = "FETCH_VEHICLE_REQUEST";
export const FETCH_VEHICLE_SUCCESS = "FETCH_VEHICLE_SUCCESS";
export const FETCH_VEHICLE_FAILURE = "FETCH_VEHICLE_FAILURE";




// Vehicule type 
export const FETCH_VEHICLE_TYPES_REQUEST = "FETCH_VEHICLE_TYPES_REQUEST";
export const FETCH_VEHICLE_TYPES_SUCCESS = "FETCH_VEHICLE_TYPES_SUCCESS";
export const FETCH_VEHICLE_TYPES_SUCCESS_2 = "FETCH_VEHICLE_TYPES_SUCCESS_2";
export const FETCH_VEHICLE_TYPES_SUCCESS_D = "FETCH_VEHICLE_TYPES_SUCCESS_D";
export const FETCH_VEHICLE_TYPES_FAILURE = "FETCH_VEHICLE_TYPES_FAILURE";
// VEHICLE_TYPES LOADING
export const FETCH_VEHICLE_TYPE_REQUEST = "FETCH_VEHICLE_TYPE_REQUEST";
export const FETCH_VEHICLE_TYPE_SUCCESS = "FETCH_VEHICLE_TYPE_SUCCESS";
export const FETCH_VEHICLE_TYPE_FAILURE = "FETCH_VEHICLE_TYPE_FAILURE";




// notification 
export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_SUCCESS_2 = "FETCH_NOTIFICATIONS_SUCCESS_2";
export const FETCH_NOTIFICATIONS_SUCCESS_D = "FETCH_NOTIFICATIONS_SUCCESS_D";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";
// NOTIFICATIONS LOADING
export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";



// Parkings 
export const FETCH_PARKINGS_REQUEST = "FETCH_PARKINGS_REQUEST";
export const FETCH_PARKINGS_SUCCESS = "FETCH_PARKINGS_SUCCESS";
export const FETCH_PARKINGS_SUCCESS_2 = "FETCH_PARKINGS_SUCCESS_2";
export const FETCH_PARKINGS_SUCCESS_D = "FETCH_PARKINGS_SUCCESS_D";
export const FETCH_PARKINGS_FAILURE = "FETCH_PARKINGS_FAILURE";
// PARKINGS LOADING
export const FETCH_PARKING_REQUEST = "FETCH_PARKING_REQUEST";
export const FETCH_PARKING_SUCCESS = "FETCH_PARKING_SUCCESS";
export const FETCH_PARKING_FAILURE = "FETCH_PARKING_FAILURE";





// assesorys 
export const FETCH_ASSESSORYS_REQUEST = "FETCH_ASSESSORYS_REQUEST";
export const FETCH_ASSESSORYS_SUCCESS = "FETCH_ASSESSORYS_SUCCESS";
export const FETCH_ASSESSORYS_SUCCESS_2 = "FETCH_ASSESSORYS_SUCCESS_2";
export const FETCH_ASSESSORYS_SUCCESS_D = "FETCH_ASSESSORYS_SUCCESS_D";
export const FETCH_ASSESSORYS_FAILURE = "FETCH_ASSESSORYS_FAILURE";
// ASSESSORYS LOADING
export const FETCH_ASSESSORY_REQUEST = "FETCH_ASSESSORY_REQUEST";
export const FETCH_ASSESSORY_SUCCESS = "FETCH_ASSESSORY_SUCCESS";
export const FETCH_ASSESSORY_FAILURE = "FETCH_ASSESSORY_FAILURE";



// code postals 
export const FETCH_CODE_POSTALS_REQUEST = "FETCH_CODE_POSTALS_REQUEST";
export const FETCH_CODE_POSTALS_SUCCESS = "FETCH_CODE_POSTALS_SUCCESS";
export const FETCH_CODE_POSTALS_SUCCESS_2 = "FETCH_CODE_POSTALS_SUCCESS_2";
export const FETCH_CODE_POSTALS_SUCCESS_D = "FETCH_CODE_POSTALS_SUCCESS_D";
export const FETCH_CODE_POSTALS_FAILURE = "FETCH_CODE_POSTALS_FAILURE";
// CODE_POSTALS LOADING
export const FETCH_CODE_POSTAL_REQUEST = "FETCH_CODE_POSTAL_REQUEST";
export const FETCH_CODE_POSTAL_SUCCESS = "FETCH_CODE_POSTAL_SUCCESS";
export const FETCH_CODE_POSTAL_FAILURE = "FETCH_CODE_POSTAL_FAILURE";



// code Airports 
export const FETCH_COMPANY_AIRPORTS_REQUEST = "FETCH_COMPANY_AIRPORTS_REQUEST";
export const FETCH_COMPANY_AIRPORTS_SUCCESS = "FETCH_COMPANY_AIRPORTS_SUCCESS";
export const FETCH_COMPANY_AIRPORTS_SUCCESS_2 = "FETCH_COMPANY_AIRPORTS_SUCCESS_2";
export const FETCH_COMPANY_AIRPORTS_SUCCESS_D = "FETCH_COMPANY_AIRPORTS_SUCCESS_D";
export const FETCH_COMPANY_AIRPORTS_FAILURE = "FETCH_COMPANY_AIRPORTS_FAILURE";
// COMPANY_AIRPORTS LOADING
export const FETCH_COMPANY_AIRPORT_REQUEST = "FETCH_COMPANY_AIRPORT_REQUEST";
export const FETCH_COMPANY_AIRPORT_SUCCESS = "FETCH_COMPANY_AIRPORT_SUCCESS";
export const FETCH_COMPANY_AIRPORT_FAILURE = "FETCH_COMPANY_AIRPORT_FAILURE";



// code postals 
export const FETCH_CODE_PROMOS_REQUEST = "FETCH_CODE_PROMOS_REQUEST";
export const FETCH_CODE_PROMOS_SUCCESS = "FETCH_CODE_PROMOS_SUCCESS";
export const FETCH_CODE_PROMOS_SUCCESS_2 = "FETCH_CODE_PROMOS_SUCCESS_2";
export const FETCH_CODE_PROMOS_SUCCESS_D = "FETCH_CODE_PROMOS_SUCCESS_D";
export const FETCH_CODE_PROMOS_FAILURE = "FETCH_CODE_PROMOS_FAILURE";
// CODE_PROMOS LOADING
export const FETCH_CODE_PROMO_REQUEST = "FETCH_CODE_PROMO_REQUEST";
export const FETCH_CODE_PROMO_SUCCESS = "FETCH_CODE_PROMO_SUCCESS";
export const FETCH_CODE_PROMO_FAILURE = "FETCH_CODE_PROMO_FAILURE";




// companys
export const FETCH_COMPANYS_REQUEST = "FETCH_COMPANYS_REQUEST";
export const FETCH_COMPANYS_SUCCESS = "FETCH_COMPANYS_SUCCESS";
export const FETCH_COMPANYS_SUCCESS_2 = "FETCH_COMPANYS_SUCCESS_2";
export const FETCH_COMPANYS_SUCCESS_D = "FETCH_COMPANYS_SUCCESS_D";
export const FETCH_COMPANYS_FAILURE = "FETCH_COMPANYS_FAILURE";
// COMPANYS LOADING
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";






// Messages sms
export const FETCH_MESSAGES_REQUEST = "FETCH_MESSAGES_REQUEST";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_SUCCESS_2 = "FETCH_MESSAGES_SUCCESS_2";
export const FETCH_MESSAGES_SUCCESS_D = "FETCH_MESSAGES_SUCCESS_D";
export const FETCH_MESSAGES_FAILURE = "FETCH_MESSAGES_FAILURE";
// MESSAGES LOADING
export const FETCH_MESSAGE_REQUEST = "FETCH_MESSAGE_REQUEST";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const FETCH_MESSAGE_FAILURE = "FETCH_MESSAGE_FAILURE";


// Chargement des boutiques
// conversations
export const FETCH_CONVERSATIONS_SUCCESS = "FETCH_CONVERSATIONS_SUCCESS";
export const FETCH_CONVERSATIONS_SUCCESS_2 = "FETCH_CONVERSATIONS_SUCCESS_2";
export const FETCH_CONVERSATIONS_SUCCESS_FILTER = "FETCH_CONVERSATIONS_SUCCESS_FILTER";
export const FETCH_CONVERSATIONS_SUCCESS_FILTER_2 = "FETCH_CONVERSATIONS_SUCCESS_FILTER_2";
// participants
export const FETCH_PARTICIPANTS_SUCCESS = "FETCH_PARTICIPANTS_SUCCESS";
export const FETCH_PARTICIPANTS_SUCCESS_2 = "FETCH_PARTICIPANTS_SUCCESS_2";
export const FETCH_PARTICIPANTS_SUCCESS_FILTER = "FETCH_PARTICIPANTS_SUCCESS_FILTER";
export const FETCH_PARTICIPANTS_SUCCESS_FILTER_2 = "FETCH_PARTICIPANTS_SUCCESS_FILTER_2";

export const FETCH_CONVERSATIONS_SEND = "FETCH_CONVERSATIONS_SEND";
export const FETCH_CONVERSATIONS_FAILURE = "FETCH_CONVERSATIONS_FAILURE"
//      chargement
export const SEND_REQUEST_CONVERSATION = "SEND_REQUEST_CONVERSATION";
export const REQUEST_SUCCESS_CONVERSATION = "REQUEST_SUCCESS_CONVERSATION";
export const REQUEST_FAILURE_CONVERSATION = "REQUEST_FAILURE_CONVERSATION";
// select
export const SELECT_USER_CONVERSATION = "SELECT_USER_CONVERSATION";















// TRADUCTION DE LA LANGUE ;
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  payload: language,
});