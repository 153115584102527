import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Badge, Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { MdOutlineSearch, MdOutlineSupport, MdSubscriptions } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { fetchInssuranceTypesAll } from '../../../actions/request/InssuranceTypeRequest';
import { InsuranceCreate, InsuranceUpdateById, fetchInsurancesAll, fetchInsurancesAllByPropertyId, fetchInsurancesAllByUserId, fetchInsurancesAllByVehicleId } from '../../../actions/request/InssuranceRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import moment from 'moment';
import { ROUTES } from '../../../config/routingUrl';
import { useNavigate } from 'react-router-dom';
import { RiEye2Fill } from 'react-icons/ri';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { fetchDamagesAll, fetchDamagesAllByUserId } from '../../../actions/request/DamageRequest';
import { fetchPaymentsAll } from '../../../actions/request/PaymentRequest';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from '../../../actions/request/ReservationRequest';

export const getExpirationColor = (endDate) => {

    const today = moment();
    const expirationDate = moment(endDate);
    const daysDiff = expirationDate.diff(today, 'days');

    if (daysDiff <= 0) {
        return 'text-danger'; // Rouge : Date expirée
    } else if (daysDiff <= 5) {
        return 'text-warning'; // Vert : Moins de 20 jours
    } else {
        return 'text-primary'; // Vert clair : Plus de 20 jours
    }
};

const InsuranceManagementPage = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const insurancesData = useSelector((state) => state.insurances.insurances);

    const insuranceTypes = useSelector((state) => state.insuranceTypes.insuranceTypes);

    useEffect(() => {
        // dispatch(fetchInssuranceTypesAll());
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchPropertysFilters("", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllFilter("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchDamagesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchInsurancesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAllByidUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchDamagesAll());
            dispatch(fetchInsurancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAll());
        }
    }, [dispatch]);


    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();


    //  filtre des option
    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : []

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : []


    const handleSearchFilter = () => {
        if (vehicleSelect) {
            dispatch(fetchInsurancesAllByVehicleId(vehicleSelect?.value));
        } else if (propertySelect) {
            dispatch(fetchInsurancesAllByPropertyId(propertySelect?.value));
        }
        else {
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
                dispatch(fetchInsurancesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            }
            else {
                dispatch(fetchInsurancesAll());
            }
        }
    }



    

    const renderStatusBadge = (status) => {

        switch (status) {
            case 'Completed':
                return <Badge bg="success">Payé</Badge>;
            case 'Failed' || "Overdue":
                return <Badge bg="danger">Non Payé</Badge>;
            case 'Pending':
                return <Badge bg="warning">En attente</Badge>;
        }
    };
    




    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"><h3 className="mb-4"><MdOutlineSupport />  Assurances</h3></h3>
                        </div>
                        <div>
                            {/*<Button variant="primary" size='sm' className="ml-2" onClick={() => navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`)}>+ Ajouter</Button> */}
                        </div>
                    </div>
                </Card.Header>


                <Card.Body style={{ minHeight: "500px" }}>


                    <ul className="list-group">
                        <li className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                            <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                <Form>
                                    <Row>
                                        {
                                            vehicleOptions && vehicleOptions.length > 0 && (
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Véhicule</Form.Label>
                                                        <Select
                                                            value={vehicleSelect}
                                                            onChange={(selectedOption) => setvehicleSelect(selectedOption)}
                                                            options={vehicleOptions}
                                                            isClearable
                                                            isSearchable
                                                            placeholder="Choix"
                                                            isDisabled={!!propertySelect}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                        {
                                            propertyOptions && propertyOptions.length > 0 && (
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Propriété</Form.Label>
                                                        <Select
                                                            value={propertySelect}
                                                            isClearable
                                                            isSearchable
                                                            onChange={(selectedOption) => setpropertySelect(selectedOption)}
                                                            options={propertyOptions}
                                                            placeholder="Choix"
                                                            isDisabled={!!vehicleSelect}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )}
                                    </Row>
                                </Form>

                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>

                                <div className="m-1">
                                    <label>{""}</label>
                                    <div>
                                        <Button variant='primary' size='sm' onClick={handleSearchFilter} >
                                            <MdOutlineSearch />
                                        </Button>
                                    </div>

                                </div>

                            </div>
                        </li>
                    </ul>


                    {
                        insurancesData && insurancesData.length === 0 ? (
                            <p className="text-center">Aucune assurance .</p>
                        ) : (
                            <Table striped bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Assurance</th>
                                        <th>Patrimoine</th>
                                        <th>Date d{"'"}Expiration</th>
                                        <th>Numéro Police Assurance</th>
                                        <th>Coût</th>
                                        <th>Statut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {insurancesData && insurancesData.length > 0 && insurancesData.map(insurance => (
                                        <tr key={insurance.id}>
                                            <td >
                                                {
                                                    insurance && insurance.property && insurance.property.images && insurance.property.images && insurance.property.images.length > 0 &&
                                                    <img src={insurance.property.images[0]} style={{ height: "30px", width: "30px" }} />
                                                }
                                                {
                                                    insurance && insurance.vehicle && insurance.vehicle.images && insurance.vehicle.images && insurance.vehicle.images.length > 0 &&
                                                    <img src={insurance.vehicle.images[0]} style={{ height: "30px", width: "30px" }} />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    insurance && insurance.type && insurance.type.name ?
                                                        insurance.type.name : ""
                                                }
                                            </td>
                                            <td >
                                                {
                                                    insurance.property?.name || insurance.vehicle?.brand
                                                }
                                            </td>
                                            <td >
                                                <Badge bg='transparent' className={getExpirationColor(insurance.endDate)}>
                                                    {moment(insurance.endDate).format("DD-MM-YYYY")}
                                                </Badge>
                                            </td>
                                            <td>{insurance.policyNumber}</td>
                                            <td>{insurance.premiumAmount}</td>
                                            <td>{renderStatusBadge(insurance.paymentStatus)}</td>

                                            <td>
                                                <Button variant="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL}/${insurance._id}`) }}>
                                                    <RiEye2Fill /> <span className="text-sm"> Detail </span>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )

                    }


                </Card.Body>
            </Card>


        </div>
    );
};

export default InsuranceManagementPage;
