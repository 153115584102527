import React from 'react';
import HomeVehicleContainerForm from './HomeVehicleContainerForm';
import HomePropertyContainerForm from './HomePropertyContainerForm';
import { Col, Row } from 'react-bootstrap';

const HomeBarner = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      {/* Image de fond */}
      <div
        style={{
          backgroundImage: "url('bg-barnerhomesreservation.jpg')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "fill",
          width: "100%",
          minHeight: "100vh",
        }}
      />

      {/* Overlay sombre */}
      <div
        style={{
            width: "100%",
            minHeight: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />

      {/* Contenu au-dessus de l'overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Row style={{ width: "90%"  }}>
          <Col md={6}>
            <HomeVehicleContainerForm />
          </Col>
          <Col md={6}>
            <HomePropertyContainerForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeBarner;
