import * as XLSX from 'xlsx'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { logoApplication } from '../dataApi/dataFormApi'

// Fonction pour exporter en Excel
export const exportToExcelDataReservation = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(
        data.map(entry => ({
            'N°': entry.ordre, // "brand" pour véhicule, "name" pour propriété
            "Designation": entry.vehicle?.brand || entry?.property?.name, // "brand" pour véhicule, "name" pour propriété
            'Model / Adresse': entry.vehicle?.model || entry?.property?.address, // "model" pour véhicule, "address" pour propriété
            "Client": entry.firstname + ' ' + entry.lastname, // "model" pour véhicule, "address" pour propriété
            "Indicatif": entry.codePostal?.indicatif, // "model" pour véhicule, "address" pour propriété
            "Téléphone": entry?.phone, // "model" pour véhicule, "address" pour propriété
            "Début": moment(entry?.startDate).format("DD-MM-YYYY HH:MM"),
            "Fin": moment(entry?.endDate).format("DD-MM-YYYY HH:MM"),
            "Statut": entry?.status == "PENDING" ? "En cours" : entry?.status == "CONFIRMED" ? "Confirmée" : entry?.status == "COMPLETED" ? "Terminée" : entry?.status == "CANCELLED" ? "Annulée" : "",
            'Prix total (DZD)': Number(entry?.totalPrice).toFixed(2) || '0'
            // Si c'est un véhicule, ajouter le champ marque
        }))
    )

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Report')
    // Exporter avec le nom du fichier donné
    XLSX.writeFile(wb, `${fileName}.xlsx`)
}

// Fonction pour exporter en PDF
export const exportToPDFDataReservation = (data, fileName) => {
    const doc = new jsPDF({
        orientation: 'landscape', // ou simplement 'l'
        unit: 'pt',               // unité (points, mm, etc.)
        format: 'a4'              // format du papier
    });

    // Ajoute le titre
    // doc.addImage(logoApplication)
    // doc.textWithLink(`https://rezaplus.com`, 5, 5)
    doc.text(`Listes des réservations`, 20, 20)

    // Données de la table
    const tableData = data.map(entry => [
        entry.ordre, // "brand" pour véhicule, "name" pour propriété
        entry.vehicle?.brand || entry?.property?.name, // "brand" pour véhicule, "name" pour propriété
        entry.vehicle?.model || entry?.property?.address, // "model" pour véhicule, "address" pour propriété
        entry.firstname + ' ' + entry.lastname, // "model" pour véhicule, "address" pour propriété
        entry.email, // "model" pour véhicule, "address" pour propriété
        "+" + entry.codePostal?.indicatif, // "model" pour véhicule, "address" pour propriété
        entry?.phone, // "model" pour véhicule, "address" pour propriété
        moment(entry?.startDate).format("DD-MM-YYYY à HH:MM"),// Date début de la réservation
        moment(entry?.endDate).format("DD-MM-YYYY à HH:MM"), // Date de fin de la réservation
        entry?.status == "PENDING" ? "En cours" : entry?.status == "CONFIRMED" ? "Confirmée" : entry?.status == "COMPLETED" ? "Terminée" : entry?.status == "CANCELLED" ? "Annulée" : "", // Statut de la réservation
        Number(entry?.totalPrice).toFixed(1) || '0' // Priw totales de la réservation
    ])

    // Options de table
    doc.autoTable({
        head: [
            [
                'N°', // "brand" pour véhicule, "name" pour propriété
                "Designation", // "brand" pour véhicule, "name" pour propriété
                'Model / Adresse', // "model" pour véhicule, "address" pour propriété
                "Client", // "model" pour véhicule, "address" pour propriété
                "Email", // "model" pour véhicule, "address" pour propriété
                "Indicatif", // "model" pour véhicule, "address" pour propriété
                "Téléphone", // "model" pour véhicule, "address" pour propriété
                "Début",// Debut de la reservaiton
                "Fin",// date de fin de la r"servation
                "Statut",// Statut de la réservation
                'Prix (DZD)'// Prix totale de la réservation
            ]
        ],
        body: tableData,
        startY: 60,      // position de départ vertical
        theme: 'grid',   // style du tableau
        margin: { left: 10, right: 10, }, // marges pour ne pas couper le tableau
    })

    // Sauvegarde du fichier PDF avec le nom du fichier donné
    doc.save(`${fileName}.pdf`)
}
