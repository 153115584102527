import { FETCH_PROPERTYS_FAILURE, FETCH_PROPERTYS_REQUEST, FETCH_PROPERTYS_SUCCESS, FETCH_PROPERTYS_SUCCESS_2, FETCH_PROPERTYS_SUCCESS_AVAILABLE, FETCH_PROPERTYS_SUCCESS_UNAVAILABLE, FETCH_PROPERTY_FAILURE, FETCH_PROPERTY_REQUEST, FETCH_PROPERTY_SUCCESS } from "../actions/actions";



// propertyReducer.js
const initialState = {
    propertys: [],
    propertys2: [],
    propertysAvailable: [],
    propertysUnAvailable: [],
    property: {},
    propertySelect: {},
    loadingProperty:false,
    loadingPropertySelect:false,
    loadingPropertys:false,
    errorPropertys:null,
    errorProperty:null
    // ... autres états spécifiques à propertyReducer
  };
  
  const propertyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROPERTY_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingProperty:true,
          errorPropertys:""
          // Autres manipulations d'état pour la demande de récupération des propertys
        };
        case FETCH_PROPERTY_SUCCESS:
        return {
          ...state,
          loadingProperty:false,
          errorPropertys:"",
          property:action.payload
        };
        case FETCH_PROPERTY_FAILURE:
        return {
          ...state,
          loadingProperty:false,
          errorPropertys:action.payload
        };


      case FETCH_PROPERTYS_REQUEST:
        return {
          ...state,
          loadingPropertys:true,
          errorPropertys:""
        };
       
      case FETCH_PROPERTYS_SUCCESS:
        return {
          ...state,
          propertys: action.payload,
          loadingPropertys:false,
          errorPropertys:""
          // Autres manipulations d'état pour le succès de la récupération des propertys
        };
        case FETCH_PROPERTYS_SUCCESS_2:
        return {
          ...state,
          propertys2: action.payload,
          loadingPropertys:false,
          errorPropertys:""
          // Autres manipulations d'état pour le succès de la récupération des propertys
        };

        case FETCH_PROPERTYS_SUCCESS_AVAILABLE:
        return {
          ...state,
          propertysAvailable: action.payload,
          loadingPropertys:false,
          errorPropertys:""
          // Autres manipulations d'état pour le succès de la récupération des propertys
        };

        case FETCH_PROPERTYS_SUCCESS_UNAVAILABLE:
        return {
          ...state,
          propertysUnAvailable: action.payload,
          loadingPropertys:false,
          errorPropertys:""
          // Autres manipulations d'état pour le succès de la récupération des propertys
        };
        
      case FETCH_PROPERTYS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingPropertys:false,
          errorPropertys:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des propertys
        };
      // ... autres cas pour d'autres actions liées aux propertys

        
      default:
        return state;
    }
  };
  
  export default propertyReducer;

  

