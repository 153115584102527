import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserById } from '../../actions/request/UserRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { emptyImage, logoApplication, profilePictureDefault } from '../../utils/dataApi/dataFormApi';
import Select from "react-select";
import moment from 'moment';
import { MdCarRental, MdCardTravel, MdHomeFilled, MdMail, MdPerson } from 'react-icons/md';
import { fetchPropertysAllByOwnerId, fetchPropertysAllResearch } from '../../actions/request/PropertyRequest';
import { Button, Col, Container, Form, Pagination, Row, Card, Badge } from 'react-bootstrap';
import { ROUTES } from '../../config/routingUrl';
import { fetchVehiclesAllByOwnerIdAvailable, fetchVehiclesAllResearch } from '../../actions/request/VehicleRequest';
import { FaSearch } from 'react-icons/fa';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../app/actions/actions';

const CarUserPage = () => {
    const paramUser = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);

    const [availableStartDate, setAvailableStartDate] = useState('');
    const [availableStartTime, setAvailableStartTime] = useState({ value: '00:00', label: '00:00' });
    const [availableEndDate, setAvailableEndDate] = useState('');
    const [availableEndTime, setAvailableEndTime] = useState({ value: '00:00', label: '00:00' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    const [filterType, setFilterType] = useState("vehicle");
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);

    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);

    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, '0');
        return [
            { value: `${hour}:00`, label: `${hour}:00` },
            { value: `${hour}:30`, label: `${hour}:30` }
        ];
    }).flat();

    useEffect(() => {
        dispatch(fetchUserById(paramUser?.id));
        dispatch(fetchVehiclesAllByOwnerIdAvailable(paramUser?.id));
        dispatch(fetchPropertysAllByOwnerId(paramUser?.id));
    }, [dispatch]);

    useEffect(() => {
        // Mettre à jour le maxPrice après le chargement des véhicules
        if (vehicles.length > 0) {
            setMaxPrice(Math.max(...vehicles.map(v => v.basePricePerDay), 0));
        }
    }, [vehicles]);

    useEffect(() => {
        // Filtrage basé sur le prix
        const vehiclesFiltered = vehicles.sort((a, b) => b.basePricePerDay - a.basePricePerDay).filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;
        });

        const propertiesFiltered = propertys.sort((a, b) => b.basePricePerDay - a.basePricePerDay).filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });

        setFilteredVehicles(vehiclesFiltered);
        setFilteredProperties(propertiesFiltered);
    }, [vehicles, propertys, minPrice, maxPrice]);



    const currentItems = filterType === "vehicle"
        ? filteredVehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : filteredProperties.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const combineDateAndTime = (date, time) => {
        return moment(`${date}T${time.value}:00.000+00:00`).toISOString();
    };

    const handleSearch = () => {
        setCurrentPage(1);


        // Combiner les dates et heures
        const startDateTime = combineDateAndTime(availableStartDate, availableStartTime);
        const endDateTime = combineDateAndTime(availableEndDate, availableEndTime);

        // Envoyer les dates combinées avec la requête de recherche
        dispatch(fetchPropertysAllResearch(startDateTime, endDateTime, paramUser?.id));
        dispatch(fetchVehiclesAllResearch(startDateTime, endDateTime, paramUser?.id));

        const filteredVehicles = vehicles.filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;

        });

        const filteredProperties = propertys.filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });


        // Mettre à jour les véhicules et propriétés filtrés dans l'état local
        setFilteredVehicles(filteredVehicles);
        setFilteredProperties(filteredProperties);
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className="container-fluid">
            <div className=" mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card p-3 mb-5 bg-white rounded">
                            <div className="card-header text-center bg-white">
                                <img
                                    src={user?.profilePicture || logoApplication}
                                    alt="Profile"
                                    className="rounded-circle img-fluid"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                                <div>
                                    <h4 className="mt-3">
                                        {user && user.companyName ? (
                                            <p className="mb-1 text-truc">{user.companyName}</p>
                                        ) : (
                                            <p className="mb-1">{user.firstname || ""} {user.lastname || ""}</p>
                                        )}
                                    </h4>
                                </div>

                            </div>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    {
                                        user.email && (
                                            <li className="list-group-item">
                                                <strong><MdMail /> Email: </strong>{user.email || ""}
                                            </li>
                                        )
                                    }
                                    {
                                        user.createdAt && (
                                            <li className="list-group-item">
                                                <strong>Date d{"'"}inscrit: </strong>
                                                {user && user.createdAt ? moment(user.createdAt).format("DD-MM-YYYY") : ""}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Container fluid className="mt-5 mb-5">
                <Row>
                    <Col lg={3}>
                        <Form className="mb-4">
                            <h5>Filtres</h5>
                            <Form.Group>
                                <Form.Label>Type de filtre</Form.Label>
                                <div>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Véhicules"
                                        checked={filterType === "vehicle"}
                                        onChange={() => setFilterType("vehicle")}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Propriétés"
                                        checked={filterType === "property"}
                                        onChange={() => setFilterType("property")}
                                    />
                                </div>
                            </Form.Group>

                            {/* Filtres de prix */}
                            <Form.Group className="mb-3">
                                <Form.Label>Prix Min</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="number"
                                    value={minPrice}
                                    onChange={(e) => {
                                        const value = Number.parseInt(e.target.value);
                                        if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                            setMinPrice(value);
                                        }
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Prix Max</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="number"
                                    value={maxPrice}
                                    onChange={(e) => {
                                        const value = Number.parseInt(e.target.value);
                                        if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                            setMaxPrice(value);
                                        }
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Date de début</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    min={today}
                                    value={availableStartDate}
                                    onChange={(e) => setAvailableStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Heure de début</Form.Label>
                                <Select
                                    value={availableStartTime}
                                    onChange={(selectedOption) => setAvailableStartTime(selectedOption)}
                                    options={hourOptions}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Date de fin</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    min={availableStartDate || today}
                                    value={availableEndDate}
                                    onChange={(e) => setAvailableEndDate(e.target.value)}
                                    disabled={!availableStartDate}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Heure de fin</Form.Label>
                                <Select
                                    value={availableEndTime}
                                    onChange={(selectedOption) => setAvailableEndTime(selectedOption)}
                                    options={hourOptions}
                                    isDisabled={!availableStartDate}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nombre d{"'"}éléments par page</Form.Label>
                                <Form.Control as="select" value={itemsPerPage} onChange={(e) => setItemsPerPage(parseInt(e.target.value))}>
                                    <option value={10}>10</option>
                                    <option value={30}>30</option>
                                </Form.Control>
                            </Form.Group>
                            <Button
                                variant="primary"
                                className="mt-3 w-100 justify-content-center"
                                onClick={handleSearch}
                            >
                                <div> <FaSearch /> <span>Rechercher</span> </div>
                            </Button>
                        </Form>
                    </Col>

                    <Col lg={9}>
                        <Row>
                            {currentItems.length > 0 ? (
                                currentItems.map((item) => (
                                    <Col md={4} key={item._id} className="mb-4">
                                        <Card className="h-100 shadow-sm">
                                            {item && item.images && item.images.length > 0 ? (
                                                <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={item.images[0]} alt={item.name || item.brand} />
                                            ) : (
                                                <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={emptyImage} alt={item.name || item.brand} />
                                            )}
                                            <Card.Body>
                                                <Card.Title className="text-start">
                                                    <h6 className="line-clamp-2 text-start text-truncate">{filterType === "vehicle" ? `${item.brand} ${item.model}` : item.name}</h6>

                                                </Card.Title>
                                                <Card.Text className="text-truncate">
                                                    {filterType === "vehicle" ? (
                                                        <>
                                                            <strong>Couleur : </strong>{item.color}<br />
                                                            <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                        </>
                                                    ) : (
                                                        <div className="text-truncate">
                                                            <strong>Adresse : </strong><span className="text-truncate">{item.address}</span><br />
                                                            <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                        </div>
                                                    )}
                                                </Card.Text>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="btn w-100 justify-content-center"
                                                    onClick={() => {
                                                        if (filterType === "vehicle") {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                            navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                        } else {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                            navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                        }
                                                    }}
                                                >
                                                    <div>{filterType === "vehicle" ? "Réserver" : "Réserver"}</div>
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <p className="text-center">Aucun résultat ne correspond aux critères de recherche.</p>
                            )}
                        </Row>

                        {/* Pagination */}
                        <Pagination className="justify-content-center">
                            {Array.from({ length: Math.ceil((filterType === "vehicle" ? filteredVehicles.length : filteredProperties.length) / itemsPerPage) }, (_, i) => (
                                <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CarUserPage;