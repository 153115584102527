import { FETCH_VEHICLES_FAILURE, FETCH_VEHICLES_REQUEST, FETCH_VEHICLES_SUCCESS, FETCH_VEHICLES_SUCCESS_2, FETCH_VEHICLES_SUCCESS_AVAILABLE, FETCH_VEHICLES_SUCCESS_UNAVAILABLE, FETCH_VEHICLE_FAILURE, FETCH_VEHICLE_REQUEST, FETCH_VEHICLE_SUCCESS } from "../actions/actions";



// vehicleReducer.js
const initialState = {
    vehicles: [],
    vehicles2: [],
    vehiclesAvailable: [],
    vehiclesUnAvailable: [],
    vehicle: {},
    vehicleSelect: {},
    loadingVehicle:false,
    loadingVehicleSelect:false,
    loadingVehicles:false,
    errorVehicles:null,
    errorVehicle:null
    // ... autres états spécifiques à vehicleReducer
  };
  
  const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingVehicle:true,
          errorVehicles:""
          // Autres manipulations d'état pour la demande de récupération des vehicles
        };
        case FETCH_VEHICLE_SUCCESS:
        return {
          ...state,
          loadingVehicle:false,
          errorVehicles:"",
          vehicle:action.payload
        };
        case FETCH_VEHICLE_FAILURE:
        return {
          ...state,
          loadingVehicle:false,
          errorVehicles:action.payload
        };


      case FETCH_VEHICLES_REQUEST:
        return {
          ...state,
          loadingVehicles:true,
          errorVehicles:""
        };
       
      case FETCH_VEHICLES_SUCCESS:
        return {
          ...state,
          vehicles: action.payload,
          loadingVehicles:false,
          errorVehicles:""
          // Autres manipulations d'état pour le succès de la récupération des vehicles
        };
        case FETCH_VEHICLES_SUCCESS_2:
        return {
          ...state,
          vehicles2: action.payload,
          loadingVehicles:false,
          errorVehicles:""
          // Autres manipulations d'état pour le succès de la récupération des vehicles
        };


        case FETCH_VEHICLES_SUCCESS_AVAILABLE:
        return {
          ...state,
          vehiclesAvailable: action.payload,
          loadingVehicles:false,
          errorVehicles:""
          // Autres manipulations d'état pour le succès de la récupération des vehicles
        };

        case FETCH_VEHICLES_SUCCESS_UNAVAILABLE:
        return {
          ...state,
          vehiclesUnAvailable: action.payload,
          loadingVehicles:false,
          errorVehicles:""
          // Autres manipulations d'état pour le succès de la récupération des vehicles
        };
        
      case FETCH_VEHICLES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingVehicles:false,
          errorVehicles:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des vehicles
        };
      // ... autres cas pour d'autres actions liées aux vehicles

        
      default:
        return state;
    }
  };
  
  export default vehicleReducer;

  

