import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Badge, Button, Card, Col, Dropdown, Form, Modal, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from '../../actions/request/ReservationRequest';
import { fetchDamagesAll, fetchDamagesAllByUserId } from '../../actions/request/DamageRequest';
import { fetchPropertysAllResearchAvailable, fetchPropertysAllResearchUnvailable, fetchPropertysFilters } from '../../actions/request/PropertyRequest';
import { fetchVehiclesAllFilter, fetchVehiclesAllResearchAvailable, fetchVehiclesAllResearchUnAvailable } from '../../actions/request/VehicleRequest';
import { fetchInsurancesAll, fetchInsurancesAllByUserId } from '../../actions/request/InssuranceRequest';
import { fetchPaymentsAll } from '../../actions/request/PaymentRequest';
import moment from 'moment';
import { profileRoleType } from '../../utils/dataApi/dataFormApi';
import { localStorageKeys } from '../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { RiEye2Fill } from 'react-icons/ri';
import { ROUTES } from '../../config/routingUrl';
import { useNavigate } from 'react-router-dom';
import { MdCopyAll, MdOutlineSearch, MdWeb } from 'react-icons/md';
import Select from "react-select";
import { fetchMaintenancesAll } from '../../actions/request/MaintenanceRequest';
import { toast } from 'react-toastify';
import { fetchExpensessAll } from '../../actions/request/ExpenseRequest';
import { useAggregatedData } from './StatisticsReportPage';
import OccupancyRateChart from '../../components/statistiques/OccupancyRateChart';
import { exportToExcelData, exportToPDFData } from '../../utils/functionData/ExportFileDocument';
import { copyToClipboard, getCleanUrl } from '../../utils/functionData/otherFucntion';
import PatrimoineCountStatisticsCard from '../../components/statistiques/PatrimoineCountStatisticsCard';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const StatisticsPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const reservations = useSelector((state) => state.reservations.reservations);
    const paymentsData = useSelector((state) => state.payments.payments);
    const damages = useSelector((state) => state.damages.damages);
    const propertys = useSelector((state) => state.propertys.propertys);
    const propertysAvailables = useSelector((state) => state.propertys.propertysAvailable);
    const propertysUnAvailables = useSelector((state) => state.propertys.propertysUnAvailable);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const vehiclesAvailables = useSelector((state) => state.vehicles.vehiclesAvailable);
    const vehiclesUnAvailables = useSelector((state) => state.vehicles.vehiclesUnAvailable);
    const insurances = useSelector((state) => state.insurances.insurances);
    const expences = useSelector((state) => state.expenses.expenses);
    // Available






    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();

    const [payments, setpayments] = useState([]);


    const currentDate = moment().startOf('day');


    useEffect(() => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchDamagesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchInsurancesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPropertysFilters("", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllFilter("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAllByidUserId(getAndCheckLocalStorage(localStorageKeys.userId)));

            dispatch(fetchExpensessAll("", "", "", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)))

            dispatch(fetchVehiclesAllResearchAvailable(currentDate, currentDate, getAndCheckLocalStorage(localStorageKeys.userId)))
            dispatch(fetchVehiclesAllResearchUnAvailable(currentDate, currentDate, getAndCheckLocalStorage(localStorageKeys.userId)))

            dispatch(fetchPropertysAllResearchAvailable(currentDate, currentDate, getAndCheckLocalStorage(localStorageKeys.userId)))
            dispatch(fetchPropertysAllResearchUnvailable(currentDate, currentDate, getAndCheckLocalStorage(localStorageKeys.userId)))


        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchDamagesAll());
            dispatch(fetchInsurancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAll());
            dispatch(fetchExpensessAll());

            dispatch(fetchVehiclesAllResearchAvailable("", ""))
            dispatch(fetchVehiclesAllResearchUnAvailable("", ""))

            dispatch(fetchPropertysAllResearchAvailable("", ""))
            dispatch(fetchPropertysAllResearchUnvailable("", ""))
        }
    }, [dispatch]);


    useEffect(() => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData].filter((pay) => pay.reservation?.userAdd === getAndCheckLocalStorage(localStorageKeys.userId))
                : [];
            setpayments(paymentGetData);
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData].filter((pay) => pay.reservation?.client?._id === getAndCheckLocalStorage(localStorageKeys.userId))
                : [];
            setpayments(paymentGetData);
        }
        else {
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData] : [];
            setpayments(paymentGetData);
        }
    }, [paymentsData]);



    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const groupByMonth = (data, field) => {
        const grouped = Array(12).fill(0);
        data.forEach(item => {
            const month = moment(item.createdAt).month();
            grouped[month] += item[field];
        });
        return grouped;
    };

    const paymentsByMonth = payments && payments.length > 0 ? groupByMonth(payments, 'amount') : [];
    const damagesAndInsurancesByMonth = expences && expences.length > 0 ? groupByMonth(expences, 'amount') : [];

    const totalDamages = expences && expences.length > 0 ? expences.reduce((sum, damage) => sum + damage.amount, 0) : 0;

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Revenus (Paiements des Réservations)',
                data: paymentsByMonth,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
            {
                label: 'Dépenses (Dommages et Assurances , Autres)',
                data: damagesAndInsurancesByMonth,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    const lastThreeReservations = reservations ? [...reservations].slice(0, 5) : [];

    const reservationStatusCounts = (reservations) => {
        const statusCounts = { pending: 0, confirmed: 0, cancelled: 0, completed: 0 };
        reservations.forEach((reservation) => {
            if (reservation.status === 'PENDING') statusCounts.pending++;
            else if (reservation.status === 'CONFIRMED') statusCounts.confirmed++;
            else if (reservation.status === 'CANCELLED') statusCounts.cancelled++;
            else if (reservation.status === 'COMPLETED') statusCounts.completed++;
        });
        return statusCounts;
    };

    const statusCounts = reservationStatusCounts(reservations);

    const pieChartData = {
        labels: ['En attente', 'Confirmées', 'Annulées', 'Terminés'],
        datasets: [
            {
                data: [
                    statusCounts.pending,
                    statusCounts.confirmed,
                    statusCounts.cancelled,
                    statusCounts.completed
                ],
                backgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
                hoverBackgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
            },
        ],
    };



    const renderStatusBadge = (status) => {
        switch (status) {
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };



    //  filtre des option
    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : []

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : []




    const [showLoading, setshowLoading] = useState(false);
    const handleShowLoading = () => { setshowLoading(true) }
    const handleCloseLoading = () => { setshowLoading(false) }


    // function filter
    const handleSearchFilter = () => {
        handleShowLoading();
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question

            dispatch(fetchReservationsAllByidUserId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""
            ))
            dispatch(fetchPaymentsAll(startDate, endDate))
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData].filter((pay) => pay.reservation?.userAdd === getAndCheckLocalStorage(localStorageKeys.userId))
                : [];
            setpayments(paymentGetData);
            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId), startDate, endDate, propertySelect?.value, vehicleSelect?.valeu));
            dispatch(fetchExpensessAll(startDate, endDate, "", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));

            dispatch(fetchVehiclesAllResearchAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllResearchUnAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));

            dispatch(fetchPropertysAllResearchAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPropertysAllResearchUnvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));

        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""
            ));
            dispatch(fetchPaymentsAll(startDate, endDate))
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData].filter((pay) => pay.reservation?.client?._id === getAndCheckLocalStorage(localStorageKeys.userId))
                : [];
            setpayments(paymentGetData);
        }
        else {
            dispatch(fetchReservationsAll(startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""));
            dispatch(fetchPaymentsAll(startDate, endDate))
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData]
                : [];
            setpayments(paymentGetData);

            dispatch(fetchMaintenancesAll("", startDate, endDate, propertySelect?.value, vehicleSelect?.valeu));
            dispatch(fetchExpensessAll(startDate, endDate));

            dispatch(fetchVehiclesAllResearchAvailable(startDate, endDate));
            dispatch(fetchVehiclesAllResearchUnAvailable(startDate, endDate));

            dispatch(fetchPropertysAllResearchAvailable(startDate, endDate));
            dispatch(fetchPropertysAllResearchUnvailable(startDate, endDate));
        }
        handleCloseLoading();
    }


    const [showWebSite, setshowWebSite] = useState(false);
    const handleShowWebsite = () => { setshowWebSite(true) }
    const handleCloseWebsite = () => { setshowWebSite(false) }
















    const aggregatedData = useAggregatedData();
    // Calcul des totaux
    const totals = aggregatedData.reduce(
        (acc, entry) => {
            acc.totalPayments += entry.totalPayments;
            acc.totalExpenses += entry.totalExpenses;
            acc.totalDaysReserved += entry.totalDaysReserved;
            acc.totalDaysInReservationMonths += entry.totalDaysInReservationMonths; // Total des jours concernés par les mois
            acc.clientsCount += entry.clientsCount;
            return acc;
        },
        {
            totalPayments: 0,
            totalExpenses: 0,
            totalDaysReserved: 0,
            totalDaysInReservationMonths: 0, // Nouveau champ
            clientsCount: 0,
        }
    );

    // Nouvelle fonction de calcul du taux d'occupation total
    const calculateOccupancyRate = (totalDaysReserved, totalDaysInReservationMonths) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours concernés
        return (totalDaysReserved / totalDaysInReservationMonths) * 100;
    };

    // Utilisation dans votre composant
    const totalOccupancyRate = calculateOccupancyRate(
        totals.totalDaysReserved,
        totals.totalDaysInReservationMonths
    );







    return (
        <div className="">


            {/* Filtres pour les dates */}
            {<Card className="mb-4">
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4">Tableau de bord </h3>
                        </div>
                        <div>
                            {
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                                    <Button size="sm" variant='primary' onClick={handleShowWebsite} ><MdWeb /> <span>Site web</span></Button>
                                    : ""
                            }
                        </div>
                    </div>

                </Card.Header>
                <Card.Body>
                    {
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN
                            || profileRoleType.USER || profileRoleType.SUPPORT || profileRoleType.ADMIN ?
                            <ul className="list-group">
                                <li className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                    <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Véhicule</Form.Label>
                                                        <Select
                                                            value={vehicleSelect}
                                                            onChange={(selectedOption) => setvehicleSelect(selectedOption)}
                                                            options={vehicleOptions}
                                                            isClearable
                                                            isSearchable
                                                            placeholder="Choix"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Propriété</Form.Label>
                                                        <Select
                                                            value={propertySelect}
                                                            isClearable
                                                            isSearchable
                                                            onChange={(selectedOption) => setpropertySelect(selectedOption)}
                                                            options={propertyOptions}
                                                            placeholder="Choix"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="p-1">
                                            <label>{""}</label>
                                            <input type="date" style={{ height: "40px" }} className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                        </div>
                                        <div className="p-1">
                                            <label>{""}</label>
                                            <input type="date" style={{ height: "40px" }} className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        </div>
                                        <div className="m-1">
                                            <label>{""}</label>
                                            <div>
                                                <Button variant='primary' size='sm' onClick={handleSearchFilter} >
                                                    <MdOutlineSearch />
                                                </Button>
                                            </div>

                                        </div>

                                    </div>
                                </li>
                            </ul>
                            : ""
                    }
                </Card.Body>
            </Card>
            }

            {/* Cartes des statistiques */}
            <Row>

                <Col md={3}>
                    <Card className="mb-4">
                        <Card.Body>
                            <p className="card-text"><strong>Réservations</strong></p>
                            <hr />
                            <p className="card-text text-lg">
                                <span style={{ fontSize: "30px" }}>
                                    {

                                        reservations && reservations.length > 0 ?

                                            Number(reservations.length)
                                            : "Auccune"
                                    }
                                </span>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>


                <Col md={3}>
                    <Card className="mb-4">
                        <Card.Body>
                            <p className="card-text"><strong>Jours</strong></p>
                            <hr />
                            <p className="card-text text-lg" style={{ fontSize: "30px" }}>
                                {
                                    totals?.totalDaysReserved?.toFixed(0)
                                } Jours
                            </p>
                        </Card.Body>
                    </Card>
                </Col>






                <Col md={3}>
                    <Card className="mb-4">
                        <Card.Body>
                            <p className="card-text"><strong>Paiements</strong></p>
                            <hr />
                            <p className="card-text text-lg">
                                {

                                    payments && payments.length > 0 ?

                                        Number(payments.reduce((sum, payment) => sum + payment.amount, 0)).toFixed(2)
                                        : 0
                                } DZD
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                {
                    (
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT

                    ) && (
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Dépenses</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">{totalDamages} DZD</p>
                                </Card.Body>
                            </Card>
                        </Col>)
                }

                {
                    (
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT

                    ) &&
                    (
                        <Col md={4}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong> Patrimoine </strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span className="text-lg" style={{ fontSize: "30px" }}>{vehicles && vehicles.length > 0 ? vehicles.length : "0"}</span><strong> véhicules </strong>
                                        <span className="text-lg" style={{ fontSize: "30px" }}>{propertys && propertys.length > 0 ? propertys.length : "0"}</span><strong> propriétés </strong></p>
                                </Card.Body>
                            </Card>
                        </Col>)
                }

                {
                    
                        (
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
        
                        ) &&
                    (
                        <Col md={4}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong> Véhicules en mouvement </strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span className="text-lg text-danger" style={{ fontSize: "30px" }}>{vehiclesUnAvailables && vehiclesUnAvailables.length > 0 ? vehiclesUnAvailables.length : "0"}</span><strong className="text-danger"> Loués </strong>
                                        <span className="text-lg text-success" style={{ fontSize: "30px" }}>{vehiclesAvailables && vehiclesAvailables.length > 0 ? vehiclesAvailables.length : "0"}</span><strong className="text-success"> non loués </strong></p>
                                </Card.Body>
                            </Card>
                        </Col>)
                }
                {

                    (
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT

                    ) &&
                    (
                        <Col md={4}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong> Propriétés en mouvement </strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span className="text-lg text-danger" style={{ fontSize: "30px" }}>{propertysUnAvailables && propertysUnAvailables.length > 0 ? propertysUnAvailables.length : "0"}</span><strong className="text-danger"> louées </strong>
                                        <span className="text-lg text-success" style={{ fontSize: "30px" }}>{propertysAvailables && propertysAvailables.length > 0 ? propertysAvailables.length : "0"}</span><strong className="text-success"> non louées </strong></p>
                                </Card.Body>
                            </Card>
                        </Col>)
                }


            </Row>

            {
                (
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT

                ) &&
                <PatrimoineCountStatisticsCard />

            }



            {/* Affichage des 3 dernières réservations */}
            <Card className="mt-4">
                <Card.Body>
                    {
                        lastThreeReservations && lastThreeReservations.length > 0 ?
                            <>
                                <div className="mb-5" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                    <div><h6 className="">Réservations récentes</h6></div>
                                    <div><span className="text-link text-primary text-text-decoration-underline pointer-event" style={{ cursor: "pointer" }} size="sm" title="Listes des reservations"
                                        onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`) }}
                                    >Voire plus</span></div>
                                </div>

                                <ul className="list-group">

                                    {lastThreeReservations.map(reservation => (
                                        <li key={reservation._id} className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                            <div>
                                                <strong>{reservation?.ordre || ""}</strong> <strong style={{ fontWeight: "bold" }}> {reservation.property?.name || ""}  {reservation?.vehicle?.brand || ""} </strong> - {moment(reservation.createdAt).format('DD MMM YYYY')}
                                                {renderStatusBadge(reservation.status)}
                                            </div>
                                            <div>
                                                <Button variant='primary' size='sm'
                                                    onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`) }}
                                                ><RiEye2Fill /></Button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </> :
                            <>

                            </>

                    }
                    {/*<div className="text-center" style={{ width: "100%", height: "150px", justifyContent: "center" }}>
                                Aucune réservation
                            </div> */}
                </Card.Body>
            </Card>

            {/* Graphiques */}
            <Row>
                {/*
                    reservations && reservations.length > 0 ?
                        <Col md={6}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <h6>Réservations par État</h6>
                                    <Pie data={pieChartData} />
                                </Card.Body>
                            </Card>
                        </Col> : ""
                         */
                }

                {
                    (
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT

                    ) &&

                    <Tabs defaultActiveKey="payment_expenses" id="reservation-details-tabs" className="mb-3">
                        <Tab eventKey="payment_expenses" title="Paiements  dépenses">
                            {
                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER || profileRoleType.SUPER_ADMIN | profileRoleType.ADMIN || profileRoleType.SUPPORT) &&
                                (reservations && reservations.length > 0 || expences && expences.length > 0) &&
                                (
                                    <Col md={12}>
                                        <Card className="mb-4">
                                            <Card.Body>
                                                <h6>Revenus et Dépenses</h6>
                                                <Line data={lineChartData} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Tab>



                        <Tab eventKey="occupacity_vehicle" title="Graphique du taux d'occupation">
                            {
                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER || profileRoleType.SUPER_ADMIN | profileRoleType.ADMIN || profileRoleType.SUPPORT) &&
                                (reservations && reservations.length > 0 || expences && expences.length > 0) &&
                                (
                                    <Col md={12}>
                                        <Card className="mb-4">
                                            <Card.Body>
                                                <OccupancyRateChart data={aggregatedData} type={"vehicle"} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Tab>




                    </Tabs>

                }

            </Row>




            <Modal show={showLoading} size='sm' onHide={handleCloseLoading} centered style={{ background: "transparent" }}>
                <Modal.Body>
                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <div><Spinner variant='primary' /></div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showWebSite} onHide={handleCloseWebsite} centered>
                <Modal.Body>
                    <div style={{ width: "100%" }}>
                        <div className="form-group mb-3">
                            <label htmlFor="password" className="form-label">Url Site web</label>
                            <div className="input-group">
                                <input style={{ height: "40px" }}
                                    readOnly
                                    className={`form-control`}
                                    placeholder=""
                                    value={
                                        `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${getAndCheckLocalStorage(localStorageKeys.userId) || ""}/person-or-company/${getAndCheckLocalStorage(localStorageKeys.userId) || ""}`}`
                                    }
                                />
                                <button
                                    type="button" style={{ height: "40px" }}
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        copyToClipboard(
                                            `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${getAndCheckLocalStorage(localStorageKeys.userId) || ""}/person-or-company/${getAndCheckLocalStorage(localStorageKeys.userId) || ""}`}`
                                        )
                                        setshowWebSite(false);
                                    }}
                                >
                                    <MdCopyAll />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        </div>
    );
};

export default StatisticsPage;
