import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Badge, Card, Col, Row, Image } from 'react-bootstrap';
import Select from 'react-select';
import { MdColorize, MdMap, MdSubscriptions } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertysAll } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll } from '../../../actions/request/VehicleRequest';
import { fetchInssuranceTypesAll } from '../../../actions/request/InssuranceTypeRequest';
import { InsuranceCreate, InsuranceUpdateById, fetchInsurancesAll } from '../../../actions/request/InssuranceRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import moment from 'moment';
import { ROUTES } from '../../../config/routingUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleImageUploadCloudOnly } from '../../../actions/upload/UploadFileCloud';

const NewInsuranceHeritagePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicleData = useSelector((state) => state.vehicles.vehicles);
  const propertyData = useSelector((state) => state.propertys.propertys);
  const insuranceTypes = useSelector((state) => state.insuranceTypes.insuranceTypes);
  const insurancesData = useSelector((state) => state.insurances.insurances);

  const { _id, name, brand, model, color, address, mileage, registrationNumber, passenger, images } = location.state;

  useEffect(() => {
    dispatch(fetchInssuranceTypesAll());
    dispatch(fetchInsurancesAll());

    if (brand) {
      setInsuredObjectType("Vehicle");
    } else {
      setInsuredObjectType("Property");
    }
  }, [dispatch]);

  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [filePreview, setFilePreview] = useState('');

  const [type, setType] = useState('');
  const [insuredObjectType, setInsuredObjectType] = useState('Vehicle');
  const [vehicle, setVehicle] = useState(null);
  const [property, setProperty] = useState(null);
  const [policyNumber, setPolicyNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [premiumAmount, setPremiumAmount] = useState('');
  const [insuranceFile, setInsuranceFile] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'policyNumber':
        setPolicyNumber(value);
        break;
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'premiumAmount':
        // Vérifie si la valeur est un nombre positif
        const numericValue = parseFloat(value);
        // Vérifie si la valeur est un nombre valide et positif
        if (!isNaN(numericValue) && numericValue >= 0) {
          setPremiumAmount(value); // Si la valeur est valide et positive, mettez à jour l'état
        } else {
          setPremiumAmount(''); // Sinon, réinitialisez l'état
        }
        break;
      default:
        break;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) { // Limite de 1MB
        toast.error("Le fichier ne doit pas dépasser 1MB.");
        return;
      }
      const fileget = await handleImageUploadCloudOnly(file, toast)
      setInsuranceFile(fileget);
    }
  };

  const handleSubmit = async () => {
    const dataToSend = {
      type,
      insuredObjectType,
      vehicle: insuredObjectType === 'Vehicle' ? _id : null,
      property: insuredObjectType === 'Property' ? _id : null,
      policyNumber,
      startDate,
      endDate,
      premiumAmount,
      insuranceFile,
      userId: getAndCheckLocalStorage(localStorageKeys.userId),
    };



    if (selectedInsurance) {
      dispatch(InsuranceUpdateById(selectedInsurance.id, dataToSend, navigate, toast));
    } else {
      if (!insuranceFile) {
        toast.error("Veillez Télécharger fichier de l'assurance", { position: "top-right" })
      } else {
        dispatch(InsuranceCreate(dataToSend, navigate, toast));
      }
    }
    setShowInsuranceModal(false);
  };

  const vehicleOptions = vehicleData && vehicleData.length > 0 ? vehicleData.map(vehicle => ({ value: vehicle._id, label: vehicle.brand })) : [];
  const propertyOptions = propertyData && propertyData.length > 0 ? propertyData.map(property => ({ value: property._id, label: property.name })) : [];
  const insuranceTypeOptions = insuranceTypes && insuranceTypes.length > 0 ? insuranceTypes.map(type => ({ value: type._id, label: type.name })) : [];

  return (
    <div>
      <Card>
        <Card.Header><h3>Nouvelle configuration d{"'"}assurance</h3></Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={12}>
                {
                  _id ?
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <Row>
                          <Col md={2}>
                            {images && images.length && images.length > 0 ?
                              <Image src={images[0]} className="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={brand || model} /> : ""
                            }
                          </Col>
                          <Col md={10}>
                            <ul className="list-group list-group-flush">
                              {brand ? <li className="list-group-item">{brand || ""}</li> : ""}
                              {model ? <li className="list-group-item">Model :  {model || ""}</li> : ""}
                              {color ? <li className="list-group-item"><MdColorize /> {color || ""}</li> : ""}
                              {name ? <li className="list-group-item">Titre : {name || ""}</li> : ""}
                              {address ? <li className="list-group-item"><MdMap /> {address || ""}</li> : ""}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    : ""
                }
              </Col>

              <Col md={4}>
                <Form.Group controlId="type">
                  <Form.Label>Type d{"'"}Assurance</Form.Label>
                  <Select
                    options={insuranceTypeOptions}
                    value={insuranceTypeOptions.find(option => option.value === type)}
                    onChange={(selectedOption) => setType(selectedOption ? selectedOption.value : '')}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="premiumAmount">
                  <Form.Label>Montant de la Prime</Form.Label>
                  <Form.Control
                    style={{ height: "40px" }}
                    type="number"
                    placeholder=""
                    name="premiumAmount"
                    value={premiumAmount}
                    onChange={handleChange}
                    min={0}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="policyNumber">
                  <Form.Label>Numéro de Police</Form.Label>
                  <Form.Control
                    style={{ height: "40px" }}
                    type="text"
                    placeholder="Numéro de Police"
                    name="policyNumber"
                    value={policyNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="startDate">
                  <Form.Label>Date de Début</Form.Label>
                  <Form.Control
                    style={{ height: "40px" }}
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="endDate">
                  <Form.Label>Date d{"'"}Expiration</Form.Label>
                  <Form.Control
                    style={{ height: "40px" }}
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group controlId="insuranceFile">
                  <Form.Label>Fichier d{"'"}Assurance</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {filePreview && <img src={filePreview} alt="Preview" style={{ width: '100px', marginTop: '10px' }} />}
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" size="sm" className="mt-5" onClick={handleSubmit}>Ajouter </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default NewInsuranceHeritagePage;