import axios from "axios";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageData } from "../../utils/storage/localvalue";

// Fonction pour récupérer le taux de change avec buyRate
export async function fetchExchangeRateGetCurency(currency) {

    try {
        const response = await axios.get(`${baseurl.url}/api/v1/exchange-rates/get_exchange-rate/${currency}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        });
        const buyRate = response.data.data.buyRate;
        saveDataToFile(buyRate, localStorageData.ExchangeRateGetNow);
        console.log(buyRate)
        return { buyRate };
    } catch (error) {
        console.error('Erreur lors de la récupération du taux de change :', error);
        return { buyRate: getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow)  };
    }
}
