import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Card, Col, Row, Badge, Form, Table, Image, Dropdown, Accordion, Breadcrumb, DropdownButton, Tabs, Tab, Alert, Spinner } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { ReservationExtendById, ReservationImagesVerieldUpdateById, ReservationStatutUpdateById, ReservationUpdateById, ReservationUpdateToalPriceRealById, fetchReservationById, fetchchangeReservationItemById } from '../../../actions/request/ReservationRequest';
import './ReservationDetailPage.css'; // Assure-toi que le chemin est correct
import { FaAddressCard, FaCar, FaFileInvoice, FaFileInvoiceDollar, FaHome, FaRegAddressCard } from 'react-icons/fa'; // Assure-toi que react-icons est installé
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageData, localStorageKeys } from '../../../utils/storage/localvalue';
import { MdCancel, MdCancelPresentation, MdClose, MdColorize, MdConfirmationNumber, MdCreditCard, MdDateRange, MdDiscount, MdEdit, MdEditNote, MdImage, MdLocationOn, MdMap, MdMoneyOff, MdOutlineImage, MdPerson, MdRequestQuote, MdSocialDistance, MdTimeline, MdWaterDamage } from 'react-icons/md';
import { GiCancel } from "react-icons/gi";
import { ROUTES } from '../../../config/routingUrl';
import { RiEdit2Fill, RiEye2Fill, RiFile2Fill, RiFileDamageFill } from 'react-icons/ri';
import { fetchDamagesAllByReservationId } from '../../../actions/request/DamageRequest';
import { fetchMotifsAll } from '../../../actions/request/MotifRequest';
import { ageDateNaissanceMax, emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { handleImageUploadCloud } from '../../../actions/upload/UploadFileCloud';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { fetchExpenseCategorysAll } from '../../../actions/request/ExpenseCategoryRequest';
import { ExpensesCreate, fetchExpensessAll } from '../../../actions/request/ExpenseRequest';
import { LiaExchangeAltSolid } from "react-icons/lia";
import { RxReload } from "react-icons/rx"
import { fetchVehiclesAllResearchAvailable } from '../../../actions/request/VehicleRequest';
import { fetchPropertysAllResearchAvailable } from '../../../actions/request/PropertyRequest';
import { fetchInsurancesAll, fetchInsurancesAllByReservationId } from '../../../actions/request/InssuranceRequest';
import { fetchCodePostalsAll } from '../../../actions/request/CodePostalRequest';
import { fetchCompanyAiportsAll } from '../../../actions/request/CompanyAiportRequest';
import { baseurl } from '../../../utils/url/baseurl';
import axios from 'axios';
import ImageGalleryComponent from '../../../components/admin/reservations/ImageGalleryDriverAndCardComponent';
import ReservationMap from '../../../components/admin/reservations/ReservationMap';

// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});




const renderStatusBadgeDamage = (status) => {
    switch (status) {
        case 'Reported':
            return <Badge bg="danger">Signalé</Badge>;
        case 'Resolved':
            return <Badge bg="success">Résolu</Badge>;
        case 'In Progress':
            return <Badge bg="danger">Annulée</Badge>;
        case 'Paid':
            return <Badge bg="danger">Annulée</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};

const ReservationDetailPage = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const expenses = useSelector((state) => state.expenses.expenses);

    const expensecategorys = useSelector((state) => state.expensecategorys.expensecategorys);
    const companyairports = useSelector((state) => state.companyairports.companyairports);
    const codepostals = useSelector((state) => state.codepostals.codepostals);
    const reservation = useSelector((state) => state.reservations.reservation);
    const loadingReservation = useSelector((state) => state.reservations.loadingReservation);
    const damages = useSelector((state) => state.damages.damages);
    const motifs = useSelector((state) => state.motifs.motifs);

    const propertyData = useSelector((state) => state.propertys.property);
    const vehicleData = useSelector((state) => state.vehicles.vehicle);


    const propertysAvailables = useSelector((state) => state.propertys.propertysAvailable);
    const vehiclesAvailables = useSelector((state) => state.vehicles.vehiclesAvailable);


    const [vehicleAvalaibleSelect, setvehicleAvalaibleSelect] = useState();
    const [propertyAvailableSelect, setpropertyAvailableSelect] = useState();

    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [idReservation, setidReservation] = useState();

    const [isLoading, setisLoading] = useState(false);

    const [startDateSearch, setStartDateSearch] = useState('');
    const [endDateSearch, setEndDateSearch] = useState('');

    const [totalPriceReal, settotalPriceReal] = useState(0);

    const [idMotif, setidMotif] = useState(null);
    const [motifComment, setmotifComment] = useState();

    const [endHour, setEndHour] = useState({ value: "10:00", label: "10:00" });

    const [showModalCardId, setshowModalCardId] = useState(false)
    const [showModalDriverFile, setshowModalDriverFile] = useState(false);
    const [showModalimagesVerifield, setshowModalimagesVerifield] = useState(false);
    const [showModalUpdateImagesVerifield, setshowModalUpdateImagesVerifield] = useState(false);
    const [showModalTotalPriceReal, setshowModalTotalPriceReal] = useState("");

    const [formDataExtend, setformDataExtend] = useState({
        newEndDate: "",
        imagesVerifield: [],
        discount: 0,
        vehicle: reservation?.vehicle?._id,
        property: reservation?.property?._id,
        basePricePerDay: reservation?.vehicle?.basePricePerDay || reservation?.property?.basePricePerDay,
        commissionPercentage: reservation?.vehicle?.commissionPercentage || reservation?.property?.commissionPercentage,
        deliveryFee: reservation?.vehicle?.deliveryFee || reservation?.property?.deliveryFee,
        discount: reservation?.vehicle?.discount || reservation?.property?.discount,
        totalPriceReal: Number(reservation?.totalPrice || 0),
        // additionalPrice: "",
        reason: ""
    });

    const [categorySelect, setcategorySelect] = useState(null);
    const [formDataExpense, setformDataExpense] = useState({
        category: "",
        reservation: id || reservation?._id || idReservation,
        property: reservation?.property?._id || "",
        vehicle: reservation?.vehicle?._id || "",
        amount: 0,
        description: '',
        date: "",
        createdBy: getAndCheckLocalStorage(localStorageKeys.userId)
    });

    const categorysExpenseOptions = expensecategorys.map((item) => { return ({ value: item._id, label: item.name }) }) || []

    const vehicleAvailablesOptions = vehiclesAvailables.map((item) => { return ({ value: item?._id, label: item?.brand, images: item?.images, model: item?.model, color: item?.color, passenger: item?.passenger, address: item?.address }) }) || []

    const propertyAvailableOptions = propertysAvailables.map((item) => { return ({ value: item?._id, label: item?.name, images: item?.images, address: item?.address }) }) || []


    const [formDataConfirm, setformDataConfirm] = useState({
        amount: "",
        user: getAndCheckLocalStorage(localStorageKeys.userId),
    });



    const [verficationAutorizationActionUser, setverficationAutorizationActionUser] = useState(false);

    const verficationAutorizationActionUserAuthorized = () => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);

        // Vérifier l'autorisation en fonction de la propriété ou du véhicule
        if (propertyData && propertyData.addBy && propertyData.addBy == userId) {
            // console.log(true);
            return true;
        }
        else if (vehicleData && vehicleData.addBy && vehicleData.addBy == userId) {
            // console.log(true);
            return true;
        }
        else {
            // console.log(false);
            return false;
        }
    };

    useEffect(() => {
        setidReservation(id);
        dispatch(fetchExpenseCategorysAll());
        dispatch(fetchMotifsAll());
        dispatch(fetchCodePostalsAll());
        dispatch(fetchCompanyAiportsAll());
        dispatch(fetchReservationById(id || idReservation));
        dispatch(fetchDamagesAllByReservationId(id || idReservation));
        dispatch(fetchExpensessAll("", "", "", "", "", "", "", id || idReservation || reservation?._id))
        fetchReservationGetById();

        // Appeler la fonction pour vérifier l'autorisation

    }, [dispatch]); // Ajoute property et vehicle dans les dépendances si elles proviennent de l'état


    useEffect(() => {
        const isAuthorized = verficationAutorizationActionUserAuthorized();
        setverficationAutorizationActionUser(isAuthorized);
    }, [verficationAutorizationActionUser])



    const handleImagesVerifield = async (e) => {
        const photosveifield = await handleImageUploadCloud(e.target.files);
        setformDataExtend({ ...formDataExtend, imagesVerifield: photosveifield })
    };




    // update reservation information

    const [showUpdateReservation, setshowUpdateReservation] = useState(false);
    const handleShowUpdateReservation = () => { setshowUpdateReservation(true) }
    const handleCloseUpdateReservation = () => { setshowUpdateReservation(false) }


    const [reservationData, setReservationData] = useState({
        driverLicenseNumber: '',
        driverLicenseFile: [],
        dateOfBirth: '',
        pickupLocation: '',
        returnLocation: '',
        latStart: '',
        lngStart: '',
        latEnd: '',
        lngEnd: '',
        companyAirport: '',
        numberVolAiport: '',
        idCard: '',
        idCardFile: [],
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        codePostal: '',
        userAdd: getAndCheckLocalStorage(localStorageKeys.userId)
    });


    const handleUploadCardFileReservation = async (e) => {
        const files = e.target.files
        const photos = await handleImageUploadCloud(files, toast)
        handleInputChange("idCardFile", photos)
    }

    const handleUploadDriverFileReservation = async (e) => {
        const files = e.target.files
        const photos = await handleImageUploadCloud(files, toast)
        handleInputChange("driverLicenseFile", photos)
    }

    // Calculate the maximum date (16 years ago) using Moment.js
    const maxDate = moment().subtract(ageDateNaissanceMax, 'years').format('YYYY-MM-DD');


    const codePostalOptions = codepostals.map((item) => { return ({ value: item?._id, label: item?.indicatif + " " + `( ${item.country} )` }) }) || []

    const airportOptions = companyairports.map((item) => { return ({ value: item?._id, label: item.name }) }) || [];



    // Fonction pour mettre à jour les champs
    const handleInputChange = (field, value) => {
        setReservationData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };






    // get reservation data
    async function fetchReservationGetById() {
        await axios.get(`${baseurl.url}/api/v1/reservations/get_reservation/${id || ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.typeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            // dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: response.data.data });
            const repoonData = response.data.data;
            setReservationData({
                ...reservationData,
                driverLicenseNumber: repoonData.driverLicenseNumber,
                driverLicenseFile: repoonData.driverLicenseFile,
                dateOfBirth: repoonData.dateOfBirth,
                pickupLocation: repoonData.pickupLocation,
                returnLocation: repoonData.returnLocation,
                latStart: repoonData.latStart,
                lngStart: repoonData.lngStart,
                latEnd: repoonData.latEnd,
                lngEnd: repoonData.lngEnd,
                companyAirport: repoonData.companyAirport?._id,
                numberVolAiport: repoonData.numberVolAiport,
                idCard: repoonData.idCard,
                idCardFile: repoonData.idCardFile,
                firstname: repoonData.firstname,
                lastname: repoonData.lastname,
                email: repoonData.email,
                phone: repoonData.phone,
                codePostal: repoonData.codePostal?._id
            })
            // setPickupLocation(repoonData.address);
        })
            .catch((error) => {
                // dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message })
                // console.log(error);
                var a = error;
                return;
                // toast.error("Proprité non récupérer", { position: "bottom-right" })
            });
    }



    // information de carte en question 
    const [locationStart, setlocationStart] = useState('');
    const [locationEnd, setlocationEnd] = useState('');


    const [pickupLocation, setPickupLocation] = useState('');
    const [returnLocation, setReturnLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [returnSuggestions, setReturnSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    // Obtenir les suggestions de lieu de retour
    const getPlacesByCountryEndLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setlocationStart(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    const handleReturnLocationChange = async (e) => {
        const value = e.target.value;
        setlocationEnd(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryEndLocation(value);
            setReturnSuggestions(suggestions);
        } else {
            setReturnSuggestions([]);
        }
    };

    const handlePickupSelection = (suggestion) => {

        setlocationStart(`${suggestion.name} - ${suggestion.formatted_address}`);
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setReservationData({
            ...reservationData,
            pickupLocation: `${suggestion.name} - ${suggestion.formatted_address}`,
            latStart: suggestion?.geometry?.location?.lat,
            lngStart: suggestion?.geometry?.location?.lng
        });
        // setlngStart(suggestion?.geometry?.location?.lng);

        // console.log({
        //     address: `${suggestion.name} - ${suggestion.formatted_address}`,
        //     lat: suggestion?.geometry?.location?.lat,
        //     lng: suggestion?.geometry?.location?.lng
        // });


        setPickupSuggestions([]);

        // Vérifier si le lieu de départ est identique au lieu de retour
        if (returnLocation && returnLocation === suggestion) {
            setPickupLocation('');
            setReturnLocation('');
            toast.error("Le lieu de départ et le lieu de retour ne peuvent pas être identiques.", { position: "bottom-right" });
        }
    };

    const handleReturnSelection = (suggestion) => {
        setlocationEnd(`${suggestion.name} - ${suggestion.formatted_address}`)
        setReturnLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setReservationData({
            ...reservationData,
            returnLocation: `${suggestion.name} - ${suggestion.formatted_address}`,
            latEnd: suggestion?.geometry?.location?.lat,
            lngEnd: suggestion?.geometry?.location?.lng
        });






        // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
        // console.log({
        //     address: `${suggestion.name} - ${suggestion.formatted_address}`,
        //     lat: suggestion?.geometry?.location?.lat,
        //     lng: suggestion?.geometry?.location?.lng
        // })
        setReturnSuggestions([]);

        // Vérifier si le lieu de retour est identique au lieu de départ
        if (pickupLocation && pickupLocation === suggestion) {
            setPickupLocation('');
            setReturnLocation('');
            toast.error("Le lieu de départ et le lieu de retour ne peuvent pas être identiques.", { position: "bottom-right" });
        }
    };



    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
        setReturnSuggestions([]);
    }










    if (loadingReservation) {
        return <p className="text-center"><Spinner variant="primary" /></p>;
    }

    if (!reservation) {
        // toast.error("Reservation non trouvé ! Veillez recharger la page", { position: "bottom-right" })
        return (<p className="text-center">Réservation non trouvez , veillez relancez à nouveau . <a href={"#"} onClick={() => { dispatch(fetchReservationById(id || idReservation)) }}> Clique ici !</a> </p>)
    }


    const { vehicle, property, client, startDate, imagesVerifield, endDate, numberOfGuests, specialRequests, status, motif, commissionPercentage, deliveryFee, basePricePerDay, driverLicenseFile, idCardFile } = reservation;

    const motifsOptions = (motifs || []).map(motif => ({
        value: motif._id,
        label: motif.name
    }));

    const images = (vehicle?.images || property?.images || []).map(image => ({
        original: image,
        thumbnail: image
    }));



    const imagesDriverFile = (reservation.driverLicenseFile || []).map(image => ({
        original: image,
        thumbnail: image
    }));

    const imagesidCardFile = (reservation.idCardFile || []).map(image => ({
        original: image,
        thumbnail: image
    }));


    const imagesimagesVerifield = (reservation.imagesVerifield || []).map(image => ({
        original: image,
        thumbnail: image
    }));














    const clientName = reservation && reservation.firstname && reservation.lastname ? `${reservation.firstname}  ${reservation.lastname}` : client && client.firstname ? `${client.firstname} ${client.lastname}` : "";
    const clientEmail = reservation && reservation.email ? reservation.email : client && client.email ? client.email : 'Non disponible';
    const clientPhone = reservation && reservation.phone && reservation.codePostal ? `${reservation.codePostal.indicatif} ${reservation.phone}` : client && client.phone ? `${client && client.codePostal && client.codePostal.indicatif ? client.codePostal.indicatif : ""}${client && client.phone ? client.phone : ""}` : 'Non disponible';

    const statusClass = status ? status.toLowerCase() : 'unknown-status';



    // ufnction modal
    const openModal = (action) => {
        setActionType(action);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setActionType('');
    };

    // function des modal de reservation

    const handleShowModalCardId = () => { setshowModalCardId(true) }
    const handleCloseModalCardId = () => { setshowModalCardId(false) }

    const handleShowModalDriver = () => { setshowModalDriverFile(true) }
    const handleCloseModalDriver = () => { setshowModalDriverFile(false) }


    const handleShowimagesVerifield = () => { setshowModalimagesVerifield(true) }
    const handleCloseimagesVerifield = () => { setshowModalimagesVerifield(false) }


    const handleShowUpdateImagesVerifield = () => { setshowModalUpdateImagesVerifield(true) }
    const handleCloseUpdateImagesVerifield = () => { setshowModalUpdateImagesVerifield(false) }














    const handleUpdateImagesVerifield = (e) => {
        e.preventDefault();
        if (formDataExtend && formDataExtend.imagesVerifield && formDataExtend.imagesVerifield.length == 0) {
            toast.error("Images état des lieus obigatoire ", { position: "bottom-right" });
        }
        else {
            setisLoading(true)
            dispatch(ReservationImagesVerieldUpdateById(id || reservation.id, formDataExtend, toast))
            setisLoading(false);
        }
    }




    const confirmAction = () => {
        if (actionType === 'Valider') {
            // Logique pour valider la réservation

            const formatDataSend = {
                discount: formDataExtend.discount
            }
            // if (formDataExtend && formDataExtend.imagesVerifield && formDataExtend.imagesVerifield.length == 0) {
            //     toast.error("Champ Etat de lieux Requis ", { position: "bottom-right" })
            // } else {
            setisLoading(true)
            if (formDataExtend && formDataExtend.discount && formDataExtend.discount > reservation?.totalPrice) {
                toast.error("La remise est supérieure au prix de la réservation", { position: "bottom-right" })
            } else {
                dispatch(ReservationStatutUpdateById(id || reservation._id, "CONFIRMED", formDataExtend.discount || 0, "", "", formDataExtend.imagesVerifield || [], toast));
                setformDataExtend({ ...formDataExtend, discount: 0 });
                setmotifComment("");
                setidMotif(null);
                setisLoading(false);
            }


        } else if (actionType === 'Prolonger') {
            // Logique pour annuler la réservation
            if (!formDataExtend && !formDataExtend.newEndDate && !endHour?.value && !formDataExtend.reason) {
                toast.error("Veillez selectionner Champ date et Commentaire requis ", { position: "bottom-right" })
                setisLoading(false);
            } else {
                if (formDataExtend.discount > totalPrice) {
                    toast.error("La remise ne peut pas etre supérieure à la montant au total ", { position: "bottom-right" })
                } else {
                    const endDateTime = combineDateAndTime(formDataExtend.newEndDate, endHour);
                    setformDataExtend({ ...formDataExtend, newEndDate: endDateTime });
                    dispatch(ReservationExtendById(id || reservation?._id, formDataExtend, toast))
                    setisLoading(false);
                }
            }

        } else if (actionType === 'Annuler') {
            // Logique pour annuler la réservation
            if (!idMotif && !idMotif?.value) {
                toast.error("Veillez selectionner le motif s'il vous plait", { position: "bottom-right" })
            } else {
                dispatch(ReservationStatutUpdateById(id || reservation?._id, "CANCELLED", formDataExtend.discount || 0, idMotif?.value, motifComment, "", toast));
                setformDataExtend({ ...formDataExtend, discount: 0 });
                setmotifComment("");
                setidMotif(null);
                setisLoading(false);
            }
        } else if (actionType === "Modifier") {
            if (!formDataExtend.totalPriceReal || formDataExtend.totalPriceReal == 0) {
                toast.error("Prix de la reservation est requis", { position: "bottom-right" })
                setisLoading(false);
            } else {
                // console.log(" Prix réele  : ", formDataExtend.totalPriceReal);
                dispatch(ReservationUpdateToalPriceRealById(id || reservation?._id, formDataExtend.totalPriceReal));
                setisLoading(false);
            }
            setisLoading(false);
        }
        else if (actionType === "Depense") {
            // console.log(formDataExpense)
            if (!formDataExpense.reservation || !formDataExpense.category && !formDataExpense.amount && formDataExpense.amount == 0 && formDataExpense.date) {
                toast.error("Veillez renseigner catégorie , le montant , la date de la dépense", { position: "bottom-right" })
                setisLoading(false);
            } else {
                // console.log(" Dé", formDataExpense);
                dispatch(ExpensesCreate(formDataExpense));
                dispatch(fetchExpensessAll("", "", "", "", "", "", "", id || idReservation || reservation?._id))
                setisLoading(false);
            }
            setisLoading(false);
        }
        else if (actionType === "Changer") {
            const formatDataChangeItem = {
                reservationId: id || idReservation || reservation?._id,
                newVehicleId: vehicleAvalaibleSelect?.value,
                newPropertyId: propertyAvailableSelect?.value,
                userAdd: getAndCheckLocalStorage(localStorageKeys.userId)
            }
            // console.log(vehicleAvalaibleSelect)
            // console.log(propertyAvailableSelect);
            // console.log(formatDataChangeItem)
            if (!formatDataChangeItem.reservationId) {
                toast.error("Reference de la reservaiton obligatoire", { position: "bottom-right" })
                setisLoading(false);
                return;
            }
            if (!propertyAvailableSelect?.value && !vehicleAvalaibleSelect?.value) {
                toast.error("Veuillez sélectionner soit un véhicule, soit une propriété s'il vous plaît", { position: "bottom-right" });
                setisLoading(false);
                return;
            }

            dispatch(fetchchangeReservationItemById(formatDataChangeItem));
            dispatch(fetchExpensessAll("", "", "", "", "", "", "", id || idReservation || reservation?._id));
            dispatch(fetchInsurancesAllByReservationId(id || idReservation || reservation?._id));
            setisLoading(false);
        }
        closeModal();
    };


    const handleSubmitUpdateReservation = () => {
        // Vérification que tous les champs requis sont remplis
        // Validation de véhicule ou propriété sélectionné(e)
        if (!reservation?.vehicle?._id && !reservation?.property?._id) {
            toast.error("Veuillez sélectionner un véhicule ou une propriété.", { position: "bottom-right" });
            setisLoading(false);
            return;
        }

        // Validation des informations de contact de l'utilisateur
        if (!reservationData?.firstname || !reservationData?.lastname || !reservationData?.email || !reservationData?.phone || !reservationData?.codePostal || !reservationData?.dateOfBirth) {
            toast.error("Veuillez remplir tous les champs de contact requis (prénom, nom, email, téléphone, code postal, date de naissance).", { position: "bottom-right" });
            setisLoading(false);
            return;
        }

        // Validation des documents d'identité
        if (!reservationData?.idCard && !reservationData?.driverLicenseNumber) {
            toast.error("Pièce d'identité ou permis de conduire obligatoire", { position: "bottom-right" });
            setisLoading(false);
            return;
        }

        // Vérification des coordonnées géographiques
        if (reservation?.vehicle?._id && (!reservationData?.latStart || !reservationData?.lngStart || !reservationData?.latEnd || !reservationData?.lngEnd)) {
            toast.error("Veuillez vérifier les coordonnées géographiques de départ et d'arrivée.", { position: "bottom-right" });
            setisLoading(false);
            return;
        }


        if (reservationData.idCardFile.length === 0 && reservationData.driverLicenseFile.length === 0) {
            toast.error("Veuillez télécharger les fichiers requis (carte d'identité et permis de conduire).", { position: "bottom-right" });
            setisLoading(false);
            return;
        }

        if ((reservationData.companyAirport && !reservationData.numberVolAiport) ||
            (!reservationData.companyAirport && reservationData.numberVolAiport)) {
            toast.error("Veuillez fournir à la fois la compagnie aérienne et le numéro de vol.", { position: "bottom-right" });
            setisLoading(false);
            return;
        }
        // if (getAndCheckLocalStorage(localStorageKeys.userId) === (reservation?.property?.addBy || reservation?.vehicle?.addBy)) {
        //     toast.error("Cette réservation ne vous appartient pas ", { position: "bottom-right" });
        //     setisLoading(false);
        //     return;
        // }
        // Si tous les champs sont remplis, procéder à la mise à jour
        // console.log(reservationData);
        dispatch(ReservationUpdateById(id || idReservation || reservation?._id, reservationData, toast));
        handleCloseUpdateReservation();
        // setisLoading(false);
    };




    // Fonction pour calculer la durée en jours, heures, minutes
    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const durationInMs = end - start;
        const durationInHours = durationInMs / (1000 * 60 * 60);

        let days = Math.floor(durationInHours / 24);
        let hours = Math.floor(durationInHours % 24);
        let minutes = Math.floor((durationInHours % 1) * 60);

        // Si on dépasse l'heure ou les minutes, on arrondit au jour suivant
        if (hours > 0 || minutes > 0) {
            days += 1;  // Ajout d'un jour supplémentaire si on dépasse
            hours = 0;  // On remet les heures à 0
            minutes = 0; // On remet les minutes à 0
        }
        return { days, hours, minutes };
    };

    // Calcul de la durée entre startDate et endDate
    const duration = calculateDuration(endDate || Date.now, formDataExtend.newEndDate);


    const calculateTotalPrice = () => {
        const { days } = duration;

        const basePricePerDay = Number(vehicle?.basePricePerDay || property?.basePricePerDay || 0) || 0;
        const commissionPercentage = Number(vehicle?.commissionPercentage || property?.commissionPercentage || 0) || 0;
        const deliveryFee = Number(vehicle?.deliveryFee || property?.deliveryFee || 0) || 0;
        const discount = Number(formDataExtend.discount || 0) || 0;

        const totalPriceForDays = days * basePricePerDay;

        // Total avant frais de livraison et remises
        const subTotal = totalPriceForDays;

        // Ajout de la commission
        const commission = (subTotal * commissionPercentage) / 100;

        // Calcul du prix total
        const totalPriceCal = Number(subTotal || 0) + Number(deliveryFee || 0) + Number(commission || 0) - Number(discount || 0);
        return totalPriceCal || 0;
    };



    const totalPrice = calculateTotalPrice();
    // console.log(tolaPrice)

    const today = endDate
        ? moment(endDate).add(2, 'days').toDate().toISOString().split('T')[0]
        : moment().add(2, 'days').toISOString().split('T')[0];


    // Options d'heures (par intervalles de 30 minutes)
    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, '0');
        return [
            { value: `${hour}:00`, label: `${hour}:00` },
            { value: `${hour}:30`, label: `${hour}:30` }
        ];
    }).flat();

    // Combiner la date et l'heure pour obtenir une date complète au format ISO
    const combineDateAndTime = (date, time) => {
        if (!date || !time) return null;
        return `${date}T${time.value}:00.000+00:00`;
    };



    //poucentage d'utilisation de l'imobile: 
    function calculateUsageRate(startDate, endDate) {
        // Conversion des dates en objets Date
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calcul du nombre de jours réservés
        const timeDiff = end - start; // Différence en millisecondes
        const daysReserved = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // Convertir en jours

        // Récupérer le mois et l'année pour le calcul du nombre de jours dans le mois
        const year = start.getFullYear();
        const month = start.getMonth() + 1; // Mois de 1 (janvier) à 12 (décembre)

        // Nombre de jours dans le mois de la réservation
        const daysInMonth = new Date(year, month, 0).getDate();

        // Calcul du taux d'utilisation
        const usageRate = (daysReserved / daysInMonth) * 100;

        return usageRate.toFixed(2); // Retourner le résultat avec 2 décimales
    }


    const renderStatusBadge = (status) => {
        switch (status) {
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };



    // Fonction pour gérer les changements dans les champs de type nombre et empêcher les valeurs négatives
    const handleNumberInputChange = (e, field) => {
        const value = parseFloat(e.target.value);
        if (value < 0) {
            setformDataExtend(prevState => ({ ...prevState, [field]: '' }));
        } else {
            setformDataExtend(prevState => ({ ...prevState, [field]: value }));
        }
    };




    // total de le comme convenu 
    // Calculer le total des dommages
    const totalDamages = damages?.reduce((acc, damage) => acc + damage.amount, 0) || 0;

    // Calculer le total des dépenses supplémentaires
    const totalExpenses = expenses?.reduce((acc, expense) => acc + expense.amount, 0) || 0;

    // Calcul du total général avec la réduction
    const total = totalDamages + totalExpenses;




















    // changer item de reservatio,
    // function filter
    const handleSearchFilter = () => {
        // handleShowLoading();
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question
            dispatch(fetchVehiclesAllResearchAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));
            // dispatch(fetchVehiclesAllResearchUnAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));

            dispatch(fetchPropertysAllResearchAvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));
            // dispatch(fetchPropertysAllResearchUnvailable(startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId)));

        }
        else {

            toast.error("Action non autorisé")
            // dispatch(fetchPropertysAllResearchUnvailable(startDate, endDate));
        }
        // setShowModal(false);
    }


    return (
        <div className="mb-5" onClick={handleCleanSugestions}>
            <Breadcrumb>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex">
                        {/* Contenu à gauche */}
                        <Breadcrumb.Item to="#">
                            <Link to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`}>
                                Reservation
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{reservation.ordre || ""}</Breadcrumb.Item>
                    </div>
                    <div>
                        {/* Bouton Actions à droite */}
                        {getAndCheckLocalStorage(localStorageKeys.userId) === (property?.addBy || vehicle?.addBy) && (
                            <DropdownButton
                                id="dropdown-basic-button"
                                title="Actions"
                                size="sm"
                                variant="primary"
                            >
                                {status && (status === "PENDING" || status === "CANCELLED") && (
                                    <Dropdown.Item className="text-dark"
                                        onClick={() => openModal("Valider")}
                                        disabled={status === "CONFIRMED"}
                                    >
                                        <MdConfirmationNumber />  Confirmer la reservation
                                    </Dropdown.Item>
                                )}

                                {status === "PENDING" && (
                                    <Dropdown.Item className="text-danger"
                                        onClick={() => openModal("Annuler")}
                                        disabled={status === "CONFIRMED" || status === "COMPLETED"}
                                    >
                                        <MdCancel />   Annuler la réservation
                                    </Dropdown.Item>
                                )}

                                {status && (status === "CONFIRMED") && (
                                    <>

                                        {
                                            status && (status === "CONFIRMED") &&
                                            <Dropdown.Item className="text-dark"
                                                onClick={() => openModal("Prolonger")}
                                                disabled={status === "PENDING" || status === "CANCELLED" || status === "COMPLETED"}
                                            >
                                                <RxReload /> Soumettre un prolongement
                                            </Dropdown.Item>
                                        }

                                        <Dropdown.Item className="text-dark"
                                            onClick={() => {
                                                navigate(
                                                    `/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_PLATEFORM_ADD}`,
                                                    { state: reservation }
                                                );
                                            }}
                                            disabled={status === "PENDING" || status === "CANCELLED"}
                                        >
                                            <RiFileDamageFill /> Nouveau dommage
                                        </Dropdown.Item>

                                        <Dropdown.Item className="text-dark"
                                            onClick={() => openModal("Depense")}
                                            disabled={status === "PENDING" || status === "CANCELLED"}
                                        >
                                            <MdMoneyOff /> Nouvelle dépense
                                        </Dropdown.Item>

                                        {/* <Dropdown.Item className="text-dark"
                                       onClick={() => openModal("Modifier")}
                                       disabled={status === "PENDING" || status === "CANCELLED"}
                                   >
                                       <MdEditNote />   Modifier prix réele
                                   </Dropdown.Item>*/}
                                    </>
                                )}

                                {status &&
                                    (status === "PENDING" ||
                                        status === "COMPLETED" ||
                                        status === "CONFIRMED" ||
                                        status === "CANCELLED") && (

                                        <Dropdown.Item className="text-dark"
                                            onClick={() => {
                                                navigate(
                                                    `/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_INVOICE_VIEW_ADMIN}/${reservation._id}`
                                                );
                                            }}
                                        >
                                            <FaFileInvoice />  Visualiser la facture
                                        </Dropdown.Item>
                                    )}

                                    {status &&
                                    (status === "PENDING" ||
                                        status === "COMPLETED" ||
                                        status === "CONFIRMED" ||
                                        status === "CANCELLED") && (
                                <Dropdown.Item className="text-dark"
                                    onClick={() => { handleShowUpdateReservation(); handleSearchFilter() }}

                                >
                                    <MdEdit />  Mettre à jour les informations
                                </Dropdown.Item>

                                        )}
                            

                                {status && (status === "PENDING" ||
                            status === "CONFIRMED"
                        ) && (
                                <Dropdown.Item className="text-dark"
                                    onClick={() => { openModal("Changer"); handleSearchFilter() }}
                                    disabled={status === "CONFIRMED"}
                                >
                                    <LiaExchangeAltSolid />  Changer élement de reservation
                                </Dropdown.Item>
                            )}
                            </DropdownButton>  
                        )}
                        
                    </div>
                </div>
            </Breadcrumb>

            <hr />
            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            {vehicle ? (
                                <div className="d-flex align-items-center">
                                    <FaCar className="me-2" /> <strong>Véhicule</strong>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <FaHome className="me-2" /> <strong>Propriété</strong>
                                </div>
                            )}
                        </Card.Header>
                        <Card.Body>
                            {images && images.length > 0 ?
                                <div className="mb-4"   >
                                    <ImageGallery
                                        items={images}
                                        showThumbnails={true}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        renderItem={(item) => (
                                            <div style={{
                                                height: '300px', // Hauteur fixe
                                                width: '100%',
                                                overflow: 'hidden'
                                            }}>
                                                <img
                                                    src={item.original}
                                                    alt=""
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        objectFit: 'contain'
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                :
                                <img src={emptyImage}
                                    alt="vehicle"
                                    className="d-block w-100"
                                    style={{ width: "100%", height: "200px" }}
                                />
                            }
                            <h4><span>{vehicle ? 'Détails du Véhicule' : 'Détails de la Propriété'} </span>
                                {vehicle && getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER &&
                                    (
                                        <Button style={{ fontSize: "10px" }} size="sm" className="mx-1" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_DETAIL}/${vehicle && vehicle._id ? vehicle._id : ""}`) }}> <RiEye2Fill /> Détail </Button>
                                    )
                                }
                                {property && getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER &&
                                    (
                                        <Button style={{ fontSize: "10px" }} size="sm" className="mx-1" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_DETAIL}/${property && property._id ? property._id : ""}`) }}> <RiEye2Fill /> Détail </Button>

                                    )
                                }
                            </h4>
                            {vehicle && (
                                <Card>
                                    <Card.Body>

                                        <h4>{vehicle.brand || ""} {vehicle.model || ""}</h4>
                                        <h3>Prix  : {Number(reservation?.totalPrice).toFixed(0) || ""} DZD </h3>
                                        {/*
                                            (vehicle || property) && getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER &&
                                            <h6 className="text-primary">Prix  réele : {Number(reservation?.totalPriceReal).toFixed(0) || ""} DZD </h6>
                                            */
                                        }

                                        <ul className="list-group">

                                            {
                                                vehicle && vehicle.year ?
                                                    <li className="list-group-item mb-3">
                                                        <MdTimeline /> Année de fabrication: {vehicle.year || ""}
                                                    </li>
                                                    : ""
                                            }


                                            {
                                                vehicle && vehicle.color ?
                                                    <li className="list-group-item mb-3">
                                                        <MdColorize /> Couleur: {vehicle.color || ""}
                                                    </li>
                                                    : ""}
                                            {
                                                vehicle && vehicle.registrationNumber ?
                                                    <li className="list-group-item mb-3">
                                                        Numéro d{"'"}immatriculation: {vehicle.registrationNumber || ""}
                                                    </li> : ""
                                            }
                                        </ul>
                                    </Card.Body>
                                </Card>
                            )}
                            {property && (
                                <Card>
                                    <Card.Body>
                                        <p><strong>Titre :</strong> {property.name}</p>
                                        <h3>Prix  : {Number(reservation.totalPrice).toFixed(1) || ""}DZD </h3>
                                        <p><strong>Adresse :</strong> {property.address}</p>
                                        <p><strong>Superficie :</strong> {property.size} m²</p>
                                    </Card.Body>
                                </Card>
                            )}


                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Informations sur le Client</h5>
                        </Card.Header>
                        <Card.Body>
                            <p><strong>Nom et Prénoms :</strong> {clientName || ""}</p>
                            <p><strong>Email :</strong> <a href={`mailto:+${clientEmail}`}>{clientEmail || ""}</a></p>
                            <p><strong>Téléphone :</strong> <a href={`tel:+${clientPhone}`}>{clientPhone || ""}</a></p>

                            <Card>
                                <Card.Header>Infos de la réservation </Card.Header>
                                <Card.Body>

                                    <ul className="list-group">





                                        <li className="list-group-item mb-3" style={{ display: 'flex', flexDirection: "column" }}> {/* Adds bottom margin */}
                                            <div className="mb-2"> {/* Adds bottom margin between icon and date */}
                                                <MdDateRange size={20} /> <strong>Période :</strong>
                                            </div>
                                            <div>
                                                {moment(startDate).format("DD-MM-YYYY - HH:MM")} - {moment(endDate).format("DD-MM-YYYY - HH:MM")}
                                            </div>
                                        </li>

                                        <li className="list-group-item">
                                            <strong>Statut :</strong>
                                            <span className={`ms-2 status ${statusClass}`}> {/* Adds left margin between label and status */}
                                                {renderStatusBadge(status || 'Non défini')}
                                            </span>
                                        </li>

                                        {
                                            reservation.dateOfBirth && (
                                                <li className="list-group-item mb-3">
                                                    <strong>
                                                        Naissance:</strong> {moment(reservation.dateOfBirth).format("DD/MM/YYYY") || ""}
                                                </li>
                                            )
                                        }
                                        {
                                            reservation?.companyAirport &&
                                            <li className="list-group-item mb-3">
                                                <strong>
                                                    Compagnie Aérienne :</strong> {reservation?.companyAirport?.name || ""}
                                            </li>
                                        }
                                        {
                                            reservation?.numberVolAiport &&
                                            <li className="list-group-item mb-3">
                                                <strong>
                                                    Numero Vol:</strong> {reservation?.numberVolAiport || ""}
                                            </li>
                                        }
                                        {
                                            reservation.idCard ?
                                                <li className="list-group-item mb-3">
                                                    <FaAddressCard /> Carte d{"'"} Identité :   <span className="px-2"> {reservation.idCard} </span>
                                                    {
                                                        reservation && reservation.idCardFile && reservation.idCardFile.length > 0 ?
                                                            <Button variant="primary" size="sm" style={{ fontSize: "10px" }} onClick={() => { handleShowModalCardId() }}> <RiEye2Fill /> voire</Button>
                                                            : ""}
                                                </li> : ""
                                        }
                                        {
                                            reservation.driverLicenseNumber ?
                                                <li className="list-group-item mb-3">
                                                    <FaRegAddressCard /> <span> Passport / Permis </span>  : <span className="px-2"> {reservation.driverLicenseNumber} </span>
                                                    {
                                                        reservation && reservation.idCardFile && reservation.idCardFile.length > 0 ?
                                                            <Button variant="primary" size="sm" style={{ fontSize: "10px" }} onClick={() => { handleShowModalDriver() }}> <RiEye2Fill /> voire</Button>
                                                            : ""}
                                                </li> : ""
                                        }

                                        {
                                            getAndCheckLocalStorage(localStorageKeys.userId) == (property?.addBy || vehicle?.addBy) && (status && status == "CONFIRMED" || status == "COMPLETED") ?
                                                <li className="list-group-item mb-3"> {/* Adds bottom margin */}
                                                    <strong><MdImage /> Etat des lieux </strong>
                                                    <Button type="button" size="sm" style={{ fontSize: "10px" }} onClick={handleShowUpdateImagesVerifield}> <RiEdit2Fill /> </Button>
                                                    <span className="mx-2"></span>
                                                    <Button type="button" size="sm" className="" style={{ fontSize: "10px" }} onClick={handleShowimagesVerifield}> <RiFile2Fill /> voire </Button>
                                                </li>
                                                : ""

                                        }




                                        {
                                            reservation && reservation.pickupLocation ?
                                                <li className="list-group-item mb-3">
                                                    <MdLocationOn /> Lieu de depart : {reservation.pickupLocation}
                                                </li> : ""

                                        }
                                        {
                                            reservation && reservation.returnLocation ?
                                                <li className="list-group-item mb-3">
                                                    <MdLocationOn />  Lieu de retour : {reservation.returnLocation}
                                                </li> : ""
                                        }

                                        {
                                            specialRequests && (
                                                <li className="list-group-item mb-3"> {/* Adds bottom margin */}
                                                    <strong><MdRequestQuote /> Information supplementaire :</strong> {specialRequests}
                                                </li>)
                                        }

                                        {
                                            reservation && reservation.motif && reservation.motif.name ?
                                                <li className="list-group-item mb-3 text-danger"> {/* Adds bottom margin */}
                                                    <strong><MdCancelPresentation /> Motif d{"'"}Annulation :</strong> {reservation?.motif?.name}
                                                </li> : ""
                                        }

                                        {
                                            reservation && reservation.motifComment ?
                                                <li className="list-group-item mb-3 text-danger"> {/* Adds bottom margin */}
                                                    <strong><MdCancelPresentation /> Commentaire d{"'"}annulation :</strong> {reservation?.motifComment}
                                                </li> : ""
                                        }
                                    </ul>

                                </Card.Body>
                            </Card>

                            {
                                reservation && reservation.extensions && reservation.extensions.length > 0 ?
                                    <Card className="mt-3">
                                        <Card.Header><strong>Prologement</strong></Card.Header>
                                        <ul>
                                            {
                                                reservation && reservation.extensions && reservation.extensions.length > 0 ?
                                                    reservation.extensions.map((extend) => {
                                                        return (
                                                            <li>
                                                                <div className="p-1 border border-md">
                                                                    <p>Date : <strong>{moment(extend.oldEndDate).format("DD-MM-YYYY - HH:MM")} </strong> au <strong>{moment(extend.newEndDate).format("DD-MM-YYYY : HH:MM")}</strong>    Prix : <strong> {Number(extend.additionalPrice).toFixed(1)} </strong> </p>
                                                                    <span>

                                                                    </span>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) : ""
                                            }
                                        </ul>
                                    </Card>
                                    : ""
                            }





                        </Card.Body>
                    </Card>
                </Col>


                <Tabs defaultActiveKey="localisation" id="reservation-tabs" className="mb-3">
                    {/* Onglet Localisation */}
                    <Tab eventKey="localisation" title="Localisation">
                        <Row>
                            {
                                reservation && reservation.property && reservation.property.lat && reservation.property.lng && (
                                    <Col md={12}>
                                        <div className="py-3">
                                            <h6> <MdLocationOn />  Localisation </h6>
                                            <p> {reservation && reservation.property && reservation.property.address ? reservation?.property?.address : ""}</p>
                                        </div>
                                        <MapContainer center={[reservation?.property?.lat, reservation?.property?.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                            <Marker position={[reservation?.property?.lat, reservation?.property?.lng]}>
                                                <Popup>
                                                    Location: {reservation?.property?.lat}, {reservation?.property?.lng}
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </Col>
                                )
                            }



                            {
                                reservation && reservation?.vehicle && reservation.latStart && reservation.lngStart && (
                                    <Col md={6}>
                                        <div className="py-3">
                                            <h6> <MdLocationOn />  Lieu de départ </h6>
                                            <p> {reservation && reservation.pickupLocation ? reservation.pickupLocation : ""}</p>
                                        </div>
                                        <MapContainer center={[reservation.latStart, reservation.lngStart]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                            <Marker position={[reservation.latStart, reservation.lngStart]}>
                                                <Popup>
                                                    Location: {reservation.latStart}, {reservation.lngStart}
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </Col>
                                )
                            }


                            {
                                reservation && reservation?.vehicle && reservation.latEnd && reservation.lngEnd && (
                                    <Col md={6}>
                                        <div className="py-3">
                                            <h6> <MdLocationOn />  Lieu de retour </h6>
                                            <p> {reservation && reservation.returnLocation ? reservation.returnLocation : ""}</p>
                                        </div>
                                        <MapContainer center={[reservation.latEnd, reservation.lngEnd]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                            <Marker position={[reservation.latEnd, reservation.lngEnd]}>
                                                <Popup>
                                                    Location: {reservation.latEnd}, {reservation.lngEnd}
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </Col>
                                )
                            }
                        </Row>


                        <div className="py-5">
                        </div>

                    </Tab>











                    {/* Onglet Dommages */}
                    <Tab eventKey="damages" title="Dommages">

                        {
                            getAndCheckLocalStorage(localStorageKeys.userId) == (property?.addBy || vehicle?.addBy) ?
                                <Col md={12}>
                                    <Card>
                                        <Card.Header><h5>Dommages</h5></Card.Header>

                                        <Card.Body>

                                            <Card.Text>
                                                <strong>Montant </strong>{totalDamages}
                                            </Card.Text>
                                            {/* Tableau des rapports filtrés */}
                                            {
                                                damages && damages.length > 0 ?
                                                    <Table striped bordered hover responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Catégorie</th>
                                                                <th>Photos Avant</th>
                                                                <th>Photos Après</th>
                                                                <th>Cout</th>
                                                                <th>Statut</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {damages && damages.length > 0 && damages.map((report, index) => (
                                                                <tr key={report.id}>

                                                                    <td>
                                                                        {
                                                                            report && report.type && report.type.name ?
                                                                                report.type.name : ""
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        <Image
                                                                            src={`${report.beforeUsePhoto[0]}`}
                                                                            alt={`Avant - ${report.beforeUsePhoto[0]}`}
                                                                            fluid
                                                                            thumbnail
                                                                            style={{ width: '80px', height: '60px' }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Image
                                                                            src={`${report.afterUsePhoto[0]}`}
                                                                            alt={`Après - ${report.afterUsePhoto[0]}`}
                                                                            fluid
                                                                            thumbnail
                                                                            style={{ width: '80px', height: '60px' }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <strong>{report.estimatedCost}</strong>
                                                                    </td>
                                                                    <td>
                                                                        {renderStatusBadgeDamage(report.status)}
                                                                    </td>
                                                                    <td>

                                                                        <Button primary="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}/${report._id}`) }}>
                                                                            <RiEye2Fill />
                                                                        </Button>


                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                        <div> Aucun dommages</div>
                                                    </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                : ""}

                    </Tab>



                    <Tab eventKey="expenses" title="Dépenses Supplémentaires">
                        {
                            // Depenses
                            getAndCheckLocalStorage(localStorageKeys.userId) == (property?.addBy || vehicle?.addBy) ?
                                <Row>


                                    <Col md={12}>

                                        <Card>
                                            <Card.Header><h5>Dépenses supplémentaires</h5></Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    <strong>Montant  </strong>{totalExpenses}
                                                </Card.Text>

                                                {/* Tableau des rapports filtrés */}
                                                {
                                                    expenses && expenses.length > 0 ?
                                                        <Table striped bordered hover responsive >
                                                            <thead>
                                                                <tr>
                                                                    <th>Frais Divers</th>
                                                                    <th>Montant</th>
                                                                    <th>Date</th>
                                                                    <th>Date Création</th>
                                                                    <th>Statut</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenses && expenses.length > 0 && expenses.map((expense, index) => (
                                                                    <tr >

                                                                        <td>
                                                                            {
                                                                                expense && expense.category && expense.category.name ?
                                                                                    expense.category.name : ""
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <strong>{expense.amount}</strong>
                                                                        </td>

                                                                        <td>
                                                                            <strong>{moment(expense.date).format("DD-MM-YYYY")}</strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong>{moment(expense.createdAt).format("DD-MM-YYYY")}</strong>
                                                                        </td>
                                                                        <td>
                                                                            <Badge variant="primary">{expense?.status == "Completed" ? "Validé" : expense.status}</Badge>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        :
                                                        <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                            <div> Aucune dépense externe</div>
                                                        </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                : ""}

                    </Tab>
                </Tabs>












            </Row>



            {/*photo pièce d'identité */}
            <Modal show={showModalCardId} onHide={handleCloseModalCardId} centered size='lg' >
                <Modal.Header>
                    <h4>
                        <FaAddressCard /> Pièce d{"'"}identité <strong>{reservation.idCard}</strong>
                    </h4>
                </Modal.Header>

                <Modal.Body>
                    {images.length > 0 && (
                        <div className="mb-4"   >
                            <ImageGallery
                                items={imagesidCardFile}
                                showThumbnails={true}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                showBullets={true}

                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>


            <Modal show={showModalDriverFile} onHide={handleCloseModalDriver} centered size='lg' >
                <Modal.Header>
                    <h4> <FaRegAddressCard /> Permis/ Passport <strong>{reservation.driverLicenseNumber}</strong> </h4>
                </Modal.Header>
                <Modal.Body>
                    {images.length > 0 && (
                        <div className="mb-4"   >
                            <ImageGallery
                                items={imagesDriverFile}
                                showThumbnails={true}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                showBullets={true}

                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>



            {/*photo etate des lieux */}
            <Modal show={showModalimagesVerifield} onHide={handleCloseimagesVerifield} centered size='lg' >
                <Modal.Header>
                    <h4><MdOutlineImage size={30} /> Etat des lieux initiales <strong></strong> </h4>
                </Modal.Header>
                <Modal.Body>
                    {imagesimagesVerifield.length > 0 && (
                        <div className="mb-4"   >
                            <ImageGallery
                                items={imagesimagesVerifield}
                                showThumbnails={true}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                showBullets={true}

                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>





            {/*Modifiier étate des lieux du véhicule */}
            <Modal show={showModalUpdateImagesVerifield} onHide={handleCloseUpdateImagesVerifield} centered size='md' >
                <Modal.Header>
                    <h4><MdOutlineImage size={30} /> Formulaire etat des lieux <strong></strong> </h4>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdateImagesVerifield}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label><MdImage /> Etat de Lieux </Form.Label>
                                <Form.Control style={{ height: "60px" }}
                                    type="file"
                                    multiple
                                    accept=".JPG,.PNG,.JPEG"
                                    onChange={handleImagesVerifield}
                                />
                            </Form.Group>
                        </Col>
                        {
                            formDataExtend && formDataExtend.imagesVerifield && formDataExtend.imagesVerifield.length > 0 ?
                                <Col md={12}>

                                    {formDataExtend.imagesVerifield.length > 0 && (
                                        <div>
                                            <h6>Images sélectionnées :</h6>
                                            <ul className="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                {formDataExtend.imagesVerifield.map((image, index) => (
                                                    <li key={index} className="p-1">
                                                        <span className="text-danger text-xs" style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                // On supprime l'image correspondant à l'index
                                                                const updatedImages = formDataExtend.imagesVerifield.filter((_, i) => i !== index);
                                                                setformDataExtend({ ...formDataExtend, imagesVerifield: updatedImages });
                                                            }}
                                                        >
                                                            <MdClose size={30} />
                                                        </span>
                                                        <div>
                                                            <img src={image} style={{ height: "60px", width: "60px" }} />
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}


                                </Col>
                                : ""
                        }
                        <div className="justify-content-between mt-5">
                            <div></div>
                            <div>
                                <Button type='button' size="sm" variant="light" onClick={handleCloseUpdateImagesVerifield}> Annuler</Button>
                                <Button type='submit' size="sm" variant="primary"> Valider</Button>
                            </div>

                        </div>

                    </Form>
                </Modal.Body>
            </Modal>







            {/* Modal de confirmation */}
            <Modal show={showModal} onHide={closeModal} centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title><h3> Confirmation </h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        actionType && actionType.toLowerCase() == "Depense".toLowerCase() ?
                            " Êtes-vous sûr de vouloir soumettre une dépense à cette réservation ?" :
                            `Êtes-vous sûr de vouloir ${actionType.toLowerCase()} cette réservation`
                    }


                    <div className="container-fluid row">


                        {
                            actionType && actionType.toLowerCase() == "Annuler".toLowerCase() ?
                                <Form>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Motif</Form.Label>
                                            <Select
                                                options={motifsOptions}
                                                onChange={setidMotif}
                                                placeholder="Selection du motif"
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group controlId="startDate">
                                            <Form.Label>Commentaire </Form.Label>
                                            <Form.Control style={{ height: "40px" }}
                                                type="text"
                                                as={"textarea"}
                                                value={motifComment}
                                                onChange={(e) => { setmotifComment(e.target.value) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form>
                                : ""
                        }

                        {
                            actionType && actionType.toLowerCase() == "Valider".toLowerCase()
                                ?
                                <Form>
                                    <Col md={12}>
                                        <Form.Group controlId="startDate">
                                            <Form.Label><MdImage /> Etat de Lieux </Form.Label>
                                            <Form.Control style={{ height: "50px" }}
                                                type="file"
                                                multiple
                                                accept=".JPG,.PNG,.JPEG"
                                                onChange={handleImagesVerifield}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {
                                        formDataExtend && formDataExtend.imagesVerifield && formDataExtend.imagesVerifield.length > 0 ?
                                            <Col md={12}>

                                                {formDataExtend.imagesVerifield.length > 0 && (
                                                    <div>
                                                        <h6>Images sélectionnées :</h6>
                                                        <ul className="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {formDataExtend.imagesVerifield.map((image, index) => (
                                                                <li key={index} className="p-1">
                                                                    <span className="text-danger text-xs" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // On supprime l'image correspondant à l'index
                                                                            const updatedImages = formDataExtend.imagesVerifield.filter((_, i) => i !== index);
                                                                            setformDataExtend({ ...formDataExtend, imagesVerifield: updatedImages });
                                                                        }}
                                                                    >
                                                                        <MdClose size={30} />
                                                                    </span>
                                                                    <div>
                                                                        <img src={image} style={{ height: "60px", width: "60px" }} />
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}


                                            </Col>
                                            : ""
                                    }


                                    <Col md={12}>
                                        <Form.Group controlId="startDate">
                                            <Form.Label> <MdDiscount /> Remise </Form.Label>
                                            <Form.Control style={{ height: "40px" }}
                                                type="number"
                                                min={0}
                                                max={totalPrice || 0}
                                                value={Number(formDataExtend.discount || 0)}
                                                onChange={(e) => { handleNumberInputChange(e, "discount") }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>

                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Remise (
                                                            {formDataExtend?.discount && reservation?.totalPrice && Number(reservation.totalPrice) > 0
                                                                ? `${Number((Number(formDataExtend.discount) * 100) / Number(reservation.totalPrice)).toFixed(2)} %`
                                                                : "0 %"}
                                                            ) :
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {formDataExtend?.discount && Number(formDataExtend.discount) > 0
                                                            ? `${Number(formDataExtend.discount).toFixed(2)} DZD`
                                                            : "0"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Montant total :</strong></td>
                                                    <td><strong>{Number(reservation.totalPrice).toFixed(2)} DZD</strong></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Form> :
                                ""
                        }
                        {
                            actionType && actionType.toLowerCase() == "Prolonger".toLowerCase()
                                ?
                                <Form >
                                    <Row>

                                        <Col md={12}>

                                            <Row>
                                                <Col md={7}>
                                                    <Form.Group controlId="startDate">
                                                        <Form.Label>Date du prolongement <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control style={{ height: "40px" }}

                                                            type="date"
                                                            min={today}
                                                            value={formDataExtend.newEndDate}
                                                            onChange={(e) => { setformDataExtend({ ...formDataExtend, newEndDate: e.target.value }) }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={5}>
                                                    <Form.Group controlId="endHour">
                                                        <Form.Label>Heure de fin <span className="text-danger">*</span> </Form.Label>
                                                        <Select
                                                            value={endHour}
                                                            onChange={(selectedOption) => setEndHour(selectedOption)}
                                                            options={hourOptions}
                                                            isDisabled={!formDataExtend.newEndDate}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>






                                        <Col md={12}>
                                            <Form.Group controlId="startDate">
                                                <Form.Label>Remise </Form.Label>
                                                <Form.Control style={{ height: "40px" }}
                                                    type="number"
                                                    value={formDataExtend.discount}
                                                    onChange={(e) => { handleNumberInputChange(e, "discount") }}
                                                />
                                            </Form.Group>
                                        </Col>


                                        <Col md={12}>
                                            <Form.Group controlId="startDate">
                                                <Form.Label>Commentaire <span className="text-danger">*</span> </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formDataExtend.reason}
                                                    onChange={(e) => { setformDataExtend({ ...formDataExtend, reason: e.target.value }) }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {
                                            formDataExtend && formDataExtend.newEndDate ?
                                                <Col md={12}>
                                                    <Card className="shadow-sm rounded">

                                                        <Card.Body>
                                                            <Card.Title><h5>Infos supplémentaire </h5></Card.Title>
                                                            <Table striped bordered hover>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Prix journalier :</strong></td>
                                                                        <td>
                                                                            {
                                                                                reservation?.vehicle?.basePricePerDay
                                                                                ||
                                                                                reservation?.property?.basePricePerDay
                                                                            } DZD
                                                                        </td>
                                                                    </tr>
                                                                    {/*<tr>
                                                                        <td><strong>
                                                                            {
                                                                                reservation?.vehicle?.deliveryFee ?
                                                                                    "Frais de livraison " :
                                                                                    "Frais Supplémentaires"
                                                                            }
                                                                            :</strong></td>
                                                                        <td>
                                                                            {
                                                                                reservation?.vehicle?.deliveryFee
                                                                                ||
                                                                                reservation?.property?.deliveryFee
                                                                            } DZD
                                                                        </td>
                                                                    </tr> */}

                                                                    {/*<tr>
                                                                        <td>
                                                                            {reservation && reservation.vehicle && reservation.vehicle.commissionPercentage
                                                                                && reservation.vehicle.deliveryFee ?

                                                                                ((totalPrice - reservation.vehicle.deliveryFee) * reservation.vehicle.commissionPercentage / 100).toFixed(1).toLocaleString()
                                                                                : ""
                                                                            }
                                                                            {reservation && reservation.property && reservation.property.commissionPercentage
                                                                                && reservation.property.deliveryFee ?

                                                                                ((totalPrice - reservation.property.deliveryFee) * reservation.property.commissionPercentage / 100).toFixed(1).toLocaleString()
                                                                                : ""
                                                                            }
                                                                            DZD
                                                                        </td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td><strong>Remise :</strong></td>
                                                                        <td> {formDataExtend.discount || 0} DZD</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>Montant total :</strong></td>
                                                                        <td><strong>{totalPrice.toFixed(1).toLocaleString()} DZD</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>


                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                :
                                                ""
                                        }

                                    </Row>
                                </Form> : ""
                        }


                        {
                            actionType && actionType.toLowerCase() == "Modifier".toLowerCase()
                            &&
                            <Form>
                                <Col md={12}>
                                    <Form.Group controlId="prix totle">
                                        <Form.Label>Prix réele de la réservation </Form.Label>
                                        <Form.Control style={{ height: "40px" }}
                                            type="number"
                                            value={formDataExtend.totalPriceReal}
                                            onChange={(e) => { handleNumberInputChange(e, "totalPriceReal") }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form>
                        }

                        {
                            actionType && actionType.toLowerCase() == "Depense".toLowerCase()
                            &&
                            <Form>
                                <Col md={12}>
                                    <Form.Group controlId="Cateori">
                                        <Form.Label>Categorie de la dépense</Form.Label>
                                        <Select
                                            options={categorysExpenseOptions}
                                            value={categorySelect}
                                            onChange={(selectOptions) => {
                                                setcategorySelect(selectOptions)
                                                setformDataExpense({ ...formDataExpense, category: selectOptions?.value })
                                                // setformDataExpense({ ...formDataExpense, description: selectOptions?.label }) 
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="prixexpense">
                                        <Form.Label>Prix de la dépense </Form.Label>
                                        <Form.Control style={{ height: "40px" }}
                                            type="number"
                                            value={formDataExpense.amount}
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value);
                                                if (value < 0) {
                                                    setformDataExpense({ ...formDataExpense, amount: "" })
                                                } else {
                                                    setformDataExpense({ ...formDataExpense, amount: value })
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group controlId="prixexpense">
                                        <Form.Label>Date estimé </Form.Label>
                                        <Form.Control style={{ height: "40px" }}
                                            type="date"
                                            value={formDataExpense.date}
                                            onChange={(e) => {
                                                setformDataExpense({ ...formDataExpense, date: e.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group controlId="description">
                                        <Form.Label>Commentaire </Form.Label>
                                        <Form.Control style={{ minHeight: "40px" }}
                                            as={"textarea"}
                                            rows={2}
                                            value={formDataExpense.description}
                                            onChange={(e) => { setformDataExpense({ ...formDataExpense, description: e.target.value }) }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form>
                        }



                        {
                            actionType && actionType.toLowerCase() == "Changer".toLowerCase()
                            &&
                            <Form>

                                {
                                    (vehicleAvalaibleSelect?.value || propertyAvailableSelect?.value) &&
                                    (
                                        <Alert variant={"danger"} className="my-5">
                                            Modifier le patrimoine de la réservation prendra en compte le prix du nouveau patrimoine et aussi lors du prologmeent de la réservation
                                        </Alert>
                                    )
                                }


                                {
                                    (vehicleAvalaibleSelect?.value || propertyAvailableSelect?.value) &&
                                    (<Col md={12}>
                                        <div className="card" style={{ width: "100%" }}>
                                            <div className="card-body">

                                                <Row>
                                                    <Col md={2}>
                                                        {vehicleAvalaibleSelect?.images && vehicleAvalaibleSelect?.images.length > 0 ? (
                                                            <Image
                                                                src={vehicleAvalaibleSelect?.images[0] || emptyImage}
                                                                className="img-fluid rounded-md"
                                                                style={{ width: "100px", height: "100px" }}
                                                                alt={vehicleAvalaibleSelect?.label || ""}
                                                            />
                                                        ) : ""}

                                                        {
                                                            propertyAvailableSelect?.images && propertyAvailableSelect?.images.length > 0 ? (
                                                                <Image
                                                                    src={propertyAvailableSelect?.images[0] || emptyImage}
                                                                    className="img-fluid rounded-md"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    alt={propertyAvailableSelect?.label || ""}
                                                                />
                                                            ) : ""}
                                                    </Col>
                                                    <Col md={10}>
                                                        <ul className="list-group list-group-flush">
                                                            {vehicleAvalaibleSelect?.brand && (
                                                                <li className="list-group-item">{vehicleAvalaibleSelect?.brand}</li>
                                                            )}
                                                            {vehicleAvalaibleSelect?.model && (
                                                                <li className="list-group-item">Model : {vehicleAvalaibleSelect?.model}</li>
                                                            )}
                                                            {vehicleAvalaibleSelect?.color && (
                                                                <li className="list-group-item">
                                                                    <MdColorize /> {vehicleAvalaibleSelect?.color}
                                                                </li>
                                                            )}
                                                            {propertyAvailableSelect?.label && (
                                                                <li className="list-group-item">Titre : {propertyAvailableSelect?.label}</li>
                                                            )}
                                                            {propertyAvailableSelect?.address && (
                                                                <li className="list-group-item">
                                                                    <MdMap /> {propertyAvailableSelect?.address}
                                                                </li>
                                                            )}

                                                            <li className="list-group-item">
                                                                <MdDateRange /> Disponibilité {reservation?.startDate ? moment(reservation?.startDate).format("DD-MM-YYYY HH:MM") : ""} {reservation?.startDate ? moment(reservation?.endDate).format("DD-MM-YYYY HH:MM") : ""}
                                                            </li>

                                                        </ul>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Col>
                                    )
                                }


                                {
                                    reservation?.vehicle?._id && (
                                        <Col md={12}>
                                            <Form.Group controlId="vehicleId">
                                                <Form.Label>Vehicule</Form.Label>
                                                <Select
                                                    options={vehicleAvailablesOptions}
                                                    value={vehicleAvalaibleSelect}
                                                    onChange={(selectOptions) => {
                                                        setvehicleAvalaibleSelect(selectOptions)
                                                        // console.log(selectOptions);
                                                    }}
                                                    isClearable
                                                    isSearchable
                                                    isDisabled={!!propertyAvailableSelect}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )
                                }

                                {
                                    reservation?.property?._id && (
                                        <Col md={12}>
                                            <Form.Group controlId="propertyId">
                                                <Form.Label>Propriété</Form.Label>
                                                <Select
                                                    options={propertyAvailableOptions}
                                                    value={propertyAvailableSelect}
                                                    onChange={(selectOptions) => {
                                                        setpropertyAvailableSelect(selectOptions)
                                                        // console.log(selectOptions);
                                                    }}
                                                    isClearable
                                                    isSearchable
                                                    isDisabled={!!vehicleAvalaibleSelect}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )
                                }






                            </Form>
                        }



                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size='sm' onClick={closeModal}>
                        Annuler
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size='sm' onClick={() => { }}>
                                <Spinner variant='light' />
                            </Button>

                            :
                            <Button variant="primary" size='sm' onClick={confirmAction}>
                                Confirmer
                            </Button>
                    }

                </Modal.Footer>
            </Modal>






            {/** update reservation transfert */}
            <Modal show={showUpdateReservation} onHide={handleCloseUpdateReservation} centered size='xl'  >
                <Modal.Header>
                    <h3>Mise à ajour des information de la réservation</h3>
                </Modal.Header>
                <Modal.Body onClick={handleCleanSugestions}>

                    <div className="m-5">
                        <Form>



                            <Row className="mb-3">







                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group controlId="driverLicenseNumber">
                                            <Form.Label>Numéro de carte d{"'"}identité</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le numéro de carete d'identité"
                                                style={{ height: "40px" }}
                                                value={reservationData.idCard}
                                                onChange={(e) => handleInputChange('idCard', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col md={6}>
                                        <Form.Group controlId="driverLicenseNumber">
                                            <Form.Label>Numéro de permis de conduire</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le numéro de permis"
                                                style={{ height: "40px" }}
                                                value={reservationData.driverLicenseNumber}
                                                onChange={(e) => handleInputChange('driverLicenseNumber', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="idCardFile">
                                            <Form.Label>Fichier Carte d{"'"}identité (Recto / verso)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                style={{ height: "40px" }}
                                                multiple accept="image/*"
                                                onChange={(e) => { handleUploadCardFileReservation(e) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="phone">
                                            <Form.Label>Fichier de permis de conduire (Recto / verso)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                style={{ height: "40px" }}
                                                multiple accept="image/*"
                                                onChange={(e) => { handleUploadDriverFileReservation(e) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>




                                {/*Card Gallery driver */}

                                <ImageGalleryComponent driverLicenseFile={reservationData?.driverLicenseFile || []} idCardFile={reservationData?.idCardFile || []} />


                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group controlId="firstname">
                                            <Form.Label>Prénom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le prénom"
                                                style={{ height: "40px" }}
                                                value={reservationData.firstname}
                                                onChange={(e) => handleInputChange('firstname', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="lastname">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le nom"
                                                style={{ height: "40px" }}
                                                value={reservationData.lastname}
                                                onChange={(e) => handleInputChange('lastname', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">


                                    <Col md={6}>
                                        <Form.Group controlId="codePostal">
                                            <Form.Label>indicatif téléphone</Form.Label>
                                            <Form.Select className="border-b border-primary"
                                                value={reservationData.codePostal}
                                                onChange={(e) => handleInputChange('codePostal', e.target.value)}
                                            >
                                                <option value="">Sélectionnez un code postal</option>
                                                {codePostalOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="dateOfBirth">
                                            <Form.Label>Date de naissance</Form.Label>
                                            <Form.Control
                                                type="date"
                                                max={maxDate}
                                                value={reservationData.dateOfBirth}
                                                style={{ height: "40px" }}
                                                onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>



                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Entrez l'email"
                                            style={{ height: "40px" }}
                                            value={reservationData.email}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Téléphone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Entrez le numéro de téléphone"
                                            style={{ height: "40px" }}
                                            value={reservationData.phone}
                                            onChange={(e) => handleInputChange('phone', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Row className="mb-3">

                                <Col md={6}>
                                    <Form.Group controlId="companyAirport">
                                        <Form.Label>Compagnie aérienne</Form.Label>
                                        <Form.Select className="border-b border-primary"
                                            value={reservationData.companyAirport}
                                            onChange={(e) => handleInputChange('companyAirport', e.target.value)}
                                        >
                                            <option value="">Sélectionnez un aéroport</option>
                                            {airportOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group controlId="lastname">
                                        <Form.Label>Numéro de vol</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Numero de vol"
                                            style={{ height: "40px" }}
                                            value={reservationData.numberVolAiport}
                                            onChange={(e) => handleInputChange('numberVolAiport', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Row className="mb-3">


                                {
                                    reservation?.vehicle?.brand && (
                                        <Col md={6}>
                                            <Form.Group controlId="pickupLocation">
                                                <Form.Label>Lieu de départ <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={locationStart}
                                                    onChange={handlePickupLocationChange}
                                                    style={{ height: '40px' }}
                                                    placeholder="Entrez un lieu"
                                                />
                                                {/* Liste déroulante des suggestions */}
                                                {pickupSuggestions && pickupSuggestions.length > 0 && (
                                                    <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                                        {pickupSuggestions.map((suggestion, index) => (
                                                            <li className="border"
                                                                key={index}
                                                                style={{ padding: '5px', cursor: 'pointer' }}
                                                                onClick={() => handlePickupSelection(suggestion)}
                                                            >
                                                                {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    )
                                }

                                {/* Lieu de retour */}
                                {
                                    reservation?.vehicle?.brand && (
                                        <Col md={6}>
                                            <Form.Group controlId="returnLocation">
                                                <Form.Label>Lieu de retour <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={locationEnd}
                                                    onChange={handleReturnLocationChange}
                                                    style={{ height: '40px' }}
                                                    placeholder="Entrez un lieu"
                                                />
                                                {/* Liste déroulante des suggestions */}
                                                {returnSuggestions && returnSuggestions.length > 0 && (
                                                    <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxWidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                                        {returnSuggestions.map((suggestion, index) => (
                                                            <li className="border"
                                                                key={index}
                                                                style={{ padding: '5px', cursor: 'pointer' }}
                                                                onClick={() => handleReturnSelection(suggestion)}
                                                            >
                                                                {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    )
                                }
                            </Row>


                            {/* Information map google */}
                            {
                                reservation?.vehicle?.brand && (
                                    <ReservationMap
                                        pickLocation={reservationData?.pickupLocation}
                                        returnLocation={reservationData?.returnLocation}
                                        latStart={reservationData?.latStart}
                                        lngStart={reservationData?.lngStart}
                                        latEnd={reservationData?.latEnd}
                                        lngEnd={reservationData?.lngEnd}
                                    />
                                )
                            }



                        </Form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="light" onClick={handleCloseUpdateReservation}>Annuler</Button>
                    {
                        loadingReservation ?
                            <Button type="button" variant="primary" onClick={() => { }}><Spinner variant="light" /></Button>
                            :
                            <Button type="button" variant="primary" onClick={handleSubmitUpdateReservation} >Valider</Button>
                    }
                </Modal.Footer>
            </Modal>



        </div>
    );
};

// Fonction pour formater les dates
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

export default ReservationDetailPage;
