import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { baseurl } from '../../utils/url/baseurl';
import { toast } from 'react-toastify';

const stripePromise = loadStripe('pk_live_51QLz8tApbw9k7b1oOvT6yD2HYapIY1jADO04YeYBeUJL0EaZOjsh3t5GPpsJlvkCV1uKQxD7txTN8d1n0ELz1vX800UNi6jI17');

const CheckoutForm = ({ reservationAmount, currency }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorStripe, setErrorStripe] = useState(null);
    const [success, setSuccess] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [loadingCard, setloadingCard] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!customerName) {
            setErrorStripe("Veuillez entrer votre nom avant de continuer.");
            return;
        }

        try {
            setErrorStripe("");
            setloadingCard(true)
            // Créer une intention de paiement côté backend
            const { data } = await axios.post(`${baseurl.url}/create-payment-intent`, {
                amount: reservationAmount * 0.1, // 10% de l'acompte
                currency,
                customerName,
            });

            const clientSecret = data.clientSecret;

            // Confirmer le paiement avec Stripe
            const paymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: customerName,
                    },
                },
            });

            if (paymentResult.errorStripe) {
                setloadingCard(false);
                setSuccess(false);
                toast.errorStripe(paymentResult?.errorStripe?.message || "Paiement échoué")
                setErrorStripe(paymentResult?.errorStripe?.message);

            } else {
                setSuccess(false);
                setErrorStripe(null);
                console.log("Paiement réussi :", paymentResult);
                setloadingCard(false)
                toast.success("Paiement réussi ", { position: "bottom-right" })
            }
        } catch (errorStripe) {
            setloadingCard(false);
            setErrorStripe("Une erreur s'est produite lors du traitement du paiement.");
            console.errorStripe("Erreur :", errorStripe);
            toast.errorStripe("Une erreur s'est produite lors du traitement du paiement.", { position: "bottom-right" })
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "auto" }}>
            <div className="col-md-12 form-group border" style={{ marginBottom: "15px" ,border:"0 0 1 0 2px solid" }}>
                <label htmlFor="customerName" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Nom du client</label>
                <input
                    className="form-control"
                    type="text"
                    id="customerName"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    placeholder="Entrez votre nom"
                    required
                    style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ced4da",
                        width: "100%",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"
                    }}
                />
            </div>

            <div className="col-md-12 form-group border" style={{ marginBottom: "15px" ,border:"0 0 1px 0 solid" }}>
                <label htmlFor="cardNumber" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Numéro de carte</label>
                <CardNumberElement
                    style={{
                        fontSize: "16px",
                        color: "#495057",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ced4da",
                        width: "100%",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                    }}
                />
            </div>

            <div className="col-md-12 form-group border" style={{ marginBottom: "15px" ,border:"0 0 1px 0 solid" }}>
                <label htmlFor="expiryDate" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Date d’expiration</label>
                <CardExpiryElement
                    style={{

                        fontSize: "16px",
                        color: "#495057",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ced4da",
                        width: "100%",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                    }}
                />
            </div>

            <div className="col-md-12 form-group border" style={{ marginBottom: "15px" ,border:"0 0 1px 0 solid" }}>
                <label htmlFor="cvc" style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>CVC</label>
                <CardCvcElement
                    style={{

                        fontSize: "16px",
                        color: "#495057",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ced4da",
                        width: "100%",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"

                    }}
                />
            </div>


            <button
                type={loadingCard ? "button" : "subumit"}
                className="btn btn-sm btn-primary w-100 justify-content-center rounded-4"
                style={{ textAlign: "center" }}
                disabled={!stripe}
            >
                {loadingCard ? (
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Paiement en cours...
                    </>
                ) : (
                    `Payer 10% ${(reservationAmount * 0.1).toFixed(2)} ${currency.toUpperCase()}`
                )}
            </button>


            {errorStripe && <p style={{ color: 'red', marginTop: "10px" }}>{errorStripe}</p>}
            {success && <p style={{ color: 'green', marginTop: "10px" }}>Paiement réussi ! Merci, {customerName}.</p>}
        </form >
    );
};

const PaymentPage = () => {
    const currency = 'eur';
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm reservationAmount={100} currency={currency} />
        </Elements>
    );
};

export default PaymentPage;
