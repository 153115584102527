import React from 'react'
import HomeBarner from '../../components/web/home/HomeBarner'
import HomeEcommerce from '../../components/web/home/HomeEcommerce';
import HomeAbout from '../../components/web/home/HomeAbout';
import BenefitsSection from '../../components/web/home/BenefitsSection';


const HomePage = () => {
    return (
        <div className="page-content bg-white">

            <HomeBarner />
            <HomeAbout/>
            <HomeEcommerce />
            <BenefitsSection/>
            {/*<HomeAbout/> */}

        </div>
    )
}

export default HomePage
